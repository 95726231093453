import { wei } from '@kwenta/wei';
import { BigOrZero } from '@perennial/sdk';
import { weiFromWei } from './number';
import { toWei6 } from './perennial';
import { truncateAddress } from './string';
export const mapAndSortStats = (stats) => {
    const uniqueStatsMap = new Map();
    stats.forEach((stat) => {
        if (!uniqueStatsMap.has(stat.accountOwner)) {
            uniqueStatsMap.set(stat.accountOwner, stat);
        }
    });
    return Array.from(uniqueStatsMap.values()).sort((a, b) => wei(b.pnlWithFeesPaid).sub(wei(a.pnlWithFeesPaid)).toNumber());
};
export const mapStat = (ensInfo) => (stat, i) => ({
    ...stat,
    id: stat.accountId,
    trader: stat.accountOwner,
    traderShort: truncateAddress(stat.accountOwner),
    rank: i + 1,
    rankText: (i + 1).toString(),
    traderEns: ensInfo[stat.accountOwner] ?? null,
});
export const transformTop = (stats, percentage) => {
    const count = Math.ceil(stats.length * percentage);
    return stats.slice(0, count);
};
export const mapStatRank = (all) => (stat) => {
    const rank = all.findIndex((s) => s.accountId === stat.accountId) + 1;
    return {
        ...stat,
        rank,
        rankText: rank.toString(),
    };
};
export const formatSnxLeaderboardResponse = (subgraphResponse) => {
    return Object.entries(subgraphResponse).reduce((acc, [key, value]) => {
        acc[key] = value.map((r) => ({
            ...r,
            pnlWithFeesPaid: weiFromWei(r.pnlWithFeesPaid).toString(),
            totalVolume: weiFromWei(r.totalVolume).toString(),
        }));
        return acc;
    }, {});
};
export const formatPerennialLeaderboardResponse = (subgraphResponse) => {
    return Object.entries(subgraphResponse).reduce((acc, [key, value]) => {
        acc[key] = value.map((r) => ({
            ...r,
            accountOwner: r.accountOwner.id,
            pnlWithFeesPaid: toWei6(BigOrZero(r.pnlWithFeesPaid.metadata_net)).toString(),
            totalVolume: toWei6(BigOrZero(r.makerNotional))
                .add(toWei6(BigOrZero(r.takerNotional)))
                .toString(),
        }));
        return acc;
    }, {});
};

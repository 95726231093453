import { wei } from '@kwenta/wei';
import orderBy from 'lodash/orderBy';
import { encodeAbiParameters, hexToString, maxUint256, parseAbiParameters, parseEther, stringToHex, } from 'viem';
import { optimism } from 'viem/chains';
import { PERMIT2_ADDRESS } from '../constants';
import { UNSUPPORTED_NETWORK } from '../constants/errors';
import { DYNAMIC_FEES_MODULE_ADDRESS, LOW_FEE_TIER_BYTES, ORDERS_FETCH_SIZE, SL_TP_MAX_SIZE, } from '../constants/futures';
import { PERIOD_IN_HOURS, PERIOD_IN_SECONDS } from '../constants/period';
import { PERMIT_STRUCT } from '../constants/token';
import { commonContractsByNetwork, snxV2ContractsByNetwork, tokenContractsByNetwork, } from '../contracts';
import PerpsV2MarketInternal from '../contracts/PerpsV2MarketInternalV2';
import PerpsMarketABI from '../contracts/abis/PerpsV2Market';
import SmartMarginAccountABI from '../contracts/abis/SmartMarginAccount';
import { getFuturesAggregateStats, queryDelegatesForAccount, queryFeeReimbursed, queryFundingRateHistory, queryFuturesTrades, queryIsolatedMarginTransfers, queryOrders, queryPositions, querySmartMarginAccountTransfers, querySubAccountsForAccount, queryTrades, queryVipData, } from '../queries/futures';
import { NetworkIdByName, SnxV2NetworkIds, } from '../types/common';
import { AccountExecuteFunctions, OrderTypeEnum, PerpsProvider, SwapDepositToken, } from '../types/futures';
import { weiFromWei } from '../utils';
import { calculateTimestampForPeriod } from '../utils/date';
import { MarketKeyByAsset, calculateVolumes, encodeCloseOffchainOrderParams, encodeConditionalOrderParams, encodeModifyMarketMarginParams, encodeSubmitOffchainOrderParams, formatPerpsV2Market, formatPotentialTrade, formatV2DelayedOrder, getDecimalsForSwapDepositToken, getPerpsV2SubgraphUrl, getQuote, mapConditionalOrderFromContract, mapFuturesOrders, mapPerpsV2Positions, mapSnxV2Position, mapTrades, marketsForNetwork, mergeSnxV2PositionsData, } from '../utils/futures';
import { getReasonFromCode } from '../utils/token';
export default class FuturesService {
    constructor(sdk) {
        this.internalFuturesMarkets = {};
        this.sdk = sdk;
    }
    contractConfigs(chainId) {
        const v2Contracts = snxV2ContractsByNetwork(chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET);
        const commonContracts = commonContractsByNetwork(chainId);
        const tokenContracts = tokenContractsByNetwork(chainId);
        return {
            ...commonContracts,
            ...v2Contracts,
            ...tokenContracts,
        };
    }
    client(chainId) {
        const client = this.sdk.context.clients[chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET];
        if (client)
            return client;
        throw new Error(UNSUPPORTED_NETWORK);
    }
    /**
     * @desc Fetches futures markets
     * @param networkOverride - Network override options
     * @returns Futures markets array
     * @example
     * ```ts
     * import { KwentaSDK } from '@kwenta/sdk'
     *
     * const sdk = new KwentaSDK()
     * const markets = await sdk.snxPerpsV2.getMarkets()
     * console.log(markets)
     * ```
     */
    async getMarkets(chainId) {
        const enabledMarkets = marketsForNetwork(chainId, this.sdk.context.logError);
        const contracts = this.contractConfigs(chainId);
        const client = this.client(chainId);
        const { SystemStatus, PerpsV2MarketData, PerpsV2MarketSettings } = contracts;
        if (!SystemStatus || !PerpsV2MarketData || !PerpsV2MarketSettings) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        const [markets, minInitialMargin, minKeeperFee] = await client.multicall({
            allowFailure: false,
            contracts: [
                {
                    ...PerpsV2MarketData,
                    functionName: 'allProxiedMarketSummaries',
                },
                {
                    ...PerpsV2MarketSettings,
                    functionName: 'minInitialMargin',
                },
                {
                    ...PerpsV2MarketSettings,
                    functionName: 'minKeeperFee',
                },
            ],
        });
        const filteredMarkets = markets.filter((m) => {
            const marketKey = hexToString(m.key, { size: 32 });
            const market = enabledMarkets.find((market) => marketKey === market.key);
            return !!market;
        });
        const marketKeys = filteredMarkets.map((m) => m.key);
        const parametersCalls = marketKeys.map((key) => ({
            ...PerpsV2MarketSettings,
            functionName: 'parameters',
            args: [key],
        }));
        // @ts-expect-error Fix multicall type
        const marketParameters = await client.multicall({
            allowFailure: false,
            contracts: parametersCalls,
        });
        const [suspensions, reasons] = await client.readContract({
            ...SystemStatus,
            functionName: 'getFuturesMarketSuspensions',
            args: [marketKeys],
        });
        const futuresMarkets = filteredMarkets.map((m, i) => formatPerpsV2Market(m, 
        // @ts-ignore TODO: fix type
        marketParameters[i], {
            minKeeperFee: wei(minKeeperFee),
            minInitialMargin: wei(minInitialMargin),
        }, suspensions[i], getReasonFromCode(reasons[i]), chainId));
        return futuresMarkets;
    }
    // TODO: types
    // TODO: Improve the API for fetching positions
    /**
     *
     * @param address Smart margin or EOA address
     * @param futuresMarkets Array of objects with market address, market key, and asset
     * @returns Array of futures positions associated with the given address
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const markets = await sdk.snxPerpsV2.getMarkets()
     * const marketDetails = markets.map((m) => ({ address: m.market, marketKey: m.marketKey, asset: m.asset }))
     * const positions = await sdk.snxPerpsV2.getPositions('0x...', marketDetails)
     * console.log(positions)
     * ```
     */
    async getPositions(params) {
        const { account, futuresMarkets, chainId, status, accountType } = params;
        const positions = await queryPositions(account, accountType ?? 'account', chainId, status ?? 'all');
        const activePositions = await this.getActivePositions(account, futuresMarkets, chainId);
        return mergeSnxV2PositionsData(activePositions, mapPerpsV2Positions(PerpsProvider.SNX_V2_OP, positions), futuresMarkets);
    }
    /**
     * @desc Get the funding rate history for a given market
     * @param marketAsset Futures market asset
     * @param periodLength Period length in seconds
     * @returns Funding rate history for the given market
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const fundingRateHistory = await sdk.snxPerpsV2.getMarketFundingRatesHistory('ETH')
     * console.log(fundingRateHistory)
     * ```
     */
    async getMarketFundingRatesHistory(marketAsset, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS, period = 'Hourly', chainId = 10) {
        const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
        return queryFundingRateHistory(marketAsset, minTimestamp, period, chainId);
    }
    /**
     * @desc Get the daily volumes for all markets
     * @returns Object with the daily number of trades and volumes for all markets
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const dailyVolumes = await sdk.snxPerpsV2.getDailyVolumes()
     * console.log(dailyVolumes)
     * ```
     */
    async getDailyVolumes(chainId) {
        const minTimestamp = Math.floor(calculateTimestampForPeriod(PERIOD_IN_HOURS.ONE_DAY) / 1000);
        const response = await getFuturesAggregateStats(getPerpsV2SubgraphUrl(chainId), {
            first: 9999,
            where: {
                period: `${PERIOD_IN_SECONDS.ONE_HOUR}`,
                timestamp_gte: `${minTimestamp}`,
            },
        });
        return response ? calculateVolumes(response) : {};
    }
    /**
     * @desc Get the smart margin accounts associated with a given wallet address
     * @param walletAddress Wallet address
     * @returns Array of smart margin account addresses
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const accounts = await sdk.snxPerpsV2.getSmartMarginAccounts()
     * console.log(accounts)
     * ```
     */
    async getSmartMarginAccounts(walletAddress, chainId) {
        const { SmartMarginAccountFactory } = this.contractConfigs(chainId);
        if (!SmartMarginAccountFactory) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        const accounts = await this.client(chainId).readContract({
            ...SmartMarginAccountFactory,
            functionName: 'getAccountsOwnedBy',
            args: [walletAddress],
        });
        return accounts ?? [];
    }
    /**
     * @desc Get market margin transfer history for a given wallet address
     * @param walletAddress Wallet address
     * @returns Array of past margin trensfers between perps markets for the given wallet address
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const transfers = await sdk.snxPerpsV2.getMarketMarginTransfers()
     * console.log(transfers)
     * ```
     */
    async getMarketMarginTransfers(walletAddress, chainId) {
        return queryIsolatedMarginTransfers(walletAddress, chainId);
    }
    /**
     * @desc Get smart margin transfer history for a given wallet address
     * @param walletAddress Wallet address
     * @returns Array of past smart margin account deposits and withdrawals for the given wallet address
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const transfers = await sdk.snxPerpsV2.getSmartMarginAccountTransfers()
     * console.log(transfers)
     * ```
     */
    async getSmartMarginAccountTransfers(walletAddress, chainId) {
        const address = walletAddress ?? this.sdk.context.walletAddress;
        return querySmartMarginAccountTransfers(address, chainId);
    }
    /**
     * @desc Get the balance of a smart margin account
     * @param smartMarginAddress Smart margin account address
     * @returns Balance of the given smart margin account
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const balance = await sdk.snxPerpsV2.getSmartMarginAccountBalance('0x...')
     * console.log(balance)
     * ```
     */
    async getSmartMarginAccountBalance(smartMarginAddress, chainId) {
        const freeMargin = await this.client(chainId).readContract({
            address: smartMarginAddress,
            abi: SmartMarginAccountABI,
            functionName: 'freeMargin',
        });
        return wei(freeMargin);
    }
    /**
     * @desc Get important balances for a given smart margin account and wallet address.
     * @param walletAddress Wallet address
     * @param smartMarginAddress Smart margin account address
     * @returns Free margin and keeper balance (in ETH) for given smart margin address, as well as the wallet balance (in ETH), and sUSD allowance for the given wallet address.
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const balanceInfo = await sdk.snxPerpsV2.getSmartMarginBalanceInfo('0x...', '0x...')
     * console.log(balanceInfo)
     * ```
     */
    async getSmartMarginBalanceInfo(walletAddress, smartMarginAddress, chainId) {
        const { SUSD, USDC, DAI, MultiCall3_1 } = this.contractConfigs(chainId);
        const { marketsWithIdleMargin } = await this.getIdleMarginInMarkets(smartMarginAddress, chainId);
        const idleMarginByMarket = marketsWithIdleMargin.reduce((acc, curr) => {
            acc[curr.position.asset] = curr.position.remainingMargin;
            return acc;
        }, {});
        // Cover testnet case
        if (chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA) {
            if (!SUSD || !MultiCall3_1)
                throw new Error(UNSUPPORTED_NETWORK);
            const [freeMargin, keeperEthBal, walletEthBal, susdBalance, allowance] = await this.client(chainId).multicall({
                contracts: [
                    {
                        address: smartMarginAddress,
                        abi: SmartMarginAccountABI,
                        functionName: 'freeMargin',
                    },
                    {
                        ...MultiCall3_1,
                        functionName: 'getEthBalance',
                        args: [smartMarginAddress],
                    },
                    {
                        ...MultiCall3_1,
                        functionName: 'getEthBalance',
                        args: [walletAddress],
                    },
                    {
                        ...SUSD,
                        functionName: 'balanceOf',
                        args: [walletAddress],
                    },
                    {
                        ...SUSD,
                        functionName: 'allowance',
                        args: [walletAddress, smartMarginAddress],
                    },
                ],
                allowFailure: false,
            });
            return {
                freeMargin: weiFromWei(freeMargin.toString()),
                keeperEthBal: weiFromWei(keeperEthBal.toString()),
                walletEthBal: weiFromWei(walletEthBal.toString()),
                allowance: weiFromWei(allowance.toString()),
                balances: {
                    [SwapDepositToken.SUSD]: weiFromWei(susdBalance.toString()),
                    [SwapDepositToken.USDC]: wei(0, 6),
                    // [SwapDepositToken.USDT]: wei(usdtBalance, 6),
                    [SwapDepositToken.DAI]: wei(0),
                    // [SwapDepositToken.LUSD]: wei(lusdBalance),
                },
                allowances: {
                    [SwapDepositToken.SUSD]: wei(allowance),
                    [SwapDepositToken.USDC]: wei(0, 6),
                    // [SwapDepositToken.USDT]: wei(usdtAllowance, 6),
                    [SwapDepositToken.DAI]: wei(0),
                    // [SwapDepositToken.LUSD]: wei(lusdAllowance),
                },
                idleMarginByMarket,
            };
        }
        if (!SUSD || !USDC || !DAI || !MultiCall3_1)
            throw new Error(UNSUPPORTED_NETWORK);
        const [freeMargin, keeperEthBal, walletEthBal, susdBalance, allowance, usdcBalance, usdcAllowance, 
        // usdtBalance,
        // usdtAllowance,
        daiBalance, daiAllowance,
        // lusdBalance,
        // lusdAllowance,
        ] = await this.client(chainId).multicall({
            contracts: [
                {
                    address: smartMarginAddress,
                    abi: SmartMarginAccountABI,
                    functionName: 'freeMargin',
                },
                {
                    ...MultiCall3_1,
                    functionName: 'getEthBalance',
                    args: [smartMarginAddress],
                },
                {
                    ...MultiCall3_1,
                    functionName: 'getEthBalance',
                    args: [walletAddress],
                },
                {
                    ...SUSD,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...SUSD,
                    functionName: 'allowance',
                    args: [walletAddress, smartMarginAddress],
                },
                {
                    ...USDC,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...USDC,
                    functionName: 'allowance',
                    args: [walletAddress, smartMarginAddress],
                },
                {
                    ...DAI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...DAI,
                    functionName: 'allowance',
                    args: [walletAddress, smartMarginAddress],
                },
            ],
            allowFailure: false,
        });
        return {
            freeMargin: weiFromWei(freeMargin.toString()),
            keeperEthBal: weiFromWei(keeperEthBal.toString()),
            walletEthBal: weiFromWei(walletEthBal.toString()),
            allowance: weiFromWei(allowance.toString()),
            balances: {
                [SwapDepositToken.SUSD]: weiFromWei(susdBalance.toString()),
                [SwapDepositToken.USDC]: wei(usdcBalance.toString(), 6),
                // [SwapDepositToken.USDT]: wei(usdtBalance, 6),
                [SwapDepositToken.DAI]: weiFromWei(daiBalance.toString()),
                // [SwapDepositToken.LUSD]: wei(lusdBalance),
            },
            allowances: {
                [SwapDepositToken.SUSD]: weiFromWei(allowance.toString()),
                [SwapDepositToken.USDC]: wei(usdcAllowance.toString(), 6),
                // [SwapDepositToken.USDT]: wei(usdtAllowance, 6),
                [SwapDepositToken.DAI]: weiFromWei(daiAllowance.toString()),
                // [SwapDepositToken.LUSD]: wei(lusdAllowance),
            },
            idleMarginByMarket,
        };
    }
    /**
     * @desc Get the conditional orders created by a given smart margin account
     * @param account Smart margin account address
     * @returns Array of conditional orders created by the given smart margin account
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const orders = await sdk.snxPerpsV2.getConditionalOrders('0x...')
     * console.log(orders)
     * ```
     */
    async getConditionalOrders(account, chainId) {
        const orders = [];
        const orderId = await this.client(chainId).readContract({
            address: account,
            abi: SmartMarginAccountABI,
            functionName: 'conditionalOrderId',
        });
        // Limit to the latest 500
        const start = orderId > ORDERS_FETCH_SIZE ? orderId - BigInt(ORDERS_FETCH_SIZE) : BigInt(0);
        const orderCalls = Array(Number(orderId))
            .fill(0)
            .map((_, i) => {
            return {
                address: account,
                abi: SmartMarginAccountABI,
                functionName: 'getConditionalOrder',
                args: [start + BigInt(i)],
            };
        });
        const contractOrders = await this.client(chainId).multicall({
            contracts: orderCalls,
            allowFailure: false,
        });
        for (let i = 0; i < orderId; i++) {
            const contractOrder = contractOrders[i];
            // Checks if the order is still pending
            // Orders are never removed but all values set to zero so we check a zero value on price to filter pending
            if (contractOrder.targetPrice > BigInt(0)) {
                const order = mapConditionalOrderFromContract({ ...contractOrder, id: Number(start) + i }, account);
                orders.push(order);
            }
        }
        return orderBy(orders, ['id'], 'desc');
    }
    // Perps V2 read functions
    /**
     * @desc Get delayed orders associated with a given wallet address, for a specific market
     * @param account Wallet address
     * @param marketAddress Array of futures market addresses
     * @returns Delayed order for the given market address, associated with the given wallet address
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const order = await sdk.snxPerpsV2.getDelayedOrder('0x...', '0x...')
     * console.log(order)
     * ```
     */
    async getDelayedOrder(account, marketAddress, chainId) {
        const order = await this.client(chainId).readContract({
            abi: PerpsMarketABI,
            address: marketAddress,
            functionName: 'delayedOrders',
            args: [account],
        });
        return formatV2DelayedOrder(account, marketAddress, order);
    }
    /**
     * @desc Get delayed orders associated with a given wallet address
     * @param account Wallet address
     * @param marketAddresses Array of futures market addresses
     * @returns Array of delayed orders for the given market addresses, associated with the given wallet address
     */
    async getDelayedOrders(account, marketAddresses, chainId) {
        // TODO: [REFACTOR] Verify why ts complains here
        // @ts-ignore
        const orders = (await this.client(chainId).multicall({
            allowFailure: false,
            contracts: marketAddresses.map((market) => ({
                abi: PerpsMarketABI,
                address: market,
                functionName: 'delayedOrders',
                args: [account],
            })),
        }));
        return orders.map((order, i) => formatV2DelayedOrder(account, marketAddresses[i], order));
    }
    /**
     * @desc Generate a trade preview for a potential trade with a smart margin account.
     * @param account Smart margin account address
     * @param marketKey Futures market key
     * @param marketAddress Futures market address
     * @param tradeParams Object containing size delta, margin delta, order price, and leverage side
     * @returns Object containing details about the potential trade
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const preview = await sdk.snxPerpsV2.getSmartMarginTradePreview(
     *  '0x...',
     *  FuturesMarketKey.sBTCPERP,
     *  '0x...',
     * 	{
     * 		sizeDelta: wei(1),
     * 		marginDelta: wei(1),
     * 		orderPrice: wei(10000),
     * 		leverageSide: PositionSide.SHORT,
     * 	}
     * )
     * console.log(preview)
     * ```
     */
    async getSmartMarginTradePreview({ account, market, tradeParams, chainId, }) {
        const marketInternal = this.getInternalFuturesMarket(market, chainId);
        const preview = await marketInternal.getTradePreview(account, tradeParams.sizeDelta.toBigInt(), tradeParams.marginDelta.toBigInt(), tradeParams.orderPrice.toBigInt());
        const skewAdjustedPrice = await this.getSkewAdjustedPrice({
            price: tradeParams.orderPrice,
            chainId,
            ...market,
        });
        return formatPotentialTrade(preview, skewAdjustedPrice, tradeParams.sizeDelta, tradeParams.leverageSide, PerpsProvider.SNX_V2_OP);
    }
    /**
     * @desc Get the trade history for a given account on a specific market
     * @param marketAsset Market asset
     * @param walletAddress Account address
     * @param accountType Account type (smart or isolated)
     * @param pageLength Number of trades to fetch
     * @param positionId Position id
     * @returns Array of trades for the account on the given market.
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const trades = await sdk.snxPerpsV2.getTradesForMarket(
     *  FuturesMarketAsset.sBTC,
     *  '0x...',
     *  FuturesMarginType.SMART_MARGIN
     * )
     * console.log(trades)
     * ```
     */
    async getTradeHistory(params) {
        const response = await queryTrades({
            pageLength: params.pageLength ?? 16,
            ...params,
        });
        return response ? mapTrades(response) : [];
    }
    /**
     * @desc Get the idle margin in futures markets
     * @param accountOrEoa Wallet address or smart margin account address
     * @returns Total idle margin in markets and an array of markets with idle margin
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const idleMargin = await sdk.snxPerpsV2.getIdleMargin('0x...')
     * console.log(idleMargin)
     * ```
     */
    async getIdleMarginInMarkets(accountOrEoa, chainId) {
        const markets = this.markets ?? (await this.getMarkets(chainId));
        const filteredMarkets = [];
        const marketParams = [];
        for (const m of markets) {
            if (!m.isSuspended) {
                filteredMarkets.push(m);
                marketParams.push({ asset: m.asset, address: m.marketAddress });
            }
        }
        const positions = await this.getActivePositions(accountOrEoa, marketParams, chainId);
        return positions
            .filter((p) => p.remainingMargin.abs().gt(0) && p.size.eq(0))
            .reduce((acc, p) => {
            acc.totalIdleInMarkets = acc.totalIdleInMarkets.add(p.remainingMargin);
            const market = filteredMarkets.find((m) => m.asset === p.asset);
            if (market) {
                acc.marketsWithIdleMargin.push({
                    ...market,
                    position: p,
                });
            }
            return acc;
        }, {
            totalIdleInMarkets: wei(0),
            marketsWithIdleMargin: [],
        });
    }
    /**
     * @desc Get idle margin for given wallet address or smart margin account address
     * @param eoa Wallet address
     * @param account Smart margin account address
     * @returns Total idle margin, idle margin in markets, total wallet balance and the markets with idle margin for the given address(es).
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const idleMargin = await sdk.snxPerpsV2.getIdleMargin('0x...')
     * console.log(idleMargin)
     * ```
     */
    async getIdleMargin(eoa, chainId, account) {
        const idleMargin = await this.getIdleMarginInMarkets(account || eoa, chainId);
        const balances = await this.sdk.tokens.getBalancesAndAllowances(['SUSD'], eoa, [], chainId);
        const susdBal = balances.SUSD?.balance ?? '0';
        return {
            total: idleMargin.totalIdleInMarkets.add(susdBal),
            marketsTotal: idleMargin.totalIdleInMarkets,
            walletTotal: susdBal,
            marketsWithMargin: idleMargin.marketsWithIdleMargin,
        };
    }
    // This is on an interval of 15 seconds.
    /**
     * @desc Get futures trades for a given market
     * @param marketKey Futures market key
     * @param minTs Minimum timestamp
     * @param maxTs Maximum timestamp
     * @returns Array of trades for the given market
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const trades = await sdk.snxPerpsV2.getFuturesTrades(FuturesMarketKey.sBTCPERP, 0, 0)
     * console.log(trades)
     * ```
     */
    async getFuturesTrades(marketKey, minTs, maxTs, chainId) {
        const response = await queryFuturesTrades(marketKey, minTs, maxTs, chainId);
        return response ? mapTrades(response) : null;
    }
    // Contract mutations
    /**
     * @desc Approve a smart margin account deposit
     * @param smartMarginAddress Smart margin account address
     * @param token Swap deposit token
     * @param amount Amount to approve
     * @returns viem WriteContractReturnType
     */
    async approveSmartMarginDeposit({ address, token = SwapDepositToken.SUSD, amount = maxUint256, chainId, }) {
        const tokenContract = this.contractConfigs(chainId)[token];
        if (!tokenContract)
            throw new Error(UNSUPPORTED_NETWORK);
        const { request } = await this.client(chainId).simulateContract({
            ...tokenContract,
            account: this.sdk.context.walletAddress,
            args: [
                (token === SwapDepositToken.SUSD ? address : PERMIT2_ADDRESS),
                BigInt(amount.toString()),
            ],
            functionName: 'approve',
        });
        return this.sdk.context.walletClient.writeContract(request);
    }
    /**
     * @desc Deposit sUSD into a smart margin account
     * @param smartMarginAddress Smart margin account address
     * @param amount Amount to deposit
     * @param token Swap deposit token
     * @param slippage Slippage tolerance for the swap deposit
     * @returns viem WriteContractReturnType
     */
    async depositSmartMarginAccount({ address, amount, token = SwapDepositToken.SUSD, slippage = 0.15, chainId, }) {
        const contracts = this.contractConfigs(chainId);
        const tokenContract = contracts[token];
        const { SUSD } = contracts;
        if (!tokenContract || !SUSD)
            throw new Error(UNSUPPORTED_NETWORK);
        const walletAddress = await this.sdk.context.walletAddress;
        const commands = [];
        const inputs = [];
        let amountOutMin = amount;
        if (token !== SwapDepositToken.SUSD) {
            const { allowance } = await this.sdk.tokens.getPermit2AllowanceData(walletAddress, tokenContract.address, address, chainId);
            if (amount.gt(allowance)) {
                const { command, input } = await this.signPermit(address, tokenContract.address, chainId);
                commands.push(command);
                inputs.push(input);
            }
            const quote = await getQuote(token, amount);
            // TODO: Consider passing slippage into getQuote function
            amountOutMin = quote.sub(quote.mul(slippage).div(100));
            if (!amountOutMin) {
                throw new Error('Deposit failed: Could not get quote for swap deposit');
            }
            const path = tokenContract.address + LOW_FEE_TIER_BYTES.slice(2) + SUSD.address.slice(2);
            commands.push(AccountExecuteFunctions.UNISWAP_V3_SWAP);
            inputs.push(encodeAbiParameters(parseAbiParameters('uint256, uint256, bytes'), [
                wei(amount, getDecimalsForSwapDepositToken(token)).toBigInt(),
                amountOutMin.toBigInt(),
                path,
            ]));
        }
        else {
            commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
            inputs.push(encodeAbiParameters(parseAbiParameters(['int256']), [amountOutMin.toBigInt()]));
        }
        return this.client(chainId).simulateContract({
            account: walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    /**
     * @desc Withdraw sUSD from a smart margin account
     * @param smartMarginAddress Smart margin account address
     * @param amount Amount to withdraw
     * @returns viem WriteContractReturnType
     */
    async withdrawSmartMarginAccount({ address, amount, chainId }) {
        const { commands, inputs } = await this.batchIdleMarketMarginSweeps(address, chainId);
        commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
        inputs.push(encodeAbiParameters(parseAbiParameters(['int256']), [amount.neg().toBigInt()]));
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    async getSwapDepositQuote(token, amount) {
        if (token === SwapDepositToken.SUSD) {
            return amount;
        }
        const quote = await getQuote(token, amount);
        if (!quote) {
            throw new Error('Could not get Uniswap quote for swap deposit token balance');
        }
        return quote;
    }
    /**
     * @desc Modify the margin for a specific market in a smart margin account
     * @param address Smart margin account address
     * @param market Market address
     * @param marginDelta Amount to modify the margin by (can be positive or negative)
     * @returns viem WriteContractReturnType
     */
    async modifySmartMarginMarketMargin(params) {
        const { address, market, marginDelta, isOwner = true } = params;
        const commands = [];
        const inputs = [];
        if (marginDelta.gt(0)) {
            const freeMargin = await this.getSmartMarginAccountBalance(address, params.chainId);
            if (marginDelta.gt(freeMargin)) {
                // Margin delta bigger than account balance,
                // need to pull some from the users wallet or idle margin
                const { commands: sweepCommands, inputs: sweepInputs, idleMargin, } = await this.batchIdleMarketMarginSweeps(address, params.chainId);
                commands.push(...sweepCommands);
                inputs.push(...sweepInputs);
                const totalFreeMargin = idleMargin.totalIdleInMarkets.add(freeMargin);
                const depositAmount = marginDelta.gt(totalFreeMargin)
                    ? marginDelta.sub(totalFreeMargin).abs()
                    : wei(0);
                if (depositAmount.gt(0) && isOwner) {
                    // If we don't have enough from idle market margin then we pull from the wallet
                    commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
                    inputs.push(encodeAbiParameters(parseAbiParameters(['int256']), [depositAmount.toBigInt()]));
                }
            }
        }
        commands.push(AccountExecuteFunctions.PERPS_V2_MODIFY_MARGIN);
        inputs.push(encodeModifyMarketMarginParams(market, marginDelta));
        return this.client(params.chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    /**
     * @desc Modify the position size for a specific market in a smart margin account
     * @param address Smart margin account address
     * @param market Object containing the market key and address
     * @param sizeDelta Intended size change (positive or negative)
     * @param desiredFillPrice Desired fill price
     * @param cancelPendingReduceOrders Boolean describing if pending reduce orders should be cancelled
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.modifySmartMarginPositionSize(
     *   '0x...',
     *   { key: FuturesMarketKey.sBTCPERP, address: '0x...' },
     *   wei(1),
     *   wei(10000),
     *   true
     * )
     * console.log(txn)
     * ```
     */
    async modifySmartMarginPositionSize({ address, market, sizeDelta, desiredFillPrice, cancelPendingReduceOrders, cancelExpiredDelayedOrders, chainId, }) {
        const commands = [];
        const inputs = [];
        // Update keeper fee
        const dynamicFeesModuleEnabled = await this.isModuleEnabled(DYNAMIC_FEES_MODULE_ADDRESS, chainId);
        if (chainId === NetworkIdByName['mainnet-ovm'] && dynamicFeesModuleEnabled) {
            commands.push(AccountExecuteFunctions.PERPS_V2_SET_MIN_KEEPER_FEE);
            inputs.push('0x');
        }
        if (cancelExpiredDelayedOrders) {
            commands.push(AccountExecuteFunctions.PERPS_V2_CANCEL_OFFCHAIN_DELAYED_ORDER);
            inputs.push(encodeAbiParameters(parseAbiParameters(['address']), [market.marketAddress]));
        }
        if (cancelPendingReduceOrders) {
            const existingOrders = await this.getConditionalOrders(address, chainId);
            for (const o of existingOrders) {
                // Remove all pending reduce only orders if instructed
                if (o.asset === market.asset && o.reduceOnly) {
                    commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                    inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(o.id)]));
                }
            }
        }
        commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_OFFCHAIN_DELAYED_ORDER);
        inputs.push(encodeSubmitOffchainOrderParams(market.marketAddress, sizeDelta, desiredFillPrice));
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    /**
     * @desc Cancels a pending/expired delayed order, for the given market and account
     * @param marketAddress Market address
     * @param account Wallet address
     * @param isOffchain Boolean describing if the order is offchain or not
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.cancelDelayedOrder('0x...', '0x...', true)
     * console.log(txn)
     * ```
     */
    async cancelDelayedOrder({ marketAddress, account, isOffchain, }) {
        return this.sdk.context.walletClient.writeContract({
            address: marketAddress,
            abi: PerpsMarketABI,
            args: [account],
            functionName: isOffchain ? 'cancelOffchainDelayedOrder' : 'cancelDelayedOrder',
        });
    }
    /**
     * @desc Executes a pending delayed order, for the given market and account
     * @param asset Futures market asset
     * @param address Market address
     * @param account Wallet address
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.executeDelayedOffchainOrder(FuturesMarketAsset.sETH, '0x...', '0x...')
     * console.log(txn)
     * ```
     */
    async executeDelayedOffchainOrder({ marketAsset: asset, marketAddress, account, chainId, }) {
        const { Pyth } = this.contractConfigs(chainId);
        if (!Pyth)
            throw new Error(UNSUPPORTED_NETWORK);
        // get price update data
        const priceUpdateData = await this.sdk.prices.getPythPriceUpdateData(asset);
        const updateFee = await this.client(chainId).readContract({
            ...Pyth,
            args: [priceUpdateData],
            functionName: 'getUpdateFee',
        });
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: marketAddress,
            abi: PerpsMarketABI,
            args: [account, priceUpdateData],
            functionName: 'executeOffchainDelayedOrder',
            value: updateFee,
        });
    }
    /**
     * @desc Creates a smart margin account
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.createSmartMarginAccount()
     * console.log(txn)
     * ```
     */
    async createSmartMarginAccount(chainId) {
        const { SmartMarginAccountFactory } = this.contractConfigs(chainId);
        if (!SmartMarginAccountFactory)
            throw new Error(UNSUPPORTED_NETWORK);
        const { request } = await this.client(chainId).simulateContract({
            ...SmartMarginAccountFactory,
            account: this.sdk.context.walletAddress,
            functionName: 'newAccount',
        });
        return this.sdk.transactions.writeContract(request, optimism.id);
    }
    /**
     * @desc Get idle margin for given wallet address or smart margin account address
     * @param eoa Wallet address
     * @param account Smart margin account address
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const idleMargin = await sdk.snxPerpsV2.getIdleMargin('0x...')
     * console.log(idleMargin)
     * ```
     */
    async submitSmartMarginOrder({ market, walletAddress, smAddress, order, isOwner = true, options = {}, chainId, }) {
        const { cancelExpiredDelayedOrders, cancelPendingReduceOrders } = options;
        const commands = [];
        const inputs = [];
        // Update keeper fee
        const dynamicFeesModuleEnabled = await this.isModuleEnabled(DYNAMIC_FEES_MODULE_ADDRESS, chainId);
        if (chainId === NetworkIdByName['mainnet-ovm'] && dynamicFeesModuleEnabled) {
            commands.push(AccountExecuteFunctions.PERPS_V2_SET_MIN_KEEPER_FEE);
            inputs.push('0x');
        }
        if (cancelExpiredDelayedOrders) {
            commands.push(AccountExecuteFunctions.PERPS_V2_CANCEL_OFFCHAIN_DELAYED_ORDER);
            inputs.push(encodeAbiParameters(parseAbiParameters(['address']), [market.marketAddress]));
        }
        const [idleMargin, freeMargin] = await Promise.all([
            this.getIdleMargin(walletAddress, chainId, smAddress),
            this.getSmartMarginAccountBalance(smAddress, chainId),
        ]);
        // Sweep idle margin from other markets to account
        if (idleMargin.marketsTotal.gt(0)) {
            for (const m of idleMargin.marketsWithMargin) {
                commands.push(AccountExecuteFunctions.PERPS_V2_WITHDRAW_ALL_MARGIN);
                inputs.push(encodeAbiParameters(parseAbiParameters(['address']), [m.marketAddress]));
            }
        }
        if (order.marginDelta.gt(0)) {
            const totalFreeMargin = freeMargin.add(idleMargin.marketsTotal);
            const depositAmount = order.marginDelta.gt(totalFreeMargin)
                ? order.marginDelta.sub(totalFreeMargin).abs()
                : wei(0);
            if (depositAmount.gt(0) && isOwner) {
                // If there's not enough idle margin to cover the margin delta we pull it from the wallet
                commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
                inputs.push(encodeAbiParameters(parseAbiParameters(['int256']), [depositAmount.toBigInt()]));
            }
        }
        if (order.sizeDelta.abs().gt(0)) {
            if (!order.conditionalOrderInputs) {
                commands.push(AccountExecuteFunctions.PERPS_V2_MODIFY_MARGIN);
                inputs.push(encodeModifyMarketMarginParams(market.marketAddress, order.marginDelta));
                commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_OFFCHAIN_DELAYED_ORDER);
                inputs.push(encodeSubmitOffchainOrderParams(market.marketAddress, order.sizeDelta, order.desiredFillPrice));
            }
            else {
                commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                const params = encodeConditionalOrderParams(MarketKeyByAsset[market.asset], {
                    marginDelta: order.marginDelta,
                    sizeDelta: order.sizeDelta,
                    price: order.conditionalOrderInputs?.price,
                    desiredFillPrice: order.desiredFillPrice,
                }, order.conditionalOrderInputs.orderType, order.conditionalOrderInputs?.reduceOnly);
                inputs.push(params);
            }
        }
        if (order.takeProfit || order.stopLoss) {
            if (order.takeProfit) {
                commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                const encodedParams = encodeConditionalOrderParams(MarketKeyByAsset[market.asset], {
                    marginDelta: wei(0),
                    sizeDelta: order.takeProfit.sizeDelta,
                    price: order.takeProfit.price,
                    desiredFillPrice: order.takeProfit.desiredFillPrice,
                }, OrderTypeEnum.LIMIT, true);
                inputs.push(encodedParams);
            }
            if (order.stopLoss) {
                commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                const encodedParams = encodeConditionalOrderParams(MarketKeyByAsset[market.asset], {
                    marginDelta: wei(0),
                    sizeDelta: order.stopLoss.sizeDelta,
                    price: order.stopLoss.price,
                    desiredFillPrice: order.stopLoss.desiredFillPrice,
                }, OrderTypeEnum.STOP, true);
                inputs.push(encodedParams);
            }
        }
        const existingOrders = await this.getConditionalOrders(smAddress, chainId);
        const existingOrdersForMarket = existingOrders.filter((o) => o.asset === market.asset && o.reduceOnly);
        if (cancelPendingReduceOrders) {
            // Remove all pending reduce only orders if instructed
            const stops = cancelPendingReduceOrders.stop
                ? existingOrdersForMarket.filter((o) => o.orderType === OrderTypeEnum.STOP)
                : [];
            const limits = cancelPendingReduceOrders?.limit
                ? existingOrdersForMarket.filter((o) => o.orderType === OrderTypeEnum.LIMIT)
                : [];
            const all = [...stops, ...limits];
            for (const o of all) {
                commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(o.id)]));
            }
        }
        else {
            if (order.takeProfit) {
                // Remove only existing take profit to overwrite
                const existingTakeProfits = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) && o.orderType === OrderTypeEnum.LIMIT);
                if (existingTakeProfits.length) {
                    for (const tp of existingTakeProfits) {
                        commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                        inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(tp.id)]));
                    }
                }
            }
            if (order.stopLoss) {
                // Remove only existing stop loss to overwrite
                const existingStopLosses = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) && o.orderType === OrderTypeEnum.STOP);
                if (existingStopLosses.length) {
                    for (const sl of existingStopLosses) {
                        commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                        inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(sl.id)]));
                    }
                }
            }
        }
        return this.client(chainId).simulateContract({
            address: smAddress,
            account: walletAddress,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
            value: parseEther(order.keeperEthDeposit?.toString() ?? '0'),
        });
    }
    /**
     * @desc Closes a smart margin position
     * @param market Object containing market address and key
     * @param address Smart margin account address
     * @param desiredFillPrice Desired fill price
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.closeSmartMarginPosition(
     *  { address: '0x...', key: FuturesMarketKey.sBTCPERP },
     *  '0x...',
     *  wei(10000)
     * )
     * console.log(txn)
     * ```
     */
    async closeSmartMarginPosition({ market, address, desiredFillPrice, chainId, cancelExpiredDelayedOrders, }) {
        const commands = [];
        const inputs = [];
        // Update keeper fee
        const dynamicFeesModuleEnabled = await this.isModuleEnabled(DYNAMIC_FEES_MODULE_ADDRESS, chainId);
        if (chainId === NetworkIdByName['mainnet-ovm'] && dynamicFeesModuleEnabled) {
            commands.push(AccountExecuteFunctions.PERPS_V2_SET_MIN_KEEPER_FEE);
            inputs.push('0x');
        }
        if (cancelExpiredDelayedOrders) {
            commands.push(AccountExecuteFunctions.PERPS_V2_CANCEL_OFFCHAIN_DELAYED_ORDER);
            inputs.push(encodeAbiParameters(parseAbiParameters(['address']), [market.marketAddress]));
        }
        const existingOrders = await this.getConditionalOrders(address, chainId);
        for (const o of existingOrders) {
            if (o.asset === market.asset && o.reduceOnly) {
                commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(o.id)]));
            }
        }
        commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_CLOSE_OFFCHAIN_DELAYED_ORDER);
        inputs.push(encodeCloseOffchainOrderParams(market.marketAddress, desiredFillPrice));
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    /**
     * @desc Cancels a conditional order
     * @param account Smart margin account address
     * @param orderId Conditional order id
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.cancelConditionalOrder('0x...', 1)
     * console.log(txn)
     * ```
     */
    async cancelConditionalOrder({ account, orderId, chainId, }) {
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [
                [AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER],
                [encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(orderId)])],
            ],
            functionName: 'execute',
        });
    }
    /**
     * @desc Updates a conditional order by canceling the existing order and placing a new one
     * @param account Smart margin account address
     * @param asset Market asset
     * @param orderId Existing conditional order id
     * @param params Object containing the new conditonal order values
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.updateConditionalOrder('0x...', FuturesMarketAsset.sBTC, 1, { ...})
     * console.log(txn)
     * ```
     */
    async updateConditionalOrder({ account, asset, orderId, params, chainId, }) {
        const newOrderParams = encodeConditionalOrderParams(MarketKeyByAsset[asset], {
            marginDelta: params.marginDelta,
            sizeDelta: params.sizeDelta,
            price: params.price,
            desiredFillPrice: params.desiredFillPrice,
        }, params.orderType, params.reduceOnly);
        const commands = [
            AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER,
            AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER,
        ];
        const inputs = [
            encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(orderId)]),
            newOrderParams,
        ];
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
        });
    }
    /**
     * @desc Withdraws given smarkt margin account's keeper balance
     * @param account Smart margin account address
     * @param amount Amount to withdraw
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.withdrawAccountKeeperBalance('0x...', wei(1))
     * console.log(txn)
     * ```
     */
    async withdrawAccountKeeperBalance({ address, amount, chainId, }) {
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: address,
            abi: SmartMarginAccountABI,
            args: [
                [AccountExecuteFunctions.ACCOUNT_WITHDRAW_ETH],
                [encodeAbiParameters(parseAbiParameters(['uint256']), [amount.toBigInt()])],
            ],
            functionName: 'execute',
        });
    }
    /**
     * @desc Updates the stop loss and take profit values for a given smart margin account, based on the specified market.
     * @param marketKey Market key
     * @param account Smart margin account address
     * @param params Object containing the stop loss and take profit values
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.snxPerpsV2.updateStopLossAndTakeProfit(
     *   FuturesMarketKey.sBTCPERP,
     *   '0x...',
     *   {
     *     stopLoss: {
     * 	     price: wei(10000),
     * 	     sizeDelta: wei(1),
     * 	     desiredFillPrice: wei(10000),
     * 	     isCancelled: false,
     *     },
     *     takeProfit: {
     * 	     price: wei(10000),
     * 	     sizeDelta: wei(1),
     * 	     desiredFillPrice: wei(10000),
     * 	     isCancelled: false,
     *     },
     *     keeperEthDeposit: wei(0.1),
     *   }
     * )
     * console.log(txn)
     * ```
     */
    async updateStopLossAndTakeProfit({ asset, account, params, chainId, }) {
        const commands = [];
        const inputs = [];
        if (params.takeProfit || params.stopLoss) {
            const existingOrders = await this.getConditionalOrders(account, chainId);
            const existingStopLosses = [];
            const existingTakeProfits = [];
            for (const o of existingOrders) {
                if (o.asset === asset && o.size.abs().eq(SL_TP_MAX_SIZE) && o.reduceOnly) {
                    if (o.orderType === OrderTypeEnum.STOP_LOSS) {
                        existingStopLosses.push(o);
                    }
                    else if (o.orderType === OrderTypeEnum.TAKE_PROFIT) {
                        existingTakeProfits.push(o);
                    }
                }
            }
            if (params.takeProfit) {
                if (existingTakeProfits.length) {
                    for (const tp of existingTakeProfits) {
                        commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                        inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(tp.id)]));
                    }
                }
                if (!params.takeProfit.isCancelled) {
                    commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                    const encodedParams = encodeConditionalOrderParams(MarketKeyByAsset[asset], {
                        marginDelta: wei(0),
                        sizeDelta: params.takeProfit.sizeDelta,
                        price: params.takeProfit.price,
                        desiredFillPrice: params.takeProfit.desiredFillPrice,
                    }, OrderTypeEnum.LIMIT, true);
                    inputs.push(encodedParams);
                }
            }
            if (params.stopLoss) {
                if (existingStopLosses.length) {
                    for (const sl of existingStopLosses) {
                        commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                        inputs.push(encodeAbiParameters(parseAbiParameters(['uint256']), [BigInt(sl.id)]));
                    }
                }
                if (!params.stopLoss.isCancelled) {
                    commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                    const encodedParams = encodeConditionalOrderParams(MarketKeyByAsset[asset], {
                        marginDelta: wei(0),
                        sizeDelta: params.stopLoss.sizeDelta,
                        price: params.stopLoss.price,
                        desiredFillPrice: params.stopLoss.desiredFillPrice,
                    }, OrderTypeEnum.STOP, true);
                    inputs.push(encodedParams);
                }
            }
        }
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [commands, inputs],
            functionName: 'execute',
            value: parseEther(params.keeperEthDeposit?.toString() ?? '0'),
        });
    }
    /**
     * @desc Adds a delegate to a smart margin account
     * @param account Smart margin account address
     * @param delegate Delegate address
     * @returns ethers.js TransactionResponse object
     */
    async addDelegate({ account, delegatedAddress, chainId }) {
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [delegatedAddress],
            functionName: 'addDelegate',
        });
    }
    /**
     * @desc Removes a delegate from a smart margin account
     * @param delegate Delegate address
     * @returns ethers.js TransactionResponse object
     */
    async removeDelegate({ account, delegatedAddress, chainId }) {
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [delegatedAddress],
            functionName: 'removeDelegate',
        });
    }
    async getDelegatesForAccount(walletAddress, chainId) {
        return await queryDelegatesForAccount(walletAddress, chainId);
    }
    async getSubAccountsForAccount(walletAddress, chainId) {
        return await querySubAccountsForAccount(walletAddress, chainId);
    }
    /**
     * @desc Adds a delegate to a given smart margin account. Delegates are addresses that are given permission
     * to perform certain actions on behalf of the smart margin account, such as placing and cancelling orders.
     * @param account Smart margin account address
     * @param delegate Address to be added as a delegate
     * @param isPrepareOnly (optional) If true, prepares the transaction without sending it
     * @returns viem WriteContractReturnType
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const txn = await sdk.delegateSmartAccount(
     *   '0x...', // Smart margin account address
     *   '0x...', // Delegate address
     *   false    // isPrepareOnly
     * )
     * console.log(txn)
     * ```
     */
    async delegateSmartMarginAccount({ account, delegate, chainId, }) {
        return this.client(chainId).simulateContract({
            account: this.sdk.context.walletAddress,
            address: account,
            abi: SmartMarginAccountABI,
            args: [delegate],
            functionName: 'addDelegate',
        });
    }
    /**
     * @desc Checks if a given address is a delegate of a smart margin account.
     * @param account Smart margin account address
     * @param delegate Address to be checked
     * @returns Boolean indicating whether the address is a delegate of the smart margin account
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const isDelegate = await sdk.checkDelegateSmartMarginAccount(
     *   '0x...', // Smart margin account address
     *   '0x...'  // Address to be checked
     * )
     * console.log(isDelegate)
     * ```
     */
    async checkDelegateSmartMarginAccount({ account, delegate, chainId, }) {
        return this.client(chainId).readContract({
            address: account,
            abi: SmartMarginAccountABI,
            args: [delegate],
            functionName: 'delegates',
        });
    }
    /**
     * @desc Adjusts the given price, based on the current market skew.
     * @param price Price to adjust
     * @param address Market address
     * @param asset Market asset
     * @returns Adjusted price, based on the given market's skew.
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const adjustedPrice = await sdk.snxPerpsV2.getSkewAdjustedPrice(wei(10000), '0x...', FuturesMarketAsset.sBTC)
     * console.log(adjustedPrice)
     * ```
     */
    async getSkewAdjustedPrice({ price, marketAddress, asset, chainId, }) {
        const { PerpsV2MarketSettings } = this.contractConfigs(chainId);
        if (!PerpsV2MarketSettings)
            throw new Error(UNSUPPORTED_NETWORK);
        const key = MarketKeyByAsset[asset];
        const [skewScale, marketSkew] = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: [
                {
                    ...PerpsV2MarketSettings,
                    functionName: 'skewScale',
                    args: [stringToHex(key, { size: 32 })],
                },
                {
                    abi: PerpsMarketABI,
                    address: marketAddress,
                    functionName: 'marketSkew',
                },
            ],
        });
        const skewWei = wei(marketSkew);
        const scaleWei = wei(skewScale);
        return price.mul(skewWei.div(scaleWei).add(1));
    }
    // Private methods
    getInternalFuturesMarket({ asset, marketAddress: address }, chainId) {
        let market = this.internalFuturesMarkets[chainId]?.[address];
        if (market)
            return market;
        market = new PerpsV2MarketInternal(this.sdk, MarketKeyByAsset[asset], address);
        this.internalFuturesMarkets = {
            [chainId]: {
                ...this.internalFuturesMarkets[chainId],
                [address]: market,
            },
        };
        return market;
    }
    async batchIdleMarketMarginSweeps(smartMarginAddress, chainId) {
        const idleMargin = await this.getIdleMarginInMarkets(smartMarginAddress, chainId);
        const commands = [];
        const inputs = [];
        // Sweep idle margin from other markets to account
        if (idleMargin.totalIdleInMarkets.gt(0)) {
            for (const m of idleMargin.marketsWithIdleMargin) {
                commands.push(AccountExecuteFunctions.PERPS_V2_WITHDRAW_ALL_MARGIN);
                inputs.push(encodeAbiParameters(parseAbiParameters(['address']), [m.marketAddress]));
            }
        }
        return { commands, inputs, idleMargin };
    }
    async signPermit(smartMarginAddress, tokenAddress, chainId) {
        // Skip amount, we will use the permit to approve the max amount
        const data = await this.sdk.tokens.getPermit2TypedData({
            tokenAddress,
            owner: this.sdk.context.walletAddress,
            spender: smartMarginAddress,
        }, chainId);
        const signedMessage = await this.sdk.transactions.signTypedData(data);
        // TODO: This is probably wrong since viem refactor
        return {
            command: AccountExecuteFunctions.PERMIT2_PERMIT,
            input: encodeAbiParameters(parseAbiParameters(`${PERMIT_STRUCT}, bytes`), [
                data.message,
                signedMessage,
            ]),
        };
    }
    /**
     * @desc Get the order history for a given account
     * @param account Account address
     * @returns Array of orders for the account.
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const orders = await sdk.snxPerpsV2.getOrderHistory('0x...')
     * console.log(orders)
     * ```
     */
    async getOrderHistory(account, chainId) {
        const response = await queryOrders(account, chainId);
        return response.futuresOrders ? mapFuturesOrders(response.futuresOrders) : [];
    }
    /**
     * @desc Verifies if Module is enabled on the current network
     * @param address Module address
     * @returns boolean
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const enabled = await sdk.snxPerpsV2.isModuleEnabled('0x...')
     * console.log(enabled)
     * ```
     */
    async isModuleEnabled(address, chainId) {
        const { GnosisSafeL2 } = this.contractConfigs(chainId);
        if (!GnosisSafeL2) {
            return false;
        }
        const response = await this.client(chainId).readContract({
            ...GnosisSafeL2,
            functionName: 'isModuleEnabled',
            args: [address],
        });
        return response;
    }
    /**
     * @desc Gets Account VIP program data
     * @param account Account address
     * @returns VIP data
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const vipData = await sdk.vip.getVipData('0x...')
     * console.log(vipData)
     * ```
     */
    async getVipData(account, chainId) {
        const vipData = await queryVipData(account.toLowerCase(), chainId);
        if (!vipData) {
            return null;
        }
        return {
            lastClaimedAtBlock: vipData.lastClaimedAtBlockNumber
                ? Number(vipData.lastClaimedAtBlockNumber)
                : null,
            totalFeeRebate: weiFromWei(vipData.totalFeeRebate).toString(),
            allTimeRebates: weiFromWei(vipData.allTimeRebates).toString(),
            lastFeeRebateAccumulatedStartBlock: vipData.lastFeeAccumulatedStartBlockNumber
                ? Number(vipData.lastFeeAccumulatedStartBlockNumber)
                : null,
        };
    }
    /**
     * @desc Gets volume generated for a given account
     * @param walletAddress Wallet Address
     * @param from staring date
     * @param to ending date
     * @returns volume
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * const vipData = await sdk.futures.getVolumeByDate('0x...')
     * console.log(vipData)
     * ```
     */
    async getVolumeByDate(walletAddress, chainId, from, to) {
        const trades = await this.getTradeHistory({
            walletAddress,
            minTimestamp: from,
            maxTimestamp: to,
            chainId,
            pageLength: 9999,
        });
        return trades
            .reduce((acc, trade) => acc.add(trade.sizeDelta.abs().mul(trade.fillPrice)), wei(0))
            .toString();
    }
    /**
     * @desc Claims user's VIP rewards
     * @param chainId Chain Id
     * @returns Tx Request
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * await sdk.snxPerpsV2.claimVipRewards(chainId)
     * ```
     */
    async claimVipRewards(accountId, chainId) {
        const { FeeReimbursementClaim } = this.contractConfigs(chainId);
        if (!FeeReimbursementClaim)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...FeeReimbursementClaim,
            account: this.sdk.context.walletAddress,
            functionName: 'claim',
            args: [accountId.toLowerCase()],
        });
    }
    /**
     * @desc Get account's claim Period
     * @param accountId SM accountId
     * @returns Claim Period
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * await sdk.snxPerpsV2.getVipAccountClaimPeriod(chainId)
     * ```
     */
    async getVipAccountClaimPeriod(accountId, chainId) {
        const { FeeReimbursementApp } = this.contractConfigs(chainId);
        if (!FeeReimbursementApp)
            throw new Error(UNSUPPORTED_NETWORK);
        const [startBlockNumber, endBlockNumber] = await this.client(chainId).readContract({
            ...FeeReimbursementApp,
            args: [accountId],
            functionName: 'accountClaimPeriod',
        });
        return {
            startBlockNumber: Number(startBlockNumber),
            endBlockNumber: Number(endBlockNumber),
        };
    }
    /**
     * @desc Get account's fee reimbursed
     * @param accountId SM accountId
     * @returns fee reimbursed
     * @example
     * ```ts
     * const sdk = new KwentaSDK()
     * await sdk.snxPerpsV2.getVipFeeReimbursed(chainId)
     * ```
     */
    async getVipFeeReimbursed(accountId, chainId) {
        const feeReimbursed = await queryFeeReimbursed(accountId.toLowerCase(), chainId);
        return feeReimbursed.map((fr) => ({
            feeRebate: weiFromWei(fr.feeRebate).toString(),
            timestamp: Number(fr.timestamp),
            blockNumber: Number(fr.blockNumber),
            txHash: fr.txHash,
            rebateTokenPrice: weiFromWei(fr.rebateTokenPrice ?? '0')
                .mul(1e10) // OP price fees has 8units
                .toString(),
        }));
    }
    async getActivePositions(address, // Smart margin or EOA address
    futuresMarkets, chainId) {
        const marketDataContract = this.contractConfigs(chainId).PerpsV2MarketData;
        const client = this.client(chainId);
        if (!marketDataContract) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        const positionCalls = futuresMarkets.map(({ asset }) => ({
            ...marketDataContract,
            functionName: 'positionDetailsForMarketKey',
            args: [stringToHex(MarketKeyByAsset[asset], { size: 32 }), address],
        }));
        const liquidationCalls = futuresMarkets.map(({ address: marketAddress }) => ({
            address: marketAddress,
            abi: PerpsMarketABI,
            functionName: 'canLiquidate',
            args: [address],
        }));
        // TODO: Combine these two?
        const positionDetails = (await client.multicall({
            contracts: positionCalls,
        }));
        const canLiquidateState = await client.multicall({
            allowFailure: false,
            contracts: liquidationCalls,
        });
        // map the positions using the results
        const positions = positionDetails
            .filter((p) => p.result)
            .map((position, i) => {
            const canLiquidate = canLiquidateState[i];
            const { asset, address } = futuresMarkets[i];
            return mapSnxV2Position(position.result, canLiquidate, {
                asset,
                address: address,
            });
        });
        return positions;
    }
}

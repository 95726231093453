import { MAX_ORDER_FLOW_FEE, ORDER_FLOW_FEE, ZERO_WEI } from '@kwenta/sdk/constants'
import {
	type ConditionalOrderV2,
	FuturesMarginType,
	type FuturesMarketAsset,
	type FuturesTrade,
	OrderTypeEnum,
	type PerpsMarketV2,
	PerpsProvider,
	type PerpsV2Position,
	PositionSide,
	TransactionStatus,
} from '@kwenta/sdk/types'
import {
	calcPerennialMaxLeverage,
	calculateDesiredFillPrice,
	getDefaultPriceImpact,
	isZero,
} from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import type { ConditionOrderTableItemIsolated } from 'types/futures'
import type { Address } from 'viem'

import { ERROR_MESSAGES } from 'components/ErrorNotifier'
import { DEFAULT_DELAYED_CANCEL_BUFFER } from 'constants/defaults'
import { previewErrorI18n } from 'queries/futures/constants'
import { selectTransaction } from 'state/app/selectors'
import { KEEPER_ETH_GAS_FEE } from 'state/constants'
import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import {
	calculateKeeperFeesForOrders,
	formatFuturesPositions,
	orderPriceInvalidLabel,
	perennialOrderDirection,
	providerIsCrossMargin,
	stopLossValidity,
	takeProfitValidity,
	unserializeConditionalOrdersV2,
	unserializeDelayedOrders,
	unserializeIsolatedMarginBalanceInfo,
	unserializeIsolatedMarginTradePreview,
	unserializeTrades,
} from 'utils/futures'

import {
	selectEditPositionInputs,
	selectIsolatedMarginAccountData,
	selectIsolatedMarginProvider,
	selectIsolatedMaxLeverage,
	selectLeverageSide,
	selectMarkPriceInfos,
	selectMarkPrices,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectMarketOnchainPrice,
	selectMarketPriceInfo,
	selectMarkets,
	selectMarketsByProvider,
	selectOneClickTradingSelected,
	selectPerpsProvider,
	selectRawTradePanelInputs,
	selectSlTpTradeInputs,
	selectSnxPerpsV2Network,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectUserInfoShowAllMarkets,
	selectV2Markets,
} from '../common/selectors'
import type { MarkPrices } from '../common/types'
import { selectPerennialAccountData, selectPerennialMarkets } from '../perennial/selectors'
import type { AsyncOrderWithDetails } from '../snxPerpsV3/types'

import type { IsolatedMarginBalanceInfo } from './types'

export const selectSnxV2Account = createSelector(
	selectWallet,
	(state: RootState) => state.futures,
	(wallet, futures) => {
		return wallet
			? (futures.accounts[PerpsProvider.SNX_V2_OP]?.[wallet]?.account as Address)
			: undefined
	}
)

export const selectSnxV2AccountContext = createSelector(
	selectWallet,
	selectSnxPerpsV2Network,
	selectSnxV2Account,
	(wallet, network, accountId) => {
		return { wallet, network, accountId, provider: PerpsProvider.SNX_V2_OP, isIsolatedMargin: true }
	}
)

export const selectIsCreatingAccount = createSelector(
	selectTransaction,
	(tx) =>
		tx?.status === TransactionStatus.AwaitingExecution &&
		tx?.type === 'create_isolated_margin_account'
)

export const selectIsolatedMarginMarginDelta = createSelector(selectRawTradePanelInputs, (inputs) =>
	wei(inputs.marginDelta || 0)
)

export const selectMarginDeltaInputValue = createSelector(
	selectRawTradePanelInputs,
	(inputs) => inputs.marginDelta ?? ''
)

export const selectSnxV2AccountData = createSelector(
	selectWallet,
	(state: RootState) => state.futures,
	(wallet, futures) => {
		return wallet ? futures.accounts[PerpsProvider.SNX_V2_OP]?.[wallet] : null
	}
)

export const selectOptimismMarkPrices = createSelector(
	selectV2Markets,
	selectPrices,
	(optimismMarkets, prices) => {
		const markPrices: MarkPrices = {}
		return optimismMarkets.reduce((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			acc[market.asset] = wei(price).mul(
				wei(market.marketSkew).div(market.settings.skewScale).add(1)
			)
			return acc
		}, markPrices)
	}
)

export const selectAllIsolatedConditionalOrders = createSelector(
	selectMarketsByProvider,
	selectSnxV2AccountData,
	selectPerennialAccountData,
	selectMarkPriceInfos,
	(markets, snxAccount, perennialAccount, prices) => {
		const format = (orders: ConditionalOrderV2[], provider: PerpsProvider) =>
			orders.reduce<ConditionOrderTableItemIsolated[]>((acc, o) => {
				const price = prices[o.asset]
				const market = markets[provider]?.find((m) => m.asset === o.asset)
				if (!price || !market || market.marginType !== FuturesMarginType.ISOLATED_MARGIN) return acc

				const tradeDirection =
					provider === PerpsProvider.PERENNIAL_V2_ARB
						? perennialOrderDirection(o)
						: o.size.lt(0)
							? PositionSide.SHORT
							: PositionSide.LONG

				acc.push({
					...o,
					provider: market.provider,
					maxExecutorFee: wei(KEEPER_ETH_GAS_FEE),
					marketName: market.marketName,
					marketAddress: market.marketAddress,
					currentPrice: price,
					tradeDirection,
				})
				return acc
			}, [])

		return {
			[PerpsProvider.SNX_V2_OP]: format(
				unserializeConditionalOrdersV2(snxAccount?.conditionalOrders ?? []),
				PerpsProvider.SNX_V2_OP
			),
			[PerpsProvider.PERENNIAL_V2_ARB]: format(
				unserializeConditionalOrdersV2(perennialAccount?.conditionalOrders ?? []),
				PerpsProvider.PERENNIAL_V2_ARB
			),
		}
	}
)

export const selectAllPerennialSLTPOrders = createSelector(
	selectAllIsolatedConditionalOrders,
	(orders) => {
		return orders[PerpsProvider.PERENNIAL_V2_ARB].filter(
			(o) => o.orderTypeDisplay === 'Take Profit' || o.orderTypeDisplay === 'Stop Loss'
		)
	}
)

export const selectAllPerennialPositions = createSelector(
	selectPerennialAccountData,
	selectPerennialMarkets,
	selectAllPerennialSLTPOrders,
	selectMarkPrices,
	(account, markets, orders, prices) => {
		const positions = account?.positionHistory ?? ([] as PerpsV2Position<string>[])
		return formatFuturesPositions(positions, markets, orders, prices)
	}
)

export const selectPerennialActivePositions = createSelector(
	selectPerennialAccountData,
	selectPerennialMarkets,
	selectAllPerennialSLTPOrders,
	selectMarkPrices,
	(account, markets, orders, prices) => {
		const positions = account?.positions ?? ([] as PerpsV2Position<string>[])
		return formatFuturesPositions(positions, markets, orders, prices)
	}
)

export const selectPerennialReservedMarginForOrders = createSelector(
	selectAllIsolatedConditionalOrders,
	(pendingOrders) => {
		return pendingOrders[PerpsProvider.PERENNIAL_V2_ARB].reduce<Wei>((acc, order) => {
			if (order.marginDelta.gt(0)) {
				return acc.add(order.marginDelta)
			}
			return acc
		}, wei(0))
	}
)

export const selectRequiredEthForPendingOrders = createSelector(
	selectAllIsolatedConditionalOrders,
	(orders) => {
		return calculateKeeperFeesForOrders(
			orders[PerpsProvider.SNX_V2_OP].map((o) => ({
				...o,
				maxExecutorFee: wei(KEEPER_ETH_GAS_FEE),
			}))
		)
	}
)

export const selectAllSnxV2Positions = createSelector(
	selectSnxV2AccountData,
	selectAllIsolatedConditionalOrders,
	selectV2Markets,
	selectMarkPrices,
	(account, orders, markets, prices) => {
		return formatFuturesPositions(
			account?.positionHistory ?? [],
			markets,
			orders[PerpsProvider.SNX_V2_OP],
			prices
		)
	}
)

export const selectSmartMarginActivePositions = createSelector(
	selectSnxV2AccountData,
	selectAllIsolatedConditionalOrders,
	selectV2Markets,
	selectMarkPrices,
	(account, orders, markets, prices) => {
		return formatFuturesPositions(
			account?.positions ?? [],
			markets,
			orders[PerpsProvider.SNX_V2_OP],
			prices
		)
	}
)

export const selectIsolatedPositionsCount = createSelector(
	selectPerennialActivePositions,
	selectSmartMarginActivePositions,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(perennialPositions, snxPositions, userInfoShowAllMarkets, currentMarket) => {
		const snxPositionsCount = userInfoShowAllMarkets
			? snxPositions.length
			: snxPositions.filter((p) => p.market.asset === currentMarket).length

		const perennialPositionsCount = userInfoShowAllMarkets
			? perennialPositions.length
			: perennialPositions.filter((p) => p.market.asset === currentMarket).length

		return {
			[PerpsProvider.SNX_V2_OP]: snxPositionsCount,
			[PerpsProvider.PERENNIAL_V2_ARB]: perennialPositionsCount,
		}
	}
)

export const selectSubmittingFuturesTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)
export const selectIsMarketCapReached = createSelector(
	selectLeverageSide,
	selectMarketInfo,
	selectMarketIndexPrice,
	(leverageSide, marketInfo, marketAssetRate) => {
		const maxMarketValueUSD =
			(leverageSide === PositionSide.LONG
				? marketInfo?.marketLimitUsd.long
				: marketInfo?.marketLimitUsd.short) ?? wei(0)
		const oi = marketInfo?.openInterest ?? { long: wei(0), short: wei(0) }
		const marketSkew = marketInfo?.marketSkew ?? wei(0)
		if (!marketInfo) return true

		if (marketInfo?.provider === PerpsProvider.PERENNIAL_V2_ARB) {
			return maxMarketValueUSD.lte(oi[leverageSide].abs().mul(marketAssetRate))
		}

		return leverageSide === PositionSide.LONG
			? oi.long.add(marketSkew).div('2').abs().mul(marketAssetRate).gte(maxMarketValueUSD)
			: oi.short.sub(marketSkew).div('2').abs().mul(marketAssetRate).gte(maxMarketValueUSD)
	}
)

export const selectSelectedSnxV2Position = createSelector(
	selectSmartMarginActivePositions,
	selectMarketInfo,
	(positions, market) => positions.find((p) => p.market.asset === market?.asset)
)

export const selectSelectedPerennialPosition = createSelector(
	selectPerennialActivePositions,
	selectMarketAsset,
	(positions, asset) => {
		const position = positions.find((p) => p.market.asset === asset)
		return position
	}
)

export const selectSelectedPerennialMarket = createSelector(
	selectPerennialMarkets,
	selectMarketAsset,
	(markets, asset) => {
		const market = markets.find((m) => m.asset === asset)
		return market
	}
)

export const selectPerennialCalculatedMaxLeverage = createSelector(
	selectMarginDeltaInputValue,
	selectSelectedPerennialPosition,
	selectSelectedPerennialMarket,
	(state: RootState) => state.futures.oneClickTrading.estTxCost,
	selectOneClickTradingSelected,
	(marginDeltaInputValue, position, market, txCost, oneClickTradingSelected) => {
		if (!market) return wei(1)
		if (marginDeltaInputValue === '' || Number.isNaN(Number(marginDeltaInputValue))) {
			return market?.appMaxLeverage ?? wei(1)
		}

		const newCollateral = wei(marginDeltaInputValue)
			.add(position?.details.margin.remainingMargin || 0)
			.sub(oneClickTradingSelected ? txCost : 0)

		return calcPerennialMaxLeverage({
			margin: market.marginParameter ?? wei(0),
			minMargin: market.minInitialMargin,
			collateral: newCollateral,
		})
	}
)

export const selectIsolatedBalanceInfo = createSelector(
	selectPerpsProvider,
	selectSnxV2AccountData,
	selectPerennialAccountData,
	(provider, snxAccount, perennialAccount) => {
		const account = provider === PerpsProvider.SNX_V2_OP ? snxAccount : perennialAccount
		return account
			? unserializeIsolatedMarginBalanceInfo(account.balanceInfo)
			: ({
					freeMargin: wei(0),
					keeperEthBal: wei(0),
					allowance: wei(0),
					factoryApproved: false,
					idleMarginByMarket: {},
					totalMarginByMarket: {},
					balances: { SUSD: wei(0), USDC: wei(0), DAI: wei(0) },
					allowances: { SUSD: wei(0), USDC: wei(0), DAI: wei(0) },
				} as IsolatedMarginBalanceInfo)
	}
)

export const selectAvailableMarginInMarkets = createSelector(
	selectIsolatedBalanceInfo,
	(balanceInfo) => {
		const totalIdleMargin = Object.keys(balanceInfo.idleMarginByMarket).reduce((acc, key) => {
			const assetKey = key as FuturesMarketAsset
			return acc.add(balanceInfo.idleMarginByMarket[assetKey] ?? 0)
		}, wei(0))
		return totalIdleMargin
	}
)

export const selectSelectedSwapDepositToken = (state: RootState) =>
	state.futures.preferences.selectedSwapDepositToken

export const selectSwapDepositAllowance = createSelector(
	selectIsolatedBalanceInfo,
	selectSelectedSwapDepositToken,
	(smartMarginBalanceInfo, swapDepositToken) => smartMarginBalanceInfo.allowances[swapDepositToken]
)

export const selectApprovingSwapDeposit = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app.transaction,
	(submitting, transaction) => submitting && transaction?.type === 'approve_cross_margin'
)

export const selectKeeperEthBalance = createSelector(selectSnxV2AccountData, (account) =>
	wei(account?.balanceInfo.keeperEthBal || 0)
)

export const selectSnxV2TradePreview = createSelector(
	(state: RootState) => state.futures,
	({ tradePreviews }) => {
		const preview = tradePreviews[PerpsProvider.SNX_V2_OP]
		return preview ? unserializeIsolatedMarginTradePreview(preview) : null
	}
)

export const selectIsolatedTradePanelPreview = createSelector(
	selectIsolatedMarginProvider,
	(state: RootState) => state.futures,
	(provider, { tradePreviews }) => {
		if (!provider) return
		const preview = tradePreviews[provider]
		if (preview && preview.action === 'trade') {
			return unserializeIsolatedMarginTradePreview(preview)
		}
		return
	}
)

export const selectSnxV2TradePreviewKeeperDeposit = createSelector(
	selectSnxV2TradePreview,
	(preview) => preview?.keeperEthDeposit ?? ZERO_WEI
)

export const selectRequiredKeeperEthDeposit = createSelector(
	selectRequiredEthForPendingOrders,
	selectKeeperEthBalance,
	(requiredEth, ethBal) => {
		return wei(requiredEth).gt(ethBal) ? wei(requiredEth).sub(ethBal) : ZERO_WEI
	}
)

export const selectAllSnxV2SLTPOrders = createSelector(
	selectAllIsolatedConditionalOrders,
	(orders) => {
		return orders[PerpsProvider.SNX_V2_OP].filter(
			(o) =>
				o.reduceOnly && (o.orderTypeDisplay === 'Stop Loss' || o.orderTypeDisplay === 'Take Profit')
		)
	}
)

export const selectOrderFlowFee = createSelector(selectTradePanelInputs, ({ susdSizeDelta }) => {
	return susdSizeDelta.mul(wei(ORDER_FLOW_FEE / MAX_ORDER_FLOW_FEE)).abs()
})

export const selectIsolatedMarginTradePreview = createSelector(
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectTradeOrderType,
	selectIsolatedTradePanelPreview,
	({ nativeSizeDelta }, orderPrice, orderType, tradePreview) => {
		if (tradePreview) {
			const priceImpact = getDefaultPriceImpact(orderType)
			const conditionalOrderPrice = wei(orderPrice || 0)
			const price =
				orderType !== OrderTypeEnum.MARKET && conditionalOrderPrice.gt(0)
					? conditionalOrderPrice
					: tradePreview.fillPrice
			const desiredFillPrice = calculateDesiredFillPrice(nativeSizeDelta, price, priceImpact)

			return {
				...tradePreview,
				desiredFillPrice,
				leverage: tradePreview.margin.gt(0)
					? tradePreview.notionalValue.div(tradePreview.margin).abs().toNumber()
					: 0,
			}
		}
		return null
	}
)

export const selectIsolatedActivePositions = createSelector(
	selectPerpsProvider,
	selectSmartMarginActivePositions,
	selectPerennialActivePositions,
	(provider, snxV2, perennial) => {
		return provider === PerpsProvider.SNX_V2_OP ? snxV2 : perennial
	}
)

export const selectSnxV2LockedMarginInMarkets = createSelector(
	selectIsolatedBalanceInfo,
	selectMarkets,
	(balanceInfo, markets) => {
		return Object.keys(balanceInfo.idleMarginByMarket).reduce((acc, key) => {
			const assetKey = key as FuturesMarketAsset
			const market = markets.find((m) => m.asset === assetKey)

			if (market?.isSuspended) {
				return acc.add(balanceInfo.idleMarginByMarket[assetKey] ?? 0)
			}

			return acc.add(ZERO_WEI)
		}, wei(0))
	}
)

export const selectIdleAccountMargin = createSelector(
	selectPerpsProvider,
	selectAvailableMarginInMarkets,
	selectSnxV2LockedMarginInMarkets,
	selectIsolatedBalanceInfo,
	selectPerennialReservedMarginForOrders,
	(provider, availableInMarkets, lockedMargin, { freeMargin }, reservedMargin) => {
		const reserved = provider === PerpsProvider.PERENNIAL_V2_ARB ? reservedMargin : wei(0)
		return lockedMargin.add(availableInMarkets).add(freeMargin).sub(reserved)
	}
)

export const selectEditMarginAllowanceValid = createSelector(
	selectIsolatedMarginAccountData,
	selectIsolatedBalanceInfo,
	selectAvailableMarginInMarkets,
	selectEditPositionInputs,
	(account, { freeMargin, allowance }, idleInMarkets, { marginDelta }) => {
		if (!account || !marginDelta || Number.isNaN(Number(marginDelta))) return false
		if (account?.provider === PerpsProvider.PERENNIAL_V2_ARB)
			return wei(allowance || 0).gte(marginDelta)
		const totalIdleMargin = freeMargin.add(idleInMarkets)
		const marginDelatWei = wei(marginDelta || 0)
		const marginDeposit = marginDelatWei.sub(totalIdleMargin)
		return (
			totalIdleMargin.gte(marginDelatWei) ||
			wei(account.balanceInfo.allowance || 0).gte(marginDeposit)
		)
	}
)

export const selectSnxV2AvailableMargin = createSelector(
	selectAvailableMarginInMarkets,
	selectIsolatedBalanceInfo,
	(idleInMarkets, { freeMargin }) => {
		return idleInMarkets.add(freeMargin)
	}
)

export const selectPerennialAvailableMargin = createSelector(
	selectAvailableMarginInMarkets,
	selectIsolatedBalanceInfo,
	(idleInMarkets, { freeMargin }) => {
		return idleInMarkets.add(freeMargin)
	}
)

export const selectIsolatedAvailableMargin = createSelector(
	selectPerpsProvider,
	selectSnxV2AvailableMargin,
	selectPerennialAvailableMargin,
	(provider, snxV2AvailableMargin, perennialAvailablemargin) => {
		return provider === PerpsProvider.PERENNIAL_V2_ARB
			? perennialAvailablemargin
			: snxV2AvailableMargin
	}
)

export const selectIsolatedActivePositionsMargin = createSelector(
	selectIsolatedActivePositions,
	(positions) => {
		return positions.reduce((acc, p) => {
			return acc.add(p.details.margin.remainingMargin)
		}, wei(0))
	}
)

export const selectIsolatedAccountEquity = createSelector(
	selectPerpsProvider,
	selectIsolatedAvailableMargin,
	selectIsolatedActivePositionsMargin,
	selectPerennialReservedMarginForOrders,
	(provider, availableMargin, positionsMargin, reservedMargin) => {
		if (providerIsCrossMargin(provider)) return wei(0)
		return availableMargin.add(positionsMargin).add(reservedMargin)
	}
)

export const selectIsolatedTradePanelLeverage = createSelector(
	selectIsolatedAvailableMargin,
	selectTradePanelInputs,
	(availableMargin, { susdSize }) => {
		if (!availableMargin || availableMargin.eq(0) || !susdSize) return wei(0)
		return susdSize.div(availableMargin)
	}
)

export const selectAccountMarginTransfers = createSelector(selectSnxV2AccountData, (account) => {
	if (!account) return []
	return account?.accountTransfers ?? []
})

export const selectTradePreviewError = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_trade?.error
	}
)

export const selectCloseTradePreviewError = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_close?.error
	}
)

export const selectIsFetchingTradePreviewSmartMargin = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return (
			futures.queryStatuses.get_trade_preview_close?.status === FetchStatus.Loading ||
			futures.queryStatuses.get_trade_preview_edit?.status === FetchStatus.Loading ||
			futures.queryStatuses.get_trade_preview_trade?.status === FetchStatus.Loading
		)
	}
)

export const selectTradePreviewStatus = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_trade?.status
	}
)

type SmartMarginTrade = FuturesTrade & {
	market: PerpsMarketV2
}

export const selectAllSmartMarginTrades = createSelector(
	selectSnxV2AccountData,
	selectV2Markets,
	(smartMarginAccountData, markets) => {
		const trades = unserializeTrades(smartMarginAccountData?.trades ?? [])
		return trades.reduce<SmartMarginTrade[]>((acc, t) => {
			const market = markets.find((m) => m.asset === t.asset)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectSelectedPortfolioTimeframe = (state: RootState) =>
	state.futures.dashboard.selectedPortfolioTimeframe

export const selectCancellingConditionalOrder = (state: RootState) =>
	state.futures.cancellingConditionalOrder

export const selectMaxUsdSizeInput = createSelector(
	selectIsolatedMaxLeverage,
	selectMarginDeltaInputValue,
	(maxLeverage, marginDelta) => {
		return maxLeverage.mul(marginDelta || 0)
	}
)

export const selectTotalFuturesFees = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return {
			[PerpsProvider.SNX_V2_OP]: wei(data.totalFeesPaid[PerpsProvider.SNX_V2_OP] ?? '0'),
			[PerpsProvider.PERENNIAL_V2_ARB]: wei(
				data.totalFeesPaid[PerpsProvider.PERENNIAL_V2_ARB] ?? '0'
			),
		}
	}
)

export const selectFuturesFeesForAccount = createSelector(
	selectSnxV2AccountData,
	selectPerennialAccountData,
	(snxV2Account, perennialAccount) => {
		return {
			[PerpsProvider.SNX_V2_OP]: wei(snxV2Account?.totalFeesPaid ?? '0'),
			[PerpsProvider.PERENNIAL_V2_ARB]: wei(perennialAccount?.totalFeesPaid ?? '0'),
		}
	}
)

export const selectFeeShare = createSelector(
	selectFuturesFeesForAccount,
	selectTotalFuturesFees,
	(accountFees, totalFees) => {
		return {
			[PerpsProvider.SNX_V2_OP]: totalFees[PerpsProvider.SNX_V2_OP].gt(0)
				? accountFees[PerpsProvider.SNX_V2_OP].div(totalFees[PerpsProvider.SNX_V2_OP])
				: ZERO_WEI,
			[PerpsProvider.PERENNIAL_V2_ARB]: totalFees[PerpsProvider.PERENNIAL_V2_ARB].gt(0)
				? accountFees[PerpsProvider.PERENNIAL_V2_ARB].div(totalFees[PerpsProvider.PERENNIAL_V2_ARB])
				: ZERO_WEI,
		}
	}
)

export const selectSMPlaceOrderTranslationKey = createSelector(
	selectPerpsProvider,
	selectSelectedSnxV2Position,
	selectSelectedPerennialPosition,
	selectTradeOrderType,
	(provider, snxV2Position, perennialPosition, orderType) => {
		if (orderType === OrderTypeEnum.LIMIT) return 'futures.market.trade.button.place-limit-order'
		if (orderType === OrderTypeEnum.STOP) return 'futures.market.trade.button.place-stop-order'
		if (
			(provider === PerpsProvider.SNX_V2_OP && !!snxV2Position) ||
			(provider === PerpsProvider.PERENNIAL_V2_ARB && !!perennialPosition)
		)
			return 'futures.market.trade.button.modify-position'
		return 'futures.market.trade.button.open-position'
	}
)

export const selectSwapDepositCustomSlippage = (state: RootState) =>
	state.futures.snxV2.swapDepositCustomSlippage

export const selectSwapDepositSlippage = createSelector(
	selectSwapDepositCustomSlippage,
	(state: RootState) => state.futures.snxV2.swapDepositSlippage,
	(customSlippage, slippage) => (customSlippage ? Number(customSlippage) : slippage ?? 0)
)

export const selectIsolatedGlobalTradeHistory = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	(state: RootState) => state.futures.providerData.globalTradeHistory,
	(perpsProvider, marketAsset, tradesHistory) => {
		if (!marketAsset || providerIsCrossMargin(perpsProvider)) return []
		return unserializeTrades(tradesHistory[perpsProvider][marketAsset] ?? [])
	}
)

export const selectOldestIsolatedGlobalTrade = createSelector(
	selectIsolatedGlobalTradeHistory,
	(history) => history[history.length - 1]
)

export const selectIsolatedTradePanelSLTPValidity = createSelector(
	selectSlTpTradeInputs,
	selectIsolatedMarginTradePreview,
	selectMarketIndexPrice,
	selectMarketOnchainPrice,
	selectLeverageSide,
	(
		{ stopLossPrice, takeProfitPrice },
		smTradePreview,
		currentPrice,
		onChainPrice,
		leverageSide
	) => {
		const tpValidity = takeProfitValidity(takeProfitPrice, leverageSide, currentPrice, onChainPrice)
		const slValidity = stopLossValidity(
			stopLossPrice,
			smTradePreview?.liqPrice || wei(0),
			leverageSide,
			currentPrice,
			onChainPrice
		)
		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectIsolatedMarginDelayedOrders = createSelector(
	selectSnxV2AccountData,
	selectV2Markets,
	(account, markets) => {
		if (!account) return []
		const orders = unserializeDelayedOrders(account?.delayedOrders ?? [])

		return orders.reduce<AsyncOrderWithDetails[]>((acc, o) => {
			const market = markets.find((m) => m.asset === o.asset)

			if (market?.provider === PerpsProvider.SNX_V2_OP) {
				const timePastExecution = Math.floor(Date.now() - o.executableAtTimestamp)
				const expirationTime = o.executableAtTimestamp + market.settings.offchainDelayedOrderMaxAge
				const executable = timePastExecution <= market.settings.offchainDelayedOrderMaxAge

				const order = {
					market,
					account: Number(account.account),
					size: o.size,
					executableStartTime: o.executableAtTimestamp / 1000,
					expirationTime: expirationTime,
					marginDelta: wei(0),
					desiredFillPrice: wei(o.desiredFillPrice),
					side: o.side,
					settlementWindowDuration: market.settings.offchainDelayedOrderMaxAge,
					settlementFee: o.keeperDeposit,
					isExecutable: executable,
					isStale:
						timePastExecution >
						DEFAULT_DELAYED_CANCEL_BUFFER + (market?.settings.offchainDelayedOrderMaxAge ?? 0),
				}
				acc.push(order)
			}
			return acc
		}, [])
	}
)

export const selectSnxV2PendingDelayedOrder = createSelector(
	selectIsolatedMarginDelayedOrders,
	selectMarketAsset,
	(delayedOrders, marketAsset) => {
		return delayedOrders.find((o) => o.market.asset === marketAsset)
	}
)

export const selectIsolatedTradeDisabledReason = createSelector(
	selectIsolatedMarginTradePreview,
	selectTradePreviewError,
	selectMarketPriceInfo,
	selectIsolatedTradePanelLeverage,
	selectIsolatedMaxLeverage,
	selectMarketInfo,
	selectIsMarketCapReached,
	selectSelectedSnxV2Position,
	selectLeverageSide,
	selectTradePanelOrderPriceInput,
	selectMarketIndexPrice,
	selectTradeOrderType,
	selectMaxUsdSizeInput,
	selectTradePanelInputs,
	selectPerpsProvider,
	selectTradePreviewStatus,
	selectSnxV2PendingDelayedOrder,
	selectIsolatedTradePanelSLTPValidity,
	(
		previewTrade,
		previewError,
		indexPrice,
		leverage,
		maxLeverageValue,
		marketInfo,
		isMarketCapReached,
		position,
		leverageSide,
		orderPrice,
		marketAssetRate,
		orderType,
		maxUsdInputAmount,
		{ susdSize },
		selectedPerpsProvider,
		previewStatus,
		openOrder,
		sltpValidity
	) => {
		const MessageType = {
			error: 'error',
			warn: 'warn',
		} as const
		if (previewError) {
			return { message: previewErrorI18n(previewError), show: MessageType.error }
		}
		if (previewTrade?.statusMessage && previewTrade.statusMessage !== 'Success') {
			return { message: previewTrade?.statusMessage, show: MessageType.error }
		}

		const maxLeverage = marketInfo?.appMaxLeverage ?? wei(25)

		const indexPriceWei = indexPrice?.price ?? ZERO_WEI
		let canLiquidate =
			previewTrade?.provider === PerpsProvider.SNX_V2_OP
				? (previewTrade?.newSize.gt(0) && indexPriceWei.lt(previewTrade?.liqPrice)) ||
					(previewTrade?.newSize.lt(0) && indexPriceWei.gt(previewTrade?.liqPrice))
				: (previewTrade?.side === PositionSide.LONG && indexPriceWei.lt(previewTrade?.liqPrice)) ||
					(previewTrade?.side === PositionSide.SHORT && indexPriceWei.gt(previewTrade?.liqPrice))
		canLiquidate = canLiquidate && !previewTrade?.liqPrice.eq(0)

		if (canLiquidate) {
			return {
				show: MessageType.warn,
				message: 'Position can be liquidated',
			}
		}
		if (leverage.gt(maxLeverageValue)) {
			return {
				show: MessageType.warn,
				message: `Max leverage ${maxLeverage.toString(0)}x exceeded`,
			}
		}
		if (marketInfo?.isSuspended) {
			return {
				show: MessageType.warn,
				message: 'Market suspended',
			}
		}
		if (
			isMarketCapReached &&
			(!position?.details.side || position?.details.side === leverageSide)
		) {
			return {
				show: MessageType.warn,
				message: 'Open interest limit exceeded',
			}
		}

		const invalidReason = orderPriceInvalidLabel(
			orderPrice,
			leverageSide,
			marketAssetRate,
			orderType
		)

		if (orderType !== OrderTypeEnum.MARKET && !!invalidReason) {
			return {
				message: `Invalid ${orderType} price`,
			}
		}
		if (susdSize.gt(maxUsdInputAmount)) {
			return {
				show: MessageType.warn,
				message: 'Max trade size exceeded',
			}
		}

		if (isZero(susdSize)) {
			return { message: 'Trade size required' }
		}
		if (orderType === OrderTypeEnum.MARKET && !!openOrder && !openOrder.isStale) {
			return {
				show: MessageType.warn,
				message: ERROR_MESSAGES.ORDER_PENDING,
			}
		}
		if (selectedPerpsProvider === PerpsProvider.SNX_V2_OP) {
			if (previewTrade?.status !== 0 || previewStatus === FetchStatus.Loading) {
				return { message: 'awaiting_preview' }
			}
			if (orderType !== OrderTypeEnum.MARKET && isZero(orderPrice)) {
				return { message: 'trade price required' }
			}
		}

		if (sltpValidity.stopLoss.invalidLabel) {
			return {
				show: MessageType.warn,
				message: 'Invalid Stop Loss Price',
			}
		}

		return null
	}
)

export const selectWithdrawableFromIsolatedAccount = createSelector(
	selectAvailableMarginInMarkets,
	selectIsolatedBalanceInfo,
	(idleInMarkets, { freeMargin }) => {
		return idleInMarkets.add(freeMargin)
	}
)

import { memo } from 'react'
import Confetti from 'react-confetti'

import { selectHidePoints } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import { selectUserLevelUpActivity } from 'state/points/selectors'

const RectangleConfetti = memo(() => {
	const drawRectangle = (ctx: CanvasRenderingContext2D) => {
		ctx.beginPath()
		ctx.rect(-5, -5, 20, 5) // Adjust size as needed
		ctx.fill()
	}

	return (
		<Confetti
			recycle={false}
			colors={['#a864fd', '#29cdff', '#7854F2', '#ff718d', '#fdff6a']}
			drawShape={drawRectangle}
		/>
	)
})

export const LevelUpConfetti = memo(() => {
	const hidePoints = useAppSelector(selectHidePoints)
	const levelUpActivity = useAppSelector(selectUserLevelUpActivity)
	if (!levelUpActivity || hidePoints) {
		return null
	}

	return <RectangleConfetti />
})

export default RectangleConfetti

import request, { gql } from 'graphql-request';
import Wei from '@kwenta/wei';
import { toHex } from 'viem';
import { DEFAULT_NUMBER_OF_TRADES, ISOLATED_MARGIN_FRAGMENT, SMART_MARGIN_FRAGMENT, } from '../constants/futures';
import { getPerpsV2SubgraphUrl, mapMarginTransfers, mapSmartMarginTransfers, } from '../utils/futures';
export const queryAccountsFromSubgraph = async (walletAddress, chainId = 10) => {
    if (!walletAddress)
        return [];
    const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
			query crossMarginAccounts($owner: String!) {
				crossMarginAccounts(where: { owner: $owner }) {
					id
					owner
				}
			}
		`, { owner: walletAddress });
    return response?.crossMarginAccounts.map((cm) => cm.id) || [];
};
function createFuturesAggregateStatsQuery(filter) {
    let whereClause = '';
    if (filter) {
        const conditions = Object.entries(filter).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc.push(`${key}: "${value}"`);
            }
            return acc;
        }, []);
        if (conditions.length > 0) {
            whereClause = `where: { ${conditions.join(', ')} }`;
        }
    }
    return gql `
    query getFuturesAggregateStats($first: Int, $orderBy: String, $orderDirection: String) {
      futuresAggregateStats(
        first: $first
        orderBy: $orderBy
        orderDirection: $orderDirection
        ${whereClause}
      ) {
        id
        period
        timestamp
        marketKey
        asset
        trades
        volume
        feesKwenta
        feesSynthetix
        feesCrossMarginAccounts
      }
    }
  `;
}
function createFuturesTradesQuery(filter) {
    let whereClause = '';
    if (filter) {
        const conditions = Object.entries(filter).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc.push(`${key}: "${value}"`);
            }
            return acc;
        }, []);
        if (conditions.length > 0) {
            whereClause = `where: { ${conditions.join(', ')} }`;
        }
    }
    return gql `
    query getFuturesTrades($first: Int, $orderBy: String, $orderDirection: String) {
      futuresTrades(
        first: $first
        orderBy: $orderBy
        orderDirection: $orderDirection
        ${whereClause}
      ) {
        id
        timestamp
        account
        abstractAccount
        accountType
        margin
        size
        asset
        marketKey
        price
        positionId
        positionSize
        positionClosed
        pnl
        feesPaid
        fundingAccrued
        keeperFeesPaid
        orderType
        trackingCode
				vipTier
				blockNumber
				feeRebate
      }
    }
  `;
}
const futuresStatsQuery = gql `
	query getFuturesStats($first: Int, $orderBy: String, $orderDirection: String) {
		futuresStats(first: $first, orderBy: $orderBy, orderDirection: $orderDirection) {
			account
			pnl
			pnlWithFeesPaid
			liquidations
			totalTrades
			totalVolume
		}
	}
`;
export async function getFuturesAggregateStats(url, variables) {
    const response = await request(url, createFuturesAggregateStatsQuery(variables.where), variables);
    return response.futuresAggregateStats.map((stat) => ({
        ...stat,
        period: new Wei(stat.period, 0),
        timestamp: new Wei(stat.timestamp, 0),
        trades: new Wei(stat.trades, 0),
        volume: new Wei(stat.volume, 0),
        feesKwenta: new Wei(stat.feesKwenta, 0),
        feesSynthetix: new Wei(stat.feesSynthetix, 0),
        feesCrossMarginAccounts: new Wei(stat.feesCrossMarginAccounts, 0),
    }));
}
export async function getFuturesStats(url, variables) {
    const response = await request(url, futuresStatsQuery, variables);
    return response.futuresStats.map((stat) => ({
        account: stat.account,
        pnl: new Wei(stat.pnl, 0),
        pnlWithFeesPaid: new Wei(stat.pnlWithFeesPaid, 0),
        liquidations: new Wei(stat.liquidations, 0),
        totalTrades: new Wei(stat.totalTrades, 0),
        totalVolume: new Wei(stat.totalVolume, 0),
    }));
}
export async function getFuturesTrades(url, variables) {
    const response = await request(url, createFuturesTradesQuery(variables.where), variables);
    return response.futuresTrades.map((trade) => ({
        ...trade,
        timestamp: new Wei(trade.timestamp, 0),
        margin: new Wei(trade.margin, 0),
        size: new Wei(trade.size, 0),
        price: new Wei(trade.price, 0),
        positionSize: new Wei(trade.positionSize, 0),
        pnl: new Wei(trade.pnl, 0),
        feesPaid: new Wei(trade.feesPaid, 0),
        fundingAccrued: new Wei(trade.fundingAccrued, 0),
        keeperFeesPaid: new Wei(trade.keeperFeesPaid, 0),
    }));
}
export const queryTrades = async (params) => {
    const filter = {
        accountType: 'smart_margin',
    };
    if (params.walletAddress) {
        filter.account = params.walletAddress;
    }
    if (params.marketAsset) {
        filter.asset = toHex(params.marketAsset, { size: 32 });
    }
    if (params.minTimestamp) {
        filter.timestamp_gt = params.minTimestamp.toString();
    }
    if (params.maxTimestamp) {
        filter.timestamp_lt = params.maxTimestamp.toString();
    }
    if (params.positionId) {
        filter.positionId = params.positionId;
    }
    return getFuturesTrades(getPerpsV2SubgraphUrl(params.chainId), {
        first: params.pageLength,
        where: filter,
        orderDirection: 'desc',
        orderBy: 'timestamp',
    });
};
export const queryPositions = async (account, accountType, chainId, status) => {
    const isOpen = status === 'open' ? 'isOpen: true' : status === 'closed' ? 'isOpen: false' : '';
    const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
			query futuresPositions($account: String!) {
				futuresPositions(where: { ${accountType === 'eoa' ? 'account: $account' : 'abstractAccount: $account'}
				${isOpen}
				} first: 9999, orderBy: openTimestamp, orderDirection: desc) {
					id
					lastTxHash
					openTimestamp
					closeTimestamp
					timestamp
					market
					marketKey
					asset
					account
					abstractAccount
					accountType
					isOpen
					isLiquidated
					trades
					totalVolume
					initialMargin
					margin
					pnl
					feesPaid
					netFunding
					pnlWithFeesPaid
					netTransfers
					totalDeposits
					fundingIndex
					entryPrice
					avgEntryPrice
					lastPrice
					exitPrice
					size
					allTrades (orderBy: timestamp, orderDirection: asc) {
						size
						timestamp
					}
				}
			}
		`, { account });
    return response.futuresPositions;
};
export const queryIsolatedMarginTransfers = async (account, chainId = 10) => {
    const response = await request(getPerpsV2SubgraphUrl(chainId), ISOLATED_MARGIN_FRAGMENT, {
        walletAddress: account,
    });
    return response ? mapMarginTransfers(response.futuresMarginTransfers) : [];
};
export const querySmartMarginAccountTransfers = async (account, chainId = 10) => {
    const response = await request(getPerpsV2SubgraphUrl(chainId), SMART_MARGIN_FRAGMENT, {
        walletAddress: account,
    });
    return response ? mapSmartMarginTransfers(response.smartMarginAccountTransfers) : [];
};
export const queryFuturesTrades = (marketKey, minTs, maxTs, chainId = 10) => {
    return getFuturesTrades(getPerpsV2SubgraphUrl(chainId), {
        first: DEFAULT_NUMBER_OF_TRADES,
        where: {
            marketKey: toHex(marketKey, { size: 32 }),
            timestamp_gt: minTs,
            timestamp_lt: maxTs,
        },
        orderDirection: 'desc',
        orderBy: 'timestamp',
    });
};
export const queryFundingRateHistory = async (marketAsset, minTimestamp, period, chainId = 10) => {
    const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
			query fundingRateUpdate(
				$marketAsset: Bytes!
				$minTimestamp: BigInt!
				$period: FundingRatePeriodType!
			) {
				fundingRatePeriods(
					where: { asset: $marketAsset, timestamp_gt: $minTimestamp, period: $period }
					first: 2000
				) {
					timestamp
					fundingRate
				}
			}
		`, { marketAsset: toHex(marketAsset, { size: 32 }), minTimestamp, period });
    return response.fundingRatePeriods.map((x) => ({
        timestamp: Number(x.timestamp) * 1000,
        fundingRate: Number(x.fundingRate),
    }));
};
export const queryDelegatesForAccount = async (walletAddress, chainId = 10) => {
    const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
			query Delegates($walletAddress: String!) {
				delegatedAccounts(first: 1000, where: { caller: $walletAddress }) {
					delegate
				}
			}
		`, { walletAddress });
    const delegates = (await response.delegatedAccounts.map((entry) => ({
        delegate: entry.delegate,
    })));
    return delegates;
};
export const querySubAccountsForAccount = async (walletAddress, chainId = 10) => {
    const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
			query SubAccounts($walletAddress: String!) {
				delegatedAccounts(first: 1000, where: { delegate: $walletAddress }) {
					caller
				}
			}
		`, { walletAddress });
    const subAccounts = (await response.delegatedAccounts.map((entry) => ({
        caller: entry.caller,
    })));
    return subAccounts;
};
export const queryOrders = async (account, chainId = 10) => {
    return request(getPerpsV2SubgraphUrl(chainId), gql `
			query FuturesOrders($account: String!) {
				futuresOrders(where: { account: $account }, orderBy: "timestamp", orderDirection: "desc") {
					id
					size
					marketKey
					account
					abstractAccount
					orderId
					targetPrice
					marginDelta
					timestamp
					orderType
					status
					keeper
					txnHash
					fillPrice
					reduceOnly
				}
			}
		`, { account });
};
export const queryVipData = async (account, chainId = 10) => {
    const response = (await request(getPerpsV2SubgraphUrl(chainId), gql `
			query AccumulatedVolumeFee($account: String!) {
				accumulatedVolumeFee(id: $account) {
					totalFeeRebate
					lastClaimedAtBlockNumber
					allTimeRebates
					lastFeeAccumulatedStartBlockNumber
				}
			}
		`, { account }));
    return response.accumulatedVolumeFee;
};
export const queryFeeReimbursed = async (account, chainId = 10) => {
    const response = (await request(getPerpsV2SubgraphUrl(chainId), gql `
			query FeeReimbursed($account: String!) {
				feeReimburseds(where: { account: $account }, orderBy: "timestamp", orderDirection: "asc") {
					feeRebate
					timestamp
					blockNumber
					txHash
					rebateTokenPrice
				}
			}
		`, { account }));
    return response.feeReimburseds ?? [];
};

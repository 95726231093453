import {
	type Activity,
	type LevelThreshold,
	type Points,
	type PointsLeaderboard,
	PointsLevel,
	PointsRank,
	type Season,
} from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import { selectLatestFetchTimestamp } from './selectors'

const ZERO_POINTS: Points = {
	total: 0,
	placement: 0,
	user: {
		address: '',
		level: PointsLevel.NPC_I,
		rank: PointsRank.NPC,
	},
}

export const fetchPoints = createAsyncThunk<Points, void, ThunkConfig>(
	'points/fetchPoints',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		if (!wallet) {
			return ZERO_POINTS
		}
		try {
			return await sdk.points.getPoints(wallet)
		} catch (error) {
			logError(error)
			throw error
		}
	}
)

export const fetchLeaderBoard = createAsyncThunk<
	{ total: number; points: PointsLeaderboard[] },
	void,
	ThunkConfig
>('points/fetchLeaderBoard', async (_, { extra: { sdk } }) => {
	try {
		return await sdk.points.getLeaderboard()
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchGlobalActivities = createAsyncThunk<Activity[], void, ThunkConfig>(
	'points/fetchGlobalActivities',
	async (_, { extra: { sdk } }) => {
		try {
			return await sdk.points.getGlobalActivities()
		} catch (error) {
			logError(error)
			throw error
		}
	}
)

export const fetchUserActivities = createAsyncThunk<Activity[], void, ThunkConfig>(
	'points/fetchUserActivities',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) {
				return []
			}
			return await sdk.points.getUserActivities(wallet)
		} catch (error) {
			logError(error)
			throw error
		}
	}
)

export const fetchLatestUserActivities = createAsyncThunk<
	{ activities: Activity[]; latestTimestamp: number },
	void,
	ThunkConfig
>('points/fetchLatestUserActivities', async (_, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState())
		if (!wallet) {
			return { activities: [], latestTimestamp: Date.now() }
		}
		const latestFetchTimestamp = selectLatestFetchTimestamp(getState())
		const activities = await sdk.points.getUserActivities(wallet, 5)
		return {
			activities: activities.filter(
				(activity) => new Date(activity.timestamp).getTime() > latestFetchTimestamp
			),
			latestTimestamp: Math.max(
				activities.length > 0 ? new Date(activities[0].timestamp).getTime() : Date.now(),
				latestFetchTimestamp
			),
		}
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchSeasons = createAsyncThunk<Season[], void, ThunkConfig>(
	'points/fetchSeasons',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())

			return await sdk.points.getSeasons(wallet)
		} catch (error) {
			logError(error)
			throw error
		}
	}
)

export const fetchLevels = createAsyncThunk<LevelThreshold[], void, ThunkConfig>(
	'points/fetchLevels',
	async (_, { extra: { sdk } }) => {
		try {
			return await sdk.points.getPointsLevel()
		} catch (error) {
			logError(error)
			throw error
		}
	}
)

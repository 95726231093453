import { type PointsLevel, PointsRank } from '@kwenta/sdk/types'
import { calculatePointsRankFromLevel } from '@kwenta/sdk/utils'
import Image, { type StaticImageData } from 'next/image'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'

import expertImg from 'assets/png/points/expert.png'
import godsImg from 'assets/png/points/gods.png'
import grandmasterImg from 'assets/png/points/grandmaster.png'
import journeymanImg from 'assets/png/points/journeyman.png'
import masterImg from 'assets/png/points/master.png'
import noviceImg from 'assets/png/points/novice.png'
import npcImg from 'assets/png/points/npc.png'

type Props = {
	level: PointsLevel
	className?: string
	width?: number
	height?: number
	style?: any
	onClick?: () => void
}

const rankIcons: Record<PointsRank, StaticImageData> = {
	[PointsRank.NPC]: npcImg,
	[PointsRank.NOVICE]: noviceImg,
	[PointsRank.JOURNEYMAN]: journeymanImg,
	[PointsRank.EXPERT]: expertImg,
	[PointsRank.MASTER]: masterImg,
	[PointsRank.GRANDMASTER]: grandmasterImg,
	[PointsRank.GOD]: godsImg,
}

const mapRankToIcon = (rank: PointsRank): StaticImageData => {
	return rankIcons[rank] || npcImg
}

const PointsRankAvatar: FC<Props> = memo(({ level, className, onClick, ...rest }) => {
	const props = { width: 50, height: 50, alt: level, ...rest }

	const icon = useMemo(() => mapRankToIcon(calculatePointsRankFromLevel(level)), [level])
	return (
		<Container className={className} onClick={onClick}>
			<Image src={icon} {...props} />
		</Container>
	)
})

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	.synth-icon {
		filter: invert(${(props) => props.theme.colors.selectedTheme.imageInvert.value});
	}
	& img {
		border-radius: 100%;
		border: ${(props) => props.theme.colors.selectedTheme.newTheme.border.avata};
	}
`

export default PointsRankAvatar

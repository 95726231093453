import Link from 'next/link'
import { type FC, memo } from 'react'
import styled from 'styled-components'

import SvgLogo from 'assets/svg/brand/logo-v3.svg'
import ROUTES from 'constants/routes'

const Logo: FC = memo(() => {
	return (
		<Link href={ROUTES.Home.Root}>
			<LogoContainer>
				<SvgLogo />
			</LogoContainer>
		</Link>
	)
})

const LogoContainer = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 18px;
	width: 122px;
	margin-right: 20px;
`

export default Logo

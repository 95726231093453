import {
	type BalancesAndAllowances,
	type NetworkId,
	PerpsProvider,
	type TokenTickers,
} from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { formatEther } from 'ethers/lib/utils'
import type { Address } from 'viem/accounts'

import { setPerpsV3Spenders } from 'state/futures/reducer'
import { selectAccountContext } from 'state/futures/selectors'
import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { providerIsCrossMargin } from 'utils/futures'
import logError from 'utils/logError'

export const fetchBalancesAndAllowances = createAsyncThunk<
	| {
			wallet: Address
			chainId: NetworkId
			balancesAndAllowances: BalancesAndAllowances
	  }
	| undefined,
	void,
	ThunkConfig
>('balances/fetchBalancesAndAllowances', async (_, { getState, dispatch, extra: { sdk } }) => {
	const wallet = selectWallet(getState())
	const { network, provider } = selectAccountContext(getState())

	let spenders: Address[] = []

	try {
		if (providerIsCrossMargin(provider)) {
			const marketProxy = sdk.context.contractConfigs[network]?.snxV3.PerpsV3MarketProxy?.address
			const marginEngine = sdk.context.contractConfigs[network]?.snxV3.MarginEngine?.address
			const spotProxy = sdk.context.contractConfigs[network]?.snxV3.SpotV3MarketProxy?.address
			if (marketProxy && spotProxy) {
				spenders = [marketProxy, spotProxy]
				if (marginEngine) spenders.push(marginEngine)
				dispatch(
					setPerpsV3Spenders({
						marginEngine: marginEngine,
						marketProxy: marketProxy,
						spotProxy: spotProxy,
					})
				)
			}
		}

		if (!wallet) return
		let tokens: TokenTickers[] = []
		switch (provider) {
			case PerpsProvider.SNX_V3_BASE:
				tokens = ['USDC', 'sUSDC', 'USDx']
				break
			case PerpsProvider.SNX_V3_ARB:
				tokens = ['WETH', 'USDx', 'USDC', 'sETH', 'tBTC', 'sBTC']
				break
			default:
				tokens = ['USDC', 'SUSD']
		}

		const res = await sdk.tokens.getBalancesAndAllowances(
			tokens,
			wallet,
			(spenders ?? []) as Address[],
			network
		)

		const ethBal = await sdk.tokens.getEthBalance(wallet, network)

		res.ETH = { balance: formatEther(ethBal), allowances: {} }

		return { wallet, chainId: network, balancesAndAllowances: res }
	} catch (err) {
		logError(err)
		throw err
	}
})

import { type FC, memo } from 'react'
import styled from 'styled-components'

type Props = {
	ratio: number
	height?: number
}

const ProgressBar: FC<Props> = memo(({ ratio, height = 8 }) => {
	return (
		<Container>
			<LineCotainer $height={`${height}px`}>
				<FilledLine $ratio={`${ratio * 100}%`} />
			</LineCotainer>
		</Container>
	)
})

const Container = styled.div`
	position: relative;
	width: 100%;
	margin-top: 5px;
	border-radius: 100px;
`

const LineCotainer = styled.div<{ $height: string }>`
	width: 100%;
	height: ${(props) => props.$height};
	border-radius: 100px;
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.button.default.background};
	transform: translateY(-50%);
`
const FilledLine = styled.div<{ $ratio: string }>`
	width: ${(props) => props.$ratio};
	height: 100%;
	background-color: ${(props) => props.theme.colors.selectedTheme.newTheme.checkBox.default.checked};
	border-radius: 100px;
	transition: width 0.5s ease-in-out; /* Add transition for smooth animation */
`
export default ProgressBar

const abi = [
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'depositTo',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
];
export default abi;

import { OrderTypeEnum } from '@kwenta/sdk/types'

import { FetchStatus } from './types'

export const ZERO_STATE_TRADE_INPUTS = {
	nativeSize: '',
	susdSize: '',
	orderType: OrderTypeEnum.MARKET,
	orderPrice: {
		price: undefined,
		invalidLabel: undefined,
	},
}

export const ZERO_STATE_ISOLATED_ACCOUNT = {
	position: undefined,
	trades: [],
	marketMarginTransfers: [],
	positions: [],
	delayedOrders: [],
	positionHistory: [],
}

export const ZERO_STATE_ACCOUNT = {
	position: undefined,
	balanceInfo: {
		freeMargin: '0',
		allowance: '0',
		keeperEthBal: '0',
		walletEthBal: '0',
		idleMarginByMarket: {},
		totalMarginByMarket: {},
		balances: { SUSD: '0', USDC: '0', DAI: '0' },
		allowances: { SUSD: '0', USDC: '0', DAI: '0' },
	},
	trades: [],
	tradesByPosition: {},
	liquidations: [],
	positions: [],
	accountTransfers: [],
	delayedOrders: [],
	conditionalOrders: [],
	positionHistory: [],
	delegates: [],
	orderHistory: [],
	pnlSnapshots: [],
}

export const ZERO_STATE_MARGIN_INFO = {
	availableMargin: '0',
	withdrawableMargin: '0',
	requiredMaintenanceMargin: '0',
	requiredInitialMargin: '0',
	maxLiquidationReward: '0',
	debt: '0',
}

export const ZERO_MARGIN_SNAPSHOTS = {
	ONE_YEAR: [],
	ONE_MONTH: [],
	ONE_WEEK: [],
}

export const ZERO_STATE_CM_ACCOUNT = {
	...ZERO_STATE_ACCOUNT,
	balances: {},
	pendingAsyncOrder: undefined,
	collateralBalances: {},
	usdcBalance: '0',
	marginSnapshots: ZERO_MARGIN_SNAPSHOTS,
	marginInfo: {
		...ZERO_STATE_MARGIN_INFO,
	},
}

export const DEFAULT_QUERY_STATUS = {
	status: FetchStatus.Idle,
	error: null,
}

export const LOADING_STATUS = {
	status: FetchStatus.Loading,
	error: null,
}

export const SUCCESS_STATUS = {
	status: FetchStatus.Success,
	error: null,
}

export const KEEPER_ETH_GAS_FEE = 0.0003

export const KEEPER_USD_GAS_FEE = 0

export const DEFAULT_SWAP_SLIPPAGE = 0.5

export const VIP_ENABLED = true

export const VIP_TIERS = {
	0: {
		volume: 0,
		feeRebate: 0,
	},
	1: {
		volume: 1000000,
		feeRebate: 0.1,
	},
	2: {
		volume: 10000000,
		feeRebate: 0.4,
	},
	3: {
		volume: 100000000,
		feeRebate: 0.7,
	},
	4: {
		volume: 1000000000,
		feeRebate: 1.1,
	},
} as const

export const GHOST_KEY = process.env.NEXT_PUBLIC_GHOST_KEY

import { encodeFunctionData, hexToString, trim, } from 'viem';
import { PYTH_ORACLE_ADDRESSES, PYTH_SERVER_PRIMARY, SNX_V3_PERPS_ADDRESSES } from '../../constants';
import IERC7412 from '../../contracts/abis/IERC7412.js';
import MarginEngineAbi from '../../contracts/abis/MarginEngine.json';
import PerpsV3MarketProxy from '../../contracts/abis/PerpsV3MarketProxy.js';
import SpotV3MarketProxy from '../../contracts/abis/SpotV3MarketProxy.js';
import { decodeTransactionError } from '../../utils';
import { PythAdapter, encodePriceUpdateData } from './PythAdapter';
const ADAPTER = new PythAdapter(PYTH_SERVER_PRIMARY);
const STALENESS_TOLERANCE_SECONDS = 0;
const CACHE_TIME = 5000;
export class EIP7412 {
    constructor() {
        this.cachedPriceUpdates = new Map();
    }
    async preparePredefinedUpdates(predefinedOracles, chainId, useMarginEngine) {
        let updateData;
        const cached = this.cachedPriceUpdates.get(predefinedOracles.join(','));
        if (cached && Date.now() - cached.timestamp < CACHE_TIME) {
            // Cache price updates for 5 seconds
            updateData = cached.data;
        }
        else {
            const updateDataResponse = await ADAPTER.connection.getLatestPriceUpdates(predefinedOracles);
            updateData = updateDataResponse.binary.data.map((x) => `0x${x}`);
            this.cachedPriceUpdates.set(predefinedOracles.join(','), {
                data: updateData,
                ids: predefinedOracles,
                timestamp: Date.now(),
            });
        }
        const priceUpdates = encodePriceUpdateData(updateData, predefinedOracles, BigInt(STALENESS_TOLERANCE_SECONDS), 1);
        const priceUpdateCall = preparePriceUpdateCall({
            chainId,
            data: priceUpdates,
            feedsUpdated: predefinedOracles.length,
            useMarginEngine,
        });
        return priceUpdateCall;
    }
    async enableERC7412(client, transactions, multicallFunc, { predefinedOracles, chainId, useMarginEngine, isWrite, }) {
        let multicallCalls = [...transactions];
        let multicallTxn;
        let requestedIds = [];
        // First prepend any price updates of oracles we need
        // latest price for if it's a readonly request
        if (!isWrite && predefinedOracles && predefinedOracles.length > 0) {
            const priceUpdateCall = await this.preparePredefinedUpdates(predefinedOracles, chainId, useMarginEngine);
            multicallCalls = [priceUpdateCall, ...multicallCalls];
        }
        const initialCallCount = multicallCalls.length;
        while (true) {
            try {
                multicallTxn = multicallFunc([...multicallCalls], chainId);
                await client.call(multicallTxn);
                return multicallTxn;
            }
            catch (error) {
                const err = decodeTransactionError(error, [
                    IERC7412,
                    PerpsV3MarketProxy,
                    SpotV3MarketProxy,
                    MarginEngineAbi,
                ]);
                if (!err)
                    throw error;
                if (err.errorName === 'OracleDataRequired') {
                    const oracleQuery = err.args?.[1];
                    const oracleAddress = err.args?.[0];
                    const oracleId = hexToString(trim((await client.readContract({
                        abi: IERC7412,
                        address: oracleAddress,
                        functionName: 'oracleId',
                    })), { dir: 'right' }));
                    const adapterOracle = ADAPTER.getOracleId();
                    if (adapterOracle !== oracleId) {
                        throw new Error(`oracle ${oracleId} not supported (supported oraclead ${adapterOracle}`);
                    }
                    // For write calls we wait for one price request and then request all markets.
                    // This is due to snx sometimes requiring a large number of prices to calculate
                    // OI across markets, which when left for EIP7412 to resolve can cause the app to stall.
                    const extraPriceIds = isWrite && predefinedOracles ? predefinedOracles : [];
                    const { data: signedRequiredData, ids } = await ADAPTER.fetchOffchainData(oracleQuery, [
                        ...new Set([...requestedIds, ...extraPriceIds]),
                    ]);
                    requestedIds = [...new Set([...requestedIds, ...ids])];
                    const tx = preparePriceUpdateCall({
                        chainId,
                        data: signedRequiredData,
                        feedsUpdated: ids.length,
                        address: oracleAddress,
                        useMarginEngine,
                    });
                    multicallCalls.length > initialCallCount
                        ? multicallCalls.splice(0, 1, tx)
                        : multicallCalls.splice(0, 0, tx);
                    // @ts-ignore
                }
                else if (err.errorName === 'FeeRequired') {
                    const requiredFee = err.args?.[0];
                    // @ts-ignore
                    multicallCalls[multicallCalls.length - 2].value = requiredFee;
                }
                else {
                    throw new Error(
                    // @ts-ignore
                    `Failed to simulate tx: ${err.errorName} - ${err.args
                        ?.map((x) => x.toString())
                        .join(',')}`);
                }
            }
        }
    }
}
const preparePriceUpdateCall = ({ chainId, data, feedsUpdated, address, useMarginEngine, }) => {
    const oracleAddress = address || PYTH_ORACLE_ADDRESSES[chainId];
    if (!oracleAddress)
        throw new Error('Oracle address not found');
    return useMarginEngine
        ? {
            to: SNX_V3_PERPS_ADDRESSES.MarginEngine[chainId],
            value: BigInt(feedsUpdated),
            data: encodeFunctionData({
                abi: MarginEngineAbi,
                functionName: 'fulfillOracleQuery',
                args: [oracleAddress, data],
            }),
        }
        : {
            to: oracleAddress,
            value: BigInt(feedsUpdated),
            data: encodeFunctionData({
                abi: IERC7412,
                functionName: 'fulfillOracleQuery',
                args: [data],
            }),
        };
};

const abi = [
    {
        inputs: [
            { internalType: 'address', name: '_feeReimbursementApp', type: 'address' },
            { internalType: 'address', name: '_factory', type: 'address' },
            { internalType: 'address', name: '_rewardToken', type: 'address' },
            { internalType: 'address', name: '_opPriceFeed', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    { inputs: [], name: 'Blacklisted', type: 'error' },
    { inputs: [], name: 'GracePeriodNotOver', type: 'error' },
    {
        inputs: [
            { internalType: 'uint256', name: 'available', type: 'uint256' },
            { internalType: 'uint256', name: 'required', type: 'uint256' },
        ],
        name: 'InsufficientContractBalance',
        type: 'error',
    },
    { inputs: [], name: 'NoFeeRebateAvailable', type: 'error' },
    { inputs: [], name: 'NotAccountOwner', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'OwnableInvalidOwner',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'OwnableUnauthorizedAccount',
        type: 'error',
    },
    { inputs: [], name: 'SequencerDown', type: 'error' },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bool', name: 'isBlacklisted', type: 'bool' },
        ],
        name: 'BlacklistUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: true, internalType: 'address', name: 'account', type: 'address' }],
        name: 'FeeRebateClaimed',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
            { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'token', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'Recovered',
        type: 'event',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'blacklist',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_smartMarginAccount', type: 'address' }],
        name: 'claim',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'contract IFactory', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'feeReimbursementApp',
        outputs: [{ internalType: 'contract IFeeReimbursementApp', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_tokenAddress', type: 'address' },
            { internalType: 'uint256', name: '_tokenAmount', type: 'uint256' },
        ],
        name: 'recoverERC20',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardToken',
        outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bool', name: 'isBlacklisted', type: 'bool' },
        ],
        name: 'updateBlacklist',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export default abi;

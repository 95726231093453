import { gql, request } from 'graphql-request';
import { fromHex, toHex } from 'viem';
export const queryReferrerByCode = async (sdk, code, chainId) => {
    const response = await request(sdk.referrals.referralsGqlEndpoint(chainId), gql `
			query boostReferrers($code: String!) {
				boostReferrers(where: { id: $code }) {
					account
				}
			}
		`, { code: toHex(code, { size: 32 }) });
    return response?.boostReferrers[0]?.account || null;
};
export const queryBoostNftTierByHolder = async (sdk, walletAddress, chainId) => {
    if (!walletAddress)
        return -1;
    const response = await request(sdk.referrals.referralsGqlEndpoint(chainId), gql `
			query boostHolders($walletAddress: String!) {
				boostHolders(where: { id: $walletAddress }) {
					tier
				}
			}
		`, { walletAddress });
    return response?.boostHolders[0]?.tier || -1;
};
export const queryCodesByReferrer = async (sdk, walletAddress, chainId) => {
    if (!walletAddress)
        return [];
    const response = await request(sdk.referrals.referralsGqlEndpoint(chainId), gql `
			query boostReferrers($walletAddress: String!) {
				boostReferrers(where: { account: $walletAddress }) {
					id
				}
			}
		`, { walletAddress });
    return response?.boostReferrers.map(({ id }) => fromHex(id, { size: 32, to: 'string' })) || [];
};
export const queryTradersByCode = async (sdk, code, chainId, epochStart, epochEnd) => {
    let queryResponseCount = 0;
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const end = epochEnd ? Math.min(currentTimeInSeconds, epochEnd) : currentTimeInSeconds;
    const boostHolders = [];
    const minTimestamp = epochStart || 0;
    let maxTimestamp = end;
    do {
        const response = await request(sdk.referrals.referralsGqlEndpoint(chainId), gql `
				query boostHolders($code: String!, $minTimestamp: BigInt!, $maxTimestamp: BigInt!) {
					boostHolders(
						where: { lastMintedAt_lte: $maxTimestamp, lastMintedAt_gt: $minTimestamp, code: $code }
						orderBy: lastMintedAt
						orderDirection: desc
						first: 1000
					) {
						id
						lastMintedAt
					}
				}
			`, { code: toHex(code, { size: 32 }), maxTimestamp, minTimestamp });
        queryResponseCount = response.boostHolders.length;
        if (queryResponseCount > 0) {
            maxTimestamp = Number(response.boostHolders[queryResponseCount - 1].lastMintedAt);
            boostHolders.push(...response.boostHolders);
        }
    } while (queryResponseCount === 1000);
    return boostHolders;
};

import { ZERO_WEI } from '@kwenta/sdk/constants'
import { TransactionStatus } from '@kwenta/sdk/types'
import { toWei } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { STAKING_DISABLED } from 'constants/ui'
import { getApy, parseEpochData } from 'queries/staking/utils'
import { selectProviderNetwork, selectSnxPerpsV2Network } from 'state/futures/common/selectors'
import {
	selectInMigrationPeriod,
	selectIsMigrationPeriodStarted,
	selectNeedEscrowMigratorApproval,
	selectNumberOfUnmigratedRegisteredEntries,
	selectNumberOfUnregisteredEntries,
	selectNumberOfUnvestedRegisteredEntries,
	selectStartMigration,
} from 'state/stakingMigration/selectors'
import type { RootState } from 'state/store'

export const selectSubmittingStakingTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectClaimableBalanceV1 = createSelector(
	(state: RootState) => state.staking.v1.claimableBalance,
	toWei
)

const selectClaimableBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.claimableBalance,
	toWei
)

export const selectStakedKwentaBalanceV1 = createSelector(
	(state: RootState) => state.staking.v1.stakedKwentaBalance,
	toWei
)

const selectStakedKwentaBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.stakedKwentaBalance,
	toWei
)

const selectEscrowedKwentaBalanceV1 = createSelector(
	(state: RootState) => state.staking.v1.escrowedKwentaBalance,
	toWei
)

const selectEscrowedKwentaBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.escrowedKwentaBalance,
	toWei
)

const selectStakedEscrowedKwentaBalanceV1 = createSelector(
	(state: RootState) => state.staking.v1.stakedEscrowedKwentaBalance,
	toWei
)

const selectStakedEscrowedKwentaBalanceV2 = createSelector(
	(state: RootState) => state.staking.v2.stakedEscrowedKwentaBalance,
	toWei
)

export const selectTotalVestableV1 = createSelector(
	(state: RootState) => state.staking.v1.totalVestable,
	wei
)

const selectTotalVestableV2 = createSelector(
	(state: RootState) => state.staking.v2.totalVestable,
	wei
)

export const selectStakingMigrationRequired = createSelector(
	selectClaimableBalanceV1,
	selectStakedKwentaBalanceV1,
	selectTotalVestableV1,
	(claimableBalanceV1, stakedKwentaBalanceV1, totalVestableV1) =>
		claimableBalanceV1.gt(ZERO_WEI) ||
		stakedKwentaBalanceV1.gt(ZERO_WEI) ||
		totalVestableV1.gt(ZERO_WEI)
)

export const selectStakingV1 = createSelector(
	selectStakingMigrationRequired,
	selectStartMigration,
	(stakingMigrationRequired, startMigration) => stakingMigrationRequired || startMigration
)

export const selectKwentaBalance = createSelector(
	(state: RootState) => state.staking.kwentaBalance,
	toWei
)

export const selectClaimableBalance = createSelector(
	selectClaimableBalanceV1,
	selectClaimableBalanceV2,
	selectStakingV1,
	(claimableBalanceV1, claimableBalanceV2, stakingV1) =>
		stakingV1 ? claimableBalanceV1 : claimableBalanceV2
)

export const selectFeeShareBalance = createSelector(
	(state: RootState) => state.staking.v2.feeShareBalance,
	toWei
)

export const selectStakedKwentaBalance = createSelector(
	selectStakedKwentaBalanceV1,
	selectStakedKwentaBalanceV2,
	selectStakingV1,
	(stakedKwentaBalanceV1, stakedKwentaBalanceV2, stakingV1) =>
		stakingV1 ? stakedKwentaBalanceV1 : stakedKwentaBalanceV2
)

export const selectEscrowedKwentaBalance = createSelector(
	selectEscrowedKwentaBalanceV1,
	selectEscrowedKwentaBalanceV2,
	selectStakingV1,
	(escrowedKwentaBalanceV1, escrowedKwentaBalanceV2, stakingV1) =>
		stakingV1 ? escrowedKwentaBalanceV1 : escrowedKwentaBalanceV2
)

export const selectStakedEscrowedKwentaBalance = createSelector(
	selectStakedEscrowedKwentaBalanceV1,
	selectStakedEscrowedKwentaBalanceV2,
	selectStakingV1,
	(stakedEscrowedKwentaBalanceV1, stakedEscrowedKwentaBalanceV2, stakingV1) =>
		stakingV1 ? stakedEscrowedKwentaBalanceV1 : stakedEscrowedKwentaBalanceV2
)

export const selectTotalVestable = createSelector(
	selectTotalVestableV1,
	selectTotalVestableV2,
	selectStakingV1,
	(totalVestableV1, totalVestableV2, stakingV1) => (stakingV1 ? totalVestableV1 : totalVestableV2)
)

export const selectUnstakedEscrowedKwentaBalance = createSelector(
	selectEscrowedKwentaBalance,
	selectStakedEscrowedKwentaBalance,
	(escrowedKwentaBalance, stakedEscrowedKwentaBalance) => {
		return escrowedKwentaBalance.sub(stakedEscrowedKwentaBalance)
	}
)

const selectIsKwentaTokenApprovedV1 = createSelector(
	selectKwentaBalance,
	(state: RootState) => state.staking.kwentaAllowance,
	(kwentaBalance, kwentaAllowance) => kwentaBalance.lte(kwentaAllowance)
)
const selectIsKwentaTokenApprovedV2 = createSelector(
	selectKwentaBalance,
	(state: RootState) => state.staking.kwentaStakingV2Allowance,
	(kwentaBalance, kwentaAllowance) => kwentaBalance.lte(kwentaAllowance)
)

export const selectStakedResetTime = (state: RootState) => state.staking.stakedResetTime

export const selectEpochData = createSelector(
	selectProviderNetwork,
	(state: RootState) => state.staking.epochPeriod,
	(networkId, epochPeriod) => {
		return Array.from(new Array(epochPeriod + 1), (_, i) => parseEpochData(i, networkId))
	}
)

export const selectIsKwentaTokenApproved = createSelector(
	selectIsKwentaTokenApprovedV1,
	selectIsKwentaTokenApprovedV2,
	selectStakingV1,
	(isKwentaTokenApprovedV1, isKwentaTokenApprovedV2, stakingV1) =>
		stakingV1 ? isKwentaTokenApprovedV1 : isKwentaTokenApprovedV2
)

export const selectSubmittingStakinglTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectIsStakingKwentaV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake'
)

export const selectIsUnstakingKwentaV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake'
)

export const selectIsStakedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'stake'
		)
	}
)

export const selectIsUnstakedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'unstake'
		)
	}
)

export const selectIsStakingEscrowedKwentaV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake_escrowed'
)

export const selectIsUnstakingEscrowedKwentaV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake_escrowed'
)

export const selectIsStakedEscrowedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'stake_escrowed'
		)
	}
)

export const selectIsUnstakedEscrowedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'unstake_escrowed'
		)
	}
)

export const selectIsApprovingKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'approve_kwenta'
)

export const selectIsGettingRewardV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'get_reward'
)

export const selectIsVestingEscrowedRewardsV1 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'vest_escrowed_rewards'
)

export const selectKwentaRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.claimableRewards.kwenta,
	wei
)

export const selectArbRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.claimableRewards.arb,
	wei
)

export const selectOpReferralRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.claimableRewards.opReferral,
	wei
)

export const selectSnxRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.claimableRewards.snx,
	wei
)

export const selectEstimatedKwentaRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.estimatedRewards.kwenta,
	wei
)

export const selectEstimatedArbRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.estimatedRewards.arb,
	wei
)

export const selectEstimatedOpRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.estimatedRewards.opReferral,
	wei
)

export const selectFeesPaid = createSelector(
	(state: RootState) => state.staking,
	(staking) => staking.tradingRewards.feesPaid
)

export const selectReferralFeesPaid = createSelector(
	(state: RootState) => state.staking,
	(staking) => staking.tradingRewards.referralFeesPaid
)

export const selectTotalKwentaRewards = createSelector(
	selectClaimableBalance,
	selectKwentaRewards,
	(stakingRewards, tradingRewards) => stakingRewards.add(tradingRewards)
)

export const selectSelectedEpoch = createSelector(
	selectSnxPerpsV2Network,
	(state: RootState) => state.staking.selectedEpoch,
	(state: RootState) => state.staking.epochPeriod,
	(providerNetwork, selectedEpoch, epochPeriod) =>
		parseEpochData(selectedEpoch || epochPeriod, providerNetwork)
)

export const selectIsTimeLeftInCooldown = createSelector(
	selectStakedResetTime,
	(stakedResetTime) => stakedResetTime > Date.now() / 1000
)

export const selectCanStakeKwenta = createSelector(
	selectKwentaBalance,
	selectIsStakingKwentaV1,
	(kwentaBalance, isStakingKwenta) => kwentaBalance.gt(0) && !isStakingKwenta && !STAKING_DISABLED
)

const selectCanUnstakeKwentaV1 = createSelector(
	selectStakedKwentaBalanceV1,
	selectIsUnstakingKwentaV1,
	(stakedKwentaBalance, isUnstakingKwenta) =>
		stakedKwentaBalance.gt(0) && !isUnstakingKwenta && !STAKING_DISABLED
)

const selectCanUnstakeKwentaV2 = createSelector(
	selectStakedKwentaBalanceV2,
	selectIsUnstakingKwentaV1,
	selectIsTimeLeftInCooldown,
	(stakedKwentaBalance, isUnstakingKwenta, isTimeLeftInCooldown) =>
		stakedKwentaBalance.gt(0) && !isUnstakingKwenta && !isTimeLeftInCooldown && !STAKING_DISABLED
)

export const selectCanUnstakeKwenta = createSelector(
	selectCanUnstakeKwentaV1,
	selectCanUnstakeKwentaV2,
	selectStakingV1,
	(canUnstakeKwentaV1, canUnstakeKwentaV2, stakingV1) =>
		stakingV1 ? canUnstakeKwentaV1 : canUnstakeKwentaV2
)

const selectCanUnstakeEscrowedKwentaV1 = createSelector(
	selectStakedEscrowedKwentaBalance,
	selectIsUnstakingEscrowedKwentaV1,
	(stakedEscrowedKwentaBalance, isUnstakingEscrowedKwenta) => {
		return stakedEscrowedKwentaBalance.gt(0) && !isUnstakingEscrowedKwenta && !STAKING_DISABLED
	}
)

const selectCanUnstakeEscrowedKwentaV2 = createSelector(
	selectStakedEscrowedKwentaBalanceV2,
	selectIsUnstakingEscrowedKwentaV1,
	selectIsTimeLeftInCooldown,
	(stakedEscrowedKwentaBalance, isUnstakingEscrowedKwenta, isTimeLeftInCooldown) => {
		return (
			stakedEscrowedKwentaBalance.gt(0) &&
			!isUnstakingEscrowedKwenta &&
			!isTimeLeftInCooldown &&
			!STAKING_DISABLED
		)
	}
)

export const selectCanUnstakeEscrowedKwenta = createSelector(
	selectCanUnstakeEscrowedKwentaV1,
	selectCanUnstakeEscrowedKwentaV2,
	selectStakingV1,
	(canUnstakeEscrowedKwentaV1, canUnstakeEscrowedKwentaV2, stakingV1) =>
		stakingV1 ? canUnstakeEscrowedKwentaV1 : canUnstakeEscrowedKwentaV2
)

export const selectCanStakeEscrowedKwenta = createSelector(
	selectUnstakedEscrowedKwentaBalance,
	selectIsStakingEscrowedKwentaV1,
	(unstakedEscrowedKwentaBalance, isStakingEscrowedKwenta) => {
		return unstakedEscrowedKwentaBalance.gt(0) && !isStakingEscrowedKwenta && !STAKING_DISABLED
	}
)

export const selectEpochPeriod = (state: RootState) => state.staking.epochPeriod

const selectApyV1 = createSelector(
	(state: RootState) => state.staking.v1.totalStakedBalance,
	(state: RootState) => state.staking.weekCounter,
	(_totalStakedBalance, _weekCounter) => {
		// always return 0 because v1 is no longer receiving rewards
		return ZERO_WEI
	}
)

const selectApyV2 = createSelector(
	(state: RootState) => state.staking.v2.totalStakedBalance,
	(state: RootState) => state.staking.weekCounter,
	(totalStakedBalance, weekCounter) => {
		return getApy(Number(totalStakedBalance), weekCounter)
	}
)

export const selectApy = createSelector(
	selectApyV1,
	selectApyV2,
	selectStakingV1,
	(apyV1, apyV2, stakingV1) => (stakingV1 ? apyV1 : apyV2)
)

const selectEscrowEntriesV1 = (state: RootState) => state.staking.v1.escrowData ?? []

const selectEscrowEntriesV2 = (state: RootState) => state.staking.v2.escrowData ?? []

export const selectEscrowEntries = createSelector(
	selectEscrowEntriesV1,
	selectEscrowEntriesV2,
	selectStakingV1,
	(escrowEntriesV1, escrowEntriesV2, stakingV1) => (stakingV1 ? escrowEntriesV1 : escrowEntriesV2)
)

export const selectIsClaimingKwentaRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_kwenta_rewards'
)

export const selectIsClaimingArbRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_arb_rewards'
)

export const selectIsClaimingOpRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_op_referral_rewards'
)

export const selectCanVestBeforeMigration = createSelector(
	selectStakingV1,
	selectStartMigration,
	(stakingV1, startMigration) => stakingV1 && !startMigration
)

export const selectStakingSupportedNetwork = createSelector(
	selectProviderNetwork,
	(network) => network === 10
)

export const selectStepClaimActive = createSelector(selectClaimableBalanceV1, (claimableBalance) =>
	claimableBalance.gt(0)
)

export const selectStepClaimTradingActive = createSelector(
	selectStepClaimActive,
	selectKwentaRewards,
	(stepClaimActive, kwentaRewards) => !stepClaimActive && kwentaRewards.gt(0)
)

export const selectStepClaimFlowActive = createSelector(
	selectStepClaimActive,
	selectStepClaimTradingActive,
	(stepClaim, stepClaimTrading) => stepClaim || stepClaimTrading
)

export const selectStepRegisterActive = createSelector(
	selectNumberOfUnregisteredEntries,
	(numberOfUnregisteredEntries) => numberOfUnregisteredEntries > 0
)

export const selectStepVestActive = createSelector(
	selectStepRegisterActive,
	selectNumberOfUnvestedRegisteredEntries,
	(stepRegisterActive, numberOfUnvestedEntries) =>
		!stepRegisterActive && numberOfUnvestedEntries > 0
)

export const selectStepApproveActive = createSelector(
	selectStepRegisterActive,
	selectStepVestActive,
	selectNeedEscrowMigratorApproval,
	(stepRegisterActive, stepVestActive, needApproval) =>
		!stepRegisterActive && !stepVestActive && needApproval
)

export const selectStepMigrateActive = createSelector(
	selectStepRegisterActive,
	selectStepVestActive,
	selectStepApproveActive,
	selectNumberOfUnmigratedRegisteredEntries,
	(stepRegisterActive, stepVestActive, stepApproveActive, numberOfUnmigratedEntries) =>
		!stepRegisterActive && !stepVestActive && !stepApproveActive && numberOfUnmigratedEntries > 0
)

export const selectStepMigrateFlowActive = createSelector(
	selectStepClaimFlowActive,
	selectStepRegisterActive,
	selectStepVestActive,
	selectStepApproveActive,
	selectStepMigrateActive,
	selectIsMigrationPeriodStarted,
	selectInMigrationPeriod,
	(
		stepClaimFlowActive,
		stepRegisterActive,
		stepVestActive,
		stepApproveActive,
		selectStepMigrateActive,
		isMigrationPeriodStarted,
		inMigrationPeriod
	) =>
		!stepClaimFlowActive &&
		(stepRegisterActive || stepVestActive || stepApproveActive || selectStepMigrateActive) &&
		(!isMigrationPeriodStarted || inMigrationPeriod)
)

export const selectStepUnstakeActive = createSelector(
	selectStepClaimFlowActive,
	selectStepMigrateFlowActive,
	selectStakedKwentaBalanceV1,
	(stepClaimFlowActive, stepMigrateFlowActive, stakedKwentaBalance) =>
		!stepClaimFlowActive && !stepMigrateFlowActive && stakedKwentaBalance.gt(0)
)

export const selectApprovedOperators = createSelector(
	(state: RootState) => state.staking.approvedOperators,
	(approvedOperators) => approvedOperators.map((operator) => ({ address: operator }))
)

export const selectIsApprovingOperator = createSelector(
	selectSubmittingStakingTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'approve_operator'
)

export const selectIsTransferring = createSelector(
	selectSubmittingStakingTx,
	(state: RootState) => state.app,
	(submitting, app) =>
		submitting &&
		(app.transaction?.type === 'transfer_escrow_entries' ||
			app.transaction?.type === 'transfer_escrow_entry')
)

export const selectVKwentaBalance = createSelector(
	(state: RootState) => state.staking.vKwentaBalance,
	toWei
)

export const selectVeKwentaBalance = createSelector(
	(state: RootState) => state.staking.veKwentaBalance,
	toWei
)

export const selectIsVKwentaTokenApproved = createSelector(
	selectVKwentaBalance,
	(state: RootState) => state.staking.vKwentaAllowance,
	(vKwentaBalance, vKwentaAllowance) => vKwentaBalance.lte(vKwentaAllowance)
)

export const selectIsVeKwentaTokenApproved = createSelector(
	selectVeKwentaBalance,
	(state: RootState) => state.staking.veKwentaAllowance,
	(veKwentaBalance, veKwentaAllowance) => veKwentaBalance.lte(veKwentaAllowance)
)

export const selectIsGettingRewardV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_staking_rewards_v2'
)

export const selectIsCompoundingRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'compound_rewards'
)

export const selectIsVestingEscrowedRewardsV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'vest_escrowed_rewards_v2'
)

export const selectIsStakingKwentaV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake_kwenta_v2'
)

export const selectIsStakingKwenta = createSelector(
	selectStakingV1,
	selectIsStakingKwentaV1,
	selectIsStakingKwentaV2,
	(stakingV1, isStakingKwentaV1, isStakingKwentaV2) =>
		stakingV1 ? isStakingKwentaV1 : isStakingKwentaV2
)

export const selectIsUnstakingKwentaV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake_kwenta_v2'
)

export const selectIsUnstakingKwenta = createSelector(
	selectStakingV1,
	selectIsUnstakingKwentaV1,
	selectIsUnstakingKwentaV2,
	(stakingV1, isUnstakingKwentaV1, isUnstakingKwentaV2) =>
		stakingV1 ? isUnstakingKwentaV1 : isUnstakingKwentaV2
)

export const selectIsStakingEscrowedKwentaV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake_escrow_v2'
)

export const selectIsUnstakingEscrowedKwentaV2 = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake_escrow_v2'
)

export const selectIsGettingReward = createSelector(
	selectStakingV1,
	selectIsGettingRewardV1,
	selectIsGettingRewardV2,
	(stakingV1, isGettingRewardV1, isGettingRewardV2) =>
		stakingV1 ? isGettingRewardV1 : isGettingRewardV2
)

export const selectIsVestingEscrowedRewards = createSelector(
	selectStakingV1,
	selectIsVestingEscrowedRewardsV1,
	selectIsVestingEscrowedRewardsV2,
	(stakingV1, isVestingEscrowedRewardsV1, isVestingEscrowedRewardsV2) =>
		stakingV1 ? isVestingEscrowedRewardsV1 : isVestingEscrowedRewardsV2
)

export const selectIsStakingEscrowedKwenta = createSelector(
	selectStakingV1,
	selectIsStakingEscrowedKwentaV1,
	selectIsStakingEscrowedKwentaV2,
	(stakingV1, isStakingEscrowedKwentaV1, isStakingEscrowedKwentaV2) =>
		stakingV1 ? isStakingEscrowedKwentaV1 : isStakingEscrowedKwentaV2
)

export const selectIsUnstakingEscrowedKwenta = createSelector(
	selectStakingV1,
	selectIsUnstakingEscrowedKwentaV1,
	selectIsUnstakingEscrowedKwentaV2,
	(stakingV1, isUnstakingEscrowedKwentaV1, isUnstakingEscrowedKwentaV2) =>
		stakingV1 ? isUnstakingEscrowedKwentaV1 : isUnstakingEscrowedKwentaV2
)

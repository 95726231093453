import { truncateString } from '@kwenta/sdk/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import ErrorIcon from 'assets/svg/app/error.svg'
import { isUserDeniedError } from 'utils/formatters/error'

import { Body } from './Text'
import { FlexDivCol, FlexDivRowCentered } from './layout/flex'

function ToastContent({ message, errorDetails }: { message: string; errorDetails?: string }) {
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState(false)
	if (!errorDetails) return <>{message}</>
	return (
		<FlexDivCol rowGap="10px">
			<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
				<ErrorIcon width={12} height={12} />
				{t('common.transaction.notification-title.error')}
			</FlexDivRowCentered>
			<StyledBody>{message}</StyledBody>
			<TextButton onClick={() => setExpanded(!expanded)}>
				{expanded ? 'Hide' : 'Show'} Details
			</TextButton>
			{expanded ? <ErrorDetails>{errorDetails}</ErrorDetails> : null}
		</FlexDivCol>
	)
}

export const notifyError = (message: string, error?: Error) => {
	if (isUserDeniedError(error?.message)) return
	const friendlyError = formatError(error?.message)
	const truncated = truncateString(message)
	toast.error(<ToastContent message={truncated} errorDetails={friendlyError} />, {
		position: 'top-right',
		toastId: message,
		containerId: 'notifications',
		bodyClassName: 'failBody',
		progressClassName: 'failProgress',
	})
}

export const ERROR_MESSAGES = {
	ORDER_PENDING: 'Previous order is pending, please wait for it to finish processing or cancel it.',
	INSUFFICIENT_MARGIN: 'Insufficient margin, minimum $50 margin required.',
	INSUFFICIENT_ETH_BAL: 'Insufficient eth balance for gas cost',
	CANNOT_CANCEL_ORDER_YET: 'Cannot cancel the order yet',
	ORDER_TOO_OLD: 'Order expired, please cancel',
	PRICE_IMPACT_EXCEEDED: 'Price exceeded desired fill price',
}

// TODO: Format more errors, especially transaction failures
export const formatError = (message?: string) => {
	if (!message) return ''
	const lowerCaseMessage = message.toLowerCase()
	if (lowerCaseMessage.includes('insufficient funds for intrinsic transaction cost'))
		return ERROR_MESSAGES.INSUFFICIENT_ETH_BAL
	if (lowerCaseMessage.includes('insufficient margin')) return ERROR_MESSAGES.INSUFFICIENT_MARGIN
	if (lowerCaseMessage.includes('previous order exists')) return ERROR_MESSAGES.ORDER_PENDING
	if (lowerCaseMessage.includes('cannot cancel yet')) return ERROR_MESSAGES.CANNOT_CANCEL_ORDER_YET
	if (lowerCaseMessage.includes('order too old')) return ERROR_MESSAGES.ORDER_TOO_OLD
	if (lowerCaseMessage.includes('price impact exceeded'))
		return ERROR_MESSAGES.PRICE_IMPACT_EXCEEDED
	return message
}

// TODO: Use re-useable component once merged with component refactor

const TextButton = styled.div`
	text-decoration: underline;
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	background-color: transparent;
	border: none;
	cursor: pointer;
`

const ErrorDetails = styled.div`
	font-size: 11px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

const StyledBody = styled(Body)`
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.banner.default.text};
`

import { http, createPublicClient, fallback } from 'viem';
import { arbitrum, arbitrumSepolia, base, baseSepolia, mainnet, optimism, optimismSepolia, } from 'viem/chains';
import { ALCHEMY_NETWORK_LOOKUP, TENDERLY_NETWORK_LOOKUP } from '../constants';
import { SupportedNetworkIds, } from '../types';
export const fallbackTransport = (chainId) => fallback([
    http(`https://${ALCHEMY_NETWORK_LOOKUP[chainId]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`),
    http(`https://${TENDERLY_NETWORK_LOOKUP[chainId]}.gateway.tenderly.co/${process.env.NEXT_PUBLIC_TENDERLY_PROJECT_ID}`),
]);
export const chainsByNetwork = {
    [SupportedNetworkIds.BASE_MAINNET]: base,
    [SupportedNetworkIds.BASE_SEPOLIA]: baseSepolia,
    [SupportedNetworkIds.OPTIMISM_MAINNET]: optimism,
    [SupportedNetworkIds.OPTIMISM_SEPOLIA]: optimismSepolia,
    [SupportedNetworkIds.ETHEREUM_MAINNET]: mainnet,
    [SupportedNetworkIds.ARB_MAINNET]: arbitrum,
    [SupportedNetworkIds.ARB_SEPOLIA]: arbitrumSepolia,
};
export const createClients = (networks) => {
    return networks.reduce((acc, network) => {
        const chain = chainsByNetwork[network];
        if (chain) {
            acc[network] = createPublicClient({
                chain: chain,
                transport: fallbackTransport(network),
            });
        }
        return acc;
    }, {});
};
export const createEIP7412Clients = (networks) => {
    return networks.reduce((acc, network) => {
        const chain = chainsByNetwork[network];
        if (chain) {
            acc[network] = createPublicClient({
                chain: chain,
                transport: http(`https://${ALCHEMY_NETWORK_LOOKUP[chain.id]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`),
            });
        }
        return acc;
    }, {});
};

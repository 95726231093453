import { type Activity, ActivityType, PointsLevel } from '@kwenta/sdk/types'
import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'

const mapActivity = (activity: Activity) => {
	return {
		timestamp: new Date(activity.timestamp).getTime(),
		account: activity.account,
		type: activity.type,
		integration: activity.integration,
		points: activity.points ?? 0,
		level: activity.level ?? activity.account_level ?? PointsLevel.NPC_I,
		season: activity.season === 'Season Unknown' ? 'Pre Season' : activity.season,
	}
}

export const selectLeaderboardSearchTerm = (state: RootState) => state.stats.leaderboardSearchTerm

export const selectTotalPoints = (state: RootState) => state.points.total
export const selectPointsLevel = (state: RootState) => state.points.level
export const selectUserEns = (state: RootState) => state.points.ens

export const selectPointsLoading = createSelector(
	(state: RootState) => state.points.queryStatuses,
	(queryStatuses) => {
		return queryStatuses.points.status === FetchStatus.Loading
	}
)

export const selectLeaderBoardPoints = (state: RootState) => state.points.leaderboard.points

export const selectLeaderBoardLoading = createSelector(
	(state: RootState) => state.points.queryStatuses,
	(queryStatuses) => {
		return queryStatuses.leaderboard.status === FetchStatus.Loading
	}
)

export const selectCurrentUserRank = (state: RootState) => {
	if (state.points.placement === 0) {
		return null
	}
	return state.points.placement
}

export const selectGlobalActivitesLoading = createSelector(
	(state: RootState) => state.points.queryStatuses,
	(queryStatuses) => {
		return queryStatuses.globalActivities.status === FetchStatus.Loading
	}
)

export const selectGlobalActivities = createSelector(
	(state: RootState) => state.points.globalActivities,
	(activities) => {
		return activities.map(mapActivity).sort((a, b) => b.timestamp - a.timestamp)
	}
)

export const selectUserActivitesLoading = createSelector(
	(state: RootState) => state.points.queryStatuses,
	(queryStatuses) => {
		return queryStatuses.userActivities.status === FetchStatus.Loading
	}
)

export const selectUserActivities = createSelector(
	(state: RootState) => state.points.userActivities,
	(activities) => {
		return activities
			.map(mapActivity)
			.filter((a) => a.type !== ActivityType.LEVEL_UP)
			.sort((a, b) => b.timestamp - a.timestamp)
	}
)

export const selectUserLatestActivities = (state: RootState) => state.points.userLatestActivities
export const selectUserLevelUpActivity = createSelector(
	selectUserLatestActivities,
	(activities) => {
		return activities.find((activity) => activity.type === ActivityType.LEVEL_UP)
	}
)

export const selectLatestFetchTimestamp = (state: RootState) => state.points.latestFetchTimestamp

export const selectSeasons = createSelector(
	(state: RootState) => state.points.seasons,
	(seasons) => {
		return seasons.map((season) => {
			const start = new Date(season.start).getTime()
			const end = new Date(season.end).getTime()
			return {
				...season,
				start: start,
				end: end,
				status: end < Date.now() ? 'ended' : 'ongoing',
			}
		})
	}
)
export const selectLevelThresholds = (state: RootState) => state.points.levels

import type KwentaSDK from '@kwenta/sdk'
import type { PerpsMarketV2, PerpsMarketV3 } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { notifyError } from 'components/ErrorNotifier'
import { GHOST_KEY } from 'state/constants'
import type { ThunkConfig } from 'state/types'
import { serializeV2Markets, serializeV3Markets } from 'utils/futures'
import logError from 'utils/logError'
import type { BlogPost } from './types'

export const fetchOptimismMarkets = createAsyncThunk<
	{ markets: PerpsMarketV2<string>[] },
	void,
	ThunkConfig
>('home/fetchOptimismMarkets', async (_, { extra: { sdk } }) => {
	// For the home page we always fetch OP mainnet markets
	const markets = await sdk.snxPerpsV2.getMarkets(10)

	const serializedMarkets = serializeV2Markets(markets)
	return { markets: serializedMarkets }
})

export const fetchBaseMarkets = createAsyncThunk<
	{ markets: PerpsMarketV3<string>[] },
	void,
	ThunkConfig
>('home/fetchBaseMarkets', async (_, { extra: { sdk } }) => {
	// For the home page we always fetch BASE mainnet markets
	const markets = await sdk.snxPerpsV3.getMarkets(8453)
	const serializedMarkets = serializeV3Markets(markets)
	return { markets: serializedMarkets }
})

export const fetchFuturesStats = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>,
	void,
	ThunkConfig
>('home/fetchFuturesStats', async (_, { extra: { sdk } }) => {
	try {
		return await sdk.stats.getFuturesStats(10)
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchBlogPosts = createAsyncThunk<BlogPost[], void, ThunkConfig>(
	'home/fetchBlogPosts',
	async () => {
		try {
			const result = await axios.get<{ posts: BlogPost[] }>(
				`https://blog.kwenta.io/ghost/api/content/posts/?key=${GHOST_KEY}&limit=3`,
				{
					headers: {
						'Accept-Version': '5.0',
					},
				}
			)

			if (result.status !== 200) {
				return []
			}

			return result.data.posts
		} catch (error) {
			logError(error)
			notifyError('Failed to fetch blog posts', error)
			throw error
		}
	}
)

import { ActivityType } from '@kwenta/sdk/types'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { EarnPointsNotification, LevelUpNotification } from 'components/PointsNotification'
import { selectHidePoints } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import {
	selectPointsLevel,
	selectTotalPoints,
	selectUserLatestActivities,
	selectUserLevelUpActivity,
} from 'state/points/selectors'

export default function useMonitorActivities() {
	const activities = useAppSelector(selectUserLatestActivities, isEqual)
	const levelUpActivity = useAppSelector(selectUserLevelUpActivity)
	const hidePoints = useAppSelector(selectHidePoints)

	const points = useAppSelector(selectTotalPoints)
	const level = useAppSelector(selectPointsLevel)

	const notifyLevelUp = useCallback(() => {
		toast(<LevelUpNotification points={points} level={level} />, {
			containerId: 'points',
			hideProgressBar: false,
			autoClose: 10000,
		})
	}, [points, level])

	const notifyEarnPoints = useCallback(
		(newPoints: number) => {
			toast(<EarnPointsNotification points={points} newPoints={newPoints} level={level} />, {
				containerId: 'points',
				hideProgressBar: false,
				autoClose: 10000,
			})
		},
		[points, level]
	)

	useEffect(() => {
		if (hidePoints) return
		activities
			.filter((activity) => activity.type === ActivityType.TRADE)
			.forEach((activity) => {
				notifyEarnPoints(activity.points!)
			})
	}, [activities, hidePoints, notifyEarnPoints])

	useEffect(() => {
		if (levelUpActivity && !hidePoints) {
			notifyLevelUp()
		}
	}, [levelUpActivity, hidePoints, notifyLevelUp])
}

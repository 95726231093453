import { ZERO_WEI } from '@kwenta/sdk/constants'
import { OrderTypeEnum, TransactionStatus } from '@kwenta/sdk/types'
import { getDisplayAsset } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import type { Hex } from 'viem'

import { createSelector } from '@reduxjs/toolkit'
import { notifyError } from 'components/ErrorNotifier'
import {
	TradeNotificationExecute,
	TradeNotificationFailed,
	TradeNotificationPending,
	TradeNotificationSuccess,
} from 'components/TradeNotification'
import { NotificationError } from 'components/TransactionNotification'
import {
	DEFAULT_DELAYED_CANCEL_BUFFER,
	DEFAULT_DELAYED_EXECUTION_BUFFER,
	DEFAULT_NOTIFICATION_TIMEOUT,
} from 'constants/defaults'
import {
	handleTransactionError,
	updateTransactionHash,
	updateTransactionOrder,
} from 'state/app/reducer'
import { selectTransaction } from 'state/app/selectors'
import { fetchPendingMarketOrders } from 'state/futures/actions'
import { fetchActivePositions, fetchAllOpenOrders, fetchTradeHistory } from 'state/futures/actions'
import {
	selectMarketAsset,
	selectMarketInfoAddress,
	selectPerpsProvider,
} from 'state/futures/common/selectors'
import { executeDelayedOrder } from 'state/futures/isolatedMargin/actions'
import { selectIsolatedMarginDelayedOrders } from 'state/futures/isolatedMargin/selectors'
import { selectAccountOrderHistory, selectActivePositions } from 'state/futures/selectors'
import { executeAsyncOrder } from 'state/futures/snxPerpsV3/actions'
import { selectAsyncCrossMarginOrder } from 'state/futures/snxPerpsV3/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import sdk from 'state/sdk'
import { providerIsCrossMargin, unserializeTransactionOrder } from 'utils/futures'

const selectPositions = createSelector(selectActivePositions, (positions) => {
	return positions.map((position) => {
		return {
			asset: position.market.asset,
			size: position.details.size,
		}
	})
})

export default function useMonitorTrades() {
	const dispatch = useAppDispatch()
	const transaction = useAppSelector(selectTransaction)

	const marketAsset = useAppSelector(selectMarketAsset)
	const marketInfoAddress = useAppSelector(selectMarketInfoAddress)

	const smartMarginOrders = useAppSelector(selectIsolatedMarginDelayedOrders, isEqual)
	const crossMarginOrder = useAppSelector(selectAsyncCrossMarginOrder)
	const perpsProvider = useAppSelector(selectPerpsProvider)
	const orderHistory = useAppSelector(selectAccountOrderHistory, isEqual)
	const positions = useAppSelector(selectPositions, isEqual)

	const [pending, setPending] = useState(false)
	const pendingRef = useRef(pending)

	useEffect(() => {
		pendingRef.current = pending
	}, [pending])

	const onExecute = useCallback(async () => {
		try {
			if (providerIsCrossMargin(perpsProvider)) {
				dispatch(executeAsyncOrder(transaction?.hash! as `0x${string}`))
			} else {
				dispatch(
					executeDelayedOrder({
						marketAsset,
						marketAddress: marketInfoAddress as `0x${string}`,
						originTxHash: transaction?.hash! as `0x${string}`,
					})
				)
			}
		} catch (error) {
			notifyError('Transaction failed', error)
		}
	}, [perpsProvider, dispatch, transaction?.hash, marketAsset, marketInfoAddress])

	const delayedOrder = useMemo(() => {
		if (!transaction?.order) {
			return undefined
		}
		const unserializedOrder = unserializeTransactionOrder(transaction.order)

		const crossMarginOrders = crossMarginOrder ? [crossMarginOrder] : []
		const delayedOrders = providerIsCrossMargin(perpsProvider)
			? crossMarginOrders
			: smartMarginOrders

		const delayedOrder = delayedOrders.find(
			(o) =>
				!!transaction?.order &&
				!!transaction?.hash &&
				o.executableStartTime * 1000 >= (transaction.timestamp ?? 0) &&
				o.market.asset === transaction.order.marketAsset &&
				o.size.abs().eq(unserializedOrder.sizeDelta)
		)
		if (delayedOrder) {
			const timePastExecution = Math.floor(Date.now() / 1000 - delayedOrder.executableStartTime)
			return {
				...delayedOrder,
				isStale: timePastExecution > DEFAULT_DELAYED_CANCEL_BUFFER,
				isFailed: timePastExecution > DEFAULT_DELAYED_EXECUTION_BUFFER,
			}
		}
	}, [perpsProvider, transaction, crossMarginOrder, smartMarginOrders])

	const position = useMemo(() => {
		if (!transaction?.order) {
			return undefined
		}
		const unserializedOrder = unserializeTransactionOrder(transaction.order)
		const size = unserializedOrder.newSize.eq(ZERO_WEI)
			? unserializedOrder.sizeDelta
			: unserializedOrder.newSize

		return positions.find(
			(p) => p.asset === transaction?.order?.marketAsset && wei(p.size).eq(size)
		)
	}, [positions, transaction])

	const order = useMemo(
		() =>
			orderHistory.find(
				(o) =>
					!!transaction?.order &&
					o.timestamp >= (transaction.timestamp ?? 0) &&
					o.displayAsset === getDisplayAsset(transaction.order.marketAsset) &&
					wei(o.sizeDelta).eq(wei(transaction.order.newSize))
			),
		[orderHistory, transaction]
	)

	const notifySuccess = useCallback(() => {
		if (transaction?.hash && transaction.order) {
			setPending(false)
			toast.update(transaction?.hash, {
				containerId: 'notifications',
				render: (
					<TradeNotificationSuccess
						txHash={transaction?.hash}
						order={unserializeTransactionOrder(transaction.order!)}
					/>
				),
				progressClassName: 'successProgress',
				hideProgressBar: false,
				autoClose: 10000,
			})
			dispatch(updateTransactionOrder(undefined))
			dispatch(updateTransactionHash(null))
		}
	}, [transaction, dispatch])

	const notifyFailed = useCallback(() => {
		if (transaction?.hash && transaction.order) {
			setPending(false)
			toast.update(transaction?.hash, {
				containerId: 'notifications',
				render: (
					<TradeNotificationFailed
						txHash={transaction?.hash}
						order={unserializeTransactionOrder(transaction.order!)}
					/>
				),
				hideProgressBar: false,
				progressClassName: 'failProgress',
				autoClose: 10000,
			})
			dispatch(updateTransactionOrder(undefined))
			dispatch(updateTransactionHash(null))
		}
	}, [transaction, dispatch])

	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null
		if (transaction?.hash && transaction.order && !order) {
			intervalId = setInterval(() => {
				dispatch(fetchActivePositions([perpsProvider]))
				if (providerIsCrossMargin(perpsProvider)) {
					// Our limit orders are offchain so we need to fetch only onchain orders
					dispatch(fetchPendingMarketOrders([perpsProvider]))
				} else {
					dispatch(fetchAllOpenOrders([perpsProvider]))
				}
				dispatch(fetchTradeHistory({ providers: [perpsProvider] }))
			}, 1000)
		} else {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}

		// Clear the polling interval when the component unmounts or the conditions change
		return () => {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}
	}, [perpsProvider, transaction, order, dispatch])

	useEffect(() => {
		if (transaction?.hash && transaction.order) {
			const txHash = transaction?.hash
			const emitter = sdk.transactions.hash(txHash as Hex, transaction.chainId)
			const toastProps = {
				containerId: 'notifications',
			}

			const unserializedOrder = unserializeTransactionOrder(transaction.order)

			emitter.on('txSent', () => {
				setPending(true)
				pendingRef.current = true

				toast(<TradeNotificationPending txHash={txHash} order={unserializedOrder} />, {
					...toastProps,
					toastId: txHash,
					hideProgressBar: true,
					autoClose: false,
				})
				setTimeout(() => {
					toast.dismiss(txHash)
					dispatch(updateTransactionOrder(undefined))
				}, DEFAULT_NOTIFICATION_TIMEOUT * 1000)
			})

			emitter.on('txFailed', ({ transactionHash, failureReason }) => {
				toast.update(transactionHash, {
					...toastProps,
					render: <NotificationError failureReason={failureReason} />,
					hideProgressBar: false,
					progressClassName: 'failProgress',
					autoClose: 10000,
				})
				dispatch(updateTransactionOrder(undefined))
				dispatch(handleTransactionError({ message: failureReason ?? 'transaction_failed' }))
			})
		}
	}, [transaction, dispatch])

	useEffect(() => {
		if (transaction?.hash && transaction.order && transaction.status === TransactionStatus.Failed) {
			notifyFailed()
		}
	}, [transaction, notifyFailed])

	useEffect(() => {
		if (transaction?.hash && transaction.order && delayedOrder && delayedOrder.isStale) {
			if (!delayedOrder.isFailed) {
				toast.update(transaction?.hash, {
					containerId: 'notifications',
					render: (
						<TradeNotificationExecute
							txHash={transaction?.hash}
							order={unserializeTransactionOrder(transaction.order!)}
							onExecute={onExecute}
						/>
					),
					hideProgressBar: true,
					autoClose: false,
				})
			} else {
				notifyFailed()
			}
		}
	}, [transaction, delayedOrder, onExecute, notifyFailed])

	useEffect(() => {
		if (providerIsCrossMargin(perpsProvider)) {
			return
		}
		if (transaction?.hash && transaction.order) {
			if (transaction.order.type !== OrderTypeEnum.MARKET) {
				if (transaction.status === TransactionStatus.Confirmed) {
					notifySuccess()
				}
			}
		}
	}, [perpsProvider, transaction, notifySuccess])

	useEffect(() => {
		if (transaction?.hash && transaction.order) {
			const newTradeFilled = !wei(transaction.order.newSize).eq(ZERO_WEI) && position !== undefined
			const closeTradeFilled = wei(transaction.order.newSize).eq(ZERO_WEI) && position === undefined
			if (newTradeFilled || closeTradeFilled) {
				notifySuccess()
			}
		}
	}, [transaction, position, notifySuccess])
}

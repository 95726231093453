import { type Activity, PointsLevel } from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { FetchStatus } from 'state/types'

import {
	fetchGlobalActivities,
	fetchLatestUserActivities,
	fetchLeaderBoard,
	fetchLevels,
	fetchPoints,
	fetchSeasons,
	fetchUserActivities,
} from './actions'
import type { PointsState } from './types'

export const STATS_INITIAL_STATE: PointsState = {
	queryStatuses: {
		points: DEFAULT_QUERY_STATUS,
		leaderboard: DEFAULT_QUERY_STATUS,
		globalActivities: DEFAULT_QUERY_STATUS,
		userActivities: DEFAULT_QUERY_STATUS,
		userLatestActivities: DEFAULT_QUERY_STATUS,
		seasons: DEFAULT_QUERY_STATUS,
		levels: DEFAULT_QUERY_STATUS,
	},
	total: 0,
	level: PointsLevel.NPC_I,
	placement: 0,
	leaderboard: {
		total: 0,
		points: [],
	},
	globalActivities: [],
	userActivities: [],
	userLatestActivities: [],
	latestFetchTimestamp: Date.now(),
	seasons: [],
	levels: [],
}

const pointsSlice = createSlice({
	name: 'stats',
	initialState: STATS_INITIAL_STATE,
	reducers: {
		// TODO: remove
		setLatestActivity: (state, action: PayloadAction<Activity>) => {
			state.userLatestActivities = [
				{
					...action.payload,
					points: action.payload.points !== undefined ? action.payload.points / 1e18 : undefined,
				},
			]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPoints.pending, (state) => {
			state.queryStatuses.points = LOADING_STATUS
		})
		builder.addCase(fetchPoints.rejected, (state) => {
			state.queryStatuses.points = {
				status: FetchStatus.Error,
				error: 'Failed to fetch points',
			}
		})
		builder.addCase(fetchPoints.fulfilled, (state, action) => {
			state.queryStatuses.points = SUCCESS_STATUS
			state.total = action.payload.total / 1e18
			state.level = action.payload.user.level
			state.ens = action.payload.user.ens?.name
			state.placement = action.payload.placement
		})
		builder.addCase(fetchLeaderBoard.pending, (state) => {
			state.queryStatuses.leaderboard = LOADING_STATUS
		})
		builder.addCase(fetchLeaderBoard.rejected, (state) => {
			state.queryStatuses.leaderboard = {
				status: FetchStatus.Error,
				error: 'Failed to fetch leaderboard',
			}
		})
		builder.addCase(fetchLeaderBoard.fulfilled, (state, action) => {
			state.queryStatuses.leaderboard = SUCCESS_STATUS
			state.leaderboard.total = action.payload.total
			state.leaderboard.points = action.payload.points.map((point) => ({
				...point,
				points: point.points / 1e18,
			}))
		})
		builder.addCase(fetchGlobalActivities.pending, (state) => {
			state.queryStatuses.globalActivities = LOADING_STATUS
		})
		builder.addCase(fetchGlobalActivities.rejected, (state) => {
			state.queryStatuses.globalActivities = {
				status: FetchStatus.Error,
				error: 'Failed to fetch global activities',
			}
		})
		builder.addCase(fetchGlobalActivities.fulfilled, (state, action) => {
			state.queryStatuses.globalActivities = SUCCESS_STATUS
			state.globalActivities = action.payload.map((activity) => ({
				...activity,
				fees_paid: activity.fees_paid !== undefined ? activity.fees_paid / 1e18 : undefined,
				points: activity.points !== undefined ? activity.points / 1e18 : undefined,
			}))
		})
		builder.addCase(fetchUserActivities.pending, (state) => {
			state.queryStatuses.userActivities = LOADING_STATUS
		})
		builder.addCase(fetchUserActivities.rejected, (state) => {
			state.queryStatuses.userActivities = {
				status: FetchStatus.Error,
				error: 'Failed to fetch user activities',
			}
		})
		builder.addCase(fetchUserActivities.fulfilled, (state, action) => {
			state.queryStatuses.userActivities = SUCCESS_STATUS
			state.userActivities = action.payload.map((activity) => ({
				...activity,
				fees_paid: activity.fees_paid !== undefined ? activity.fees_paid / 1e18 : undefined,
				points: activity.points !== undefined ? activity.points / 1e18 : undefined,
			}))
			state.latestFetchTimestamp = Math.max(
				state.userActivities.length > 0
					? new Date(state.userActivities[0].timestamp).getTime()
					: Date.now(),
				state.latestFetchTimestamp
			)
		})
		builder.addCase(fetchLatestUserActivities.pending, (state) => {
			state.queryStatuses.userLatestActivities = LOADING_STATUS
		})
		builder.addCase(fetchLatestUserActivities.rejected, (state) => {
			state.queryStatuses.userLatestActivities = {
				status: FetchStatus.Error,
				error: 'Failed to fetch user latest activities',
			}
		})
		builder.addCase(fetchLatestUserActivities.fulfilled, (state, action) => {
			state.queryStatuses.userLatestActivities = SUCCESS_STATUS
			state.userLatestActivities = action.payload.activities.map((activity) => ({
				...activity,
				fees_paid: activity.fees_paid !== undefined ? activity.fees_paid / 1e18 : undefined,
				points: activity.points !== undefined ? activity.points / 1e18 : undefined,
				points_total:
					activity.points_total !== undefined ? activity.points_total / 1e18 : undefined,
			}))
			state.userActivities = [...state.userLatestActivities, ...state.userActivities]
			state.latestFetchTimestamp = action.payload.latestTimestamp
			if (state.userLatestActivities.length > 0 && state.userLatestActivities[0].points_total) {
				state.total = state.userLatestActivities[0].points_total
			}
		})
		builder.addCase(fetchSeasons.pending, (state) => {
			state.queryStatuses.seasons = LOADING_STATUS
		})
		builder.addCase(fetchSeasons.rejected, (state) => {
			state.queryStatuses.seasons = {
				status: FetchStatus.Error,
				error: 'Failed to fetch seasons',
			}
		})
		builder.addCase(fetchSeasons.fulfilled, (state, action) => {
			state.queryStatuses.seasons = SUCCESS_STATUS
			state.seasons = action.payload.map((season) => ({
				...season,
				points: season.points / 1e18,
			}))
		})
		builder.addCase(fetchLevels.pending, (state) => {
			state.queryStatuses.levels = LOADING_STATUS
		})
		builder.addCase(fetchLevels.rejected, (state) => {
			state.queryStatuses.levels = {
				status: FetchStatus.Error,
				error: 'Failed to fetch seasons',
			}
		})
		builder.addCase(fetchLevels.fulfilled, (state, action) => {
			state.queryStatuses.levels = SUCCESS_STATUS
			state.levels = action.payload.sort((a, b) => a.start - b.start)
		})
	},
})

export const { setLatestActivity } = pointsSlice.actions

export default pointsSlice.reducer

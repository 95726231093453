export var PointsRank;
(function (PointsRank) {
    PointsRank["NPC"] = "NPC";
    PointsRank["NOVICE"] = "NOVICE";
    PointsRank["JOURNEYMAN"] = "JOURNEYMAN";
    PointsRank["EXPERT"] = "EXPERT";
    PointsRank["MASTER"] = "MASTER";
    PointsRank["GRANDMASTER"] = "GRANDMASTER";
    PointsRank["GOD"] = "GOD";
})(PointsRank || (PointsRank = {}));
export var PointsLevel;
(function (PointsLevel) {
    PointsLevel["NPC_I"] = "NPC";
    PointsLevel["NOVICE_I"] = "Novice I";
    PointsLevel["NOVICE_II"] = "Novice II";
    PointsLevel["NOVICE_III"] = "Novice III";
    PointsLevel["JOURNEYMAN_I"] = "Journeyman I";
    PointsLevel["JOURNEYMAN_II"] = "Journeyman II";
    PointsLevel["JOURNEYMAN_III"] = "Journeyman III";
    PointsLevel["EXPERT_I"] = "Expert I";
    PointsLevel["EXPERT_II"] = "Expert II";
    PointsLevel["EXPERT_III"] = "Expert III";
    PointsLevel["MASTER_I"] = "Master I";
    PointsLevel["MASTER_II"] = "Master II";
    PointsLevel["MASTER_III"] = "Master III";
    PointsLevel["GRANDMASTER_I"] = "Grandmaster I";
    PointsLevel["GRANDMASTER_II"] = "Grandmaster II";
    PointsLevel["GRANDMASTER_III"] = "Grandmaster III";
    PointsLevel["GOD_I"] = "God";
})(PointsLevel || (PointsLevel = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["LEVEL_UP"] = "Level Up";
    ActivityType["TRADE"] = "Trade";
    ActivityType["WHALE_BONUS"] = "Whale Bonus";
})(ActivityType || (ActivityType = {}));

import { LEVEL_THRESHOLDS } from '../constants';
import { PointsLevel, PointsRank } from '../types/points';
export function calculatePointsLevel(points, levelThresholds = LEVEL_THRESHOLDS) {
    const threshold = levelThresholds.findLast(({ start }) => points >= start);
    return threshold?.level ?? PointsLevel.GOD_I;
}
export function calculatePointsRank(points, levelThresholds = LEVEL_THRESHOLDS) {
    const threshold = levelThresholds.findLast(({ start }) => points >= start);
    return threshold?.rank ?? PointsRank.GOD;
}
export function calculatePointsRankFromLevel(pointsLevel, levelThresholds = LEVEL_THRESHOLDS) {
    const threshold = levelThresholds.findLast(({ level }) => level === pointsLevel);
    return threshold?.rank ?? PointsRank.GOD;
}
export function getNextThreshold(points, levelThresholds = LEVEL_THRESHOLDS) {
    const threshold = levelThresholds.find(({ start }) => points < start);
    return threshold?.start || null;
}
export function calculateLevelProgress(points, levelThresholds = LEVEL_THRESHOLDS) {
    const threshold = levelThresholds.findLast(({ start }) => points >= start);
    const nextThreshold = levelThresholds.find(({ start }) => points < start);
    const start = threshold?.start || 0;
    const end = nextThreshold?.start || null;
    if (!end) {
        return 1; // 100% progress if at or above the highest threshold
    }
    return (points - start) / (end - start);
}

import { SNX_V2_VIP_START_DATE } from '@kwenta/sdk/constants'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { VIP_TIERS } from 'state/constants'
import {
	selectSMTradesHistoryTableData,
	selectSubmittingFuturesTx,
	selectTradesQueryStatus,
} from 'state/futures/selectors'
import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import { shouldFilterHistoryTableByDate } from 'utils/futures'

import type { VipTier } from './types'

const selectQueryStatuses = (state: RootState) => state.vip.queryStatuses

const selectVipDataQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_vip_data?.status
)

const selectThirtyDayVolumeQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_thirty_day_volume?.status
)

export const selectVipDataIsLoading = createSelector(
	selectVipDataQueryStatus,
	selectThirtyDayVolumeQueryStatus,
	(vipDataIsLoading, thirtyDayVolumeIsLoading) =>
		vipDataIsLoading === FetchStatus.Loading || thirtyDayVolumeIsLoading === FetchStatus.Loading
)

export const selectAllTimeRebates = (state: RootState) => state.vip.allTimeRebates

const selectRebateTokenPrice = createSelector(selectPrices, (prices) => {
	return prices.OP
})

export const selectUnclaimedRewards = createSelector(
	selectRebateTokenPrice,
	(state: RootState) => state.vip,
	(rewardTokenPrice, vip) => {
		return {
			token: rewardTokenPrice?.offChain
				? wei(vip.totalFeeRebate).div(rewardTokenPrice.offChain).toString()
				: '0',
			fiat: wei(vip.totalFeeRebate).toString(),
		}
	}
)

export const selectVipTradeHistoryTableFilter = (state: RootState) =>
	state.vip.vipTradeHistoryTableFilter

const selectAccountClaimPeriod = (state: RootState) => state.vip.claimPeriod

export const selectLastClaimedBlock = (state: RootState) => state.vip.lastClaimedAtBlock

export const selectFeeReimbursed = (state: RootState) => state.vip.feeReimbursed

const selectVipTradeHistory = createSelector(
	selectAccountClaimPeriod,
	selectSMTradesHistoryTableData,
	selectLastClaimedBlock,
	selectFeeReimbursed,
	selectRebateTokenPrice,
	(state: RootState) => state.vip.lastFeeRebateAccumulatedStartBlock,
	(
		claimPeriod,
		trades,
		lastClaimedBlock,
		feeReimbursed,
		rebateTokenRealTimePrice,
		lastStartBlock
	) => {
		if (!claimPeriod) {
			return []
		}

		return trades
			.filter((t) => t.timestamp >= SNX_V2_VIP_START_DATE * 1000)
			.map((t) => {
				let feeRebateStatus: 'pending' | 'claimable' | 'claimed'

				if (t.blockNumber > claimPeriod.endBlockNumber) {
					feeRebateStatus = 'pending'
				} else if (
					lastClaimedBlock &&
					lastStartBlock &&
					((t.blockNumber < lastClaimedBlock && t.blockNumber < lastStartBlock) ||
						(t.blockNumber <= claimPeriod.endBlockNumber &&
							claimPeriod.endBlockNumber < lastClaimedBlock))
				) {
					feeRebateStatus = 'claimed'
				} else {
					feeRebateStatus = 'claimable'
				}

				const tradeFeeReimbursed = feeReimbursed.find((fr) => fr.blockNumber >= t.blockNumber)

				const rebateTokenPriceAtClaimTime = tradeFeeReimbursed?.rebateTokenPrice

				let tokenRebate: string | undefined = undefined

				if (feeRebateStatus === 'pending') {
					tokenRebate = '0'
				} else if (feeRebateStatus === 'claimed') {
					tokenRebate = rebateTokenPriceAtClaimTime
						? wei(t.feeRebate ?? '0')
								.div(wei(rebateTokenPriceAtClaimTime))
								.toString()
						: '0'
				} else {
					tokenRebate = rebateTokenRealTimePrice.offChain?.gt(0)
						? wei(t.feeRebate ?? '0')
								.div(rebateTokenRealTimePrice.offChain)
								.toString()
						: '0'
				}

				return {
					...t,
					feeRebateStatus,
					tokenRebate,
				}
			})
	}
)

export const selectVipTradeHistoryFilteredTableData = createSelector(
	selectAccountClaimPeriod,
	selectVipTradeHistory,
	(state: RootState) => state.vip.vipTradeHistoryTableFilter,
	(claimPeriod, trades, tableFilter) => {
		if (!claimPeriod) {
			return []
		}

		return trades
			.filter((t) => shouldFilterHistoryTableByDate(new Date(t.timestamp), tableFilter))
			.filter(
				(t) =>
					('vipTier' in t && String(Number(t.vipTier) - 1) === tableFilter?.tier) ||
					tableFilter?.tier === 'all' ||
					!tableFilter?.tier
			)
			.filter(
				(t) =>
					t.feeRebateStatus === tableFilter?.status ||
					tableFilter?.status === 'all' ||
					!tableFilter?.status
			)
	}
)

export const selectFeesPaidSinceClaimed = createSelector(
	selectVipTradeHistory,
	selectUnclaimedRewards,
	(trades, unclaimedRewards) => {
		if (Number(unclaimedRewards.token) === 0) {
			return '0'
		}

		return trades.reduce((acc, trade) => {
			if (trade.feeRebateStatus === 'claimable') {
				return acc.add(wei(trade.feesPaid ?? '0'))
			}

			return acc
		}, wei('0'))
	}
)

export const select30DayVolume = createSelector(
	selectVipTradeHistory,
	(state: RootState) => state.vip.thirtyDayVolume,
	(trades, thirtyDayVolume) => {
		const pendingVolume = trades.reduce((acc, trade) => {
			if (trade.feeRebateStatus !== 'pending') {
				return acc
			}

			return acc.add(trade.amount.mul(trade.price))
		}, wei('0'))

		const volume = wei(thirtyDayVolume ?? '0').sub(pendingVolume)

		return volume.lt(0) ? '0' : volume.toString()
	}
)

export const selectVipTier = createSelector(select30DayVolume, (thirtyDayVolume) => {
	const indexTier = Object.entries(VIP_TIERS).findIndex(
		([_, { volume }]) => Number(thirtyDayVolume || '0') <= volume
	)
	if (indexTier === -1) {
		return 4
	} else if (indexTier === 0) {
		return 0
	}

	return indexTier - 1
})

export const selectRemainingVolume = createSelector(
	selectVipTier,
	select30DayVolume,
	(tier, thirtyDayVolume) => {
		const nextTier = tier >= 4 ? 4 : ((tier + 1) as VipTier)
		return VIP_TIERS[nextTier].volume - wei(thirtyDayVolume).toNumber()
	}
)

export const selectAvgRebate = createSelector(
	selectFeesPaidSinceClaimed,
	(state: RootState) => state.vip,
	(paidFeesSinceClaimed, vip) => {
		if (!vip.totalFeeRebate || Number(paidFeesSinceClaimed) === 0) {
			return '0'
		}

		return wei(vip.totalFeeRebate)
			.div(wei(paidFeesSinceClaimed))
			.mul(wei(100))
			.toNumber()
			.toFixed(2)
	}
)

export const selectIsSubmittingClaim = createSelector(
	selectSubmittingFuturesTx,
	selectQueryStatuses,
	(state: RootState) => state.app,
	(submitting, statuses, app) => {
		return (
			(app.transaction?.type === 'claim_vip_rewards' && submitting) ||
			statuses.refetch_vip_data?.status === FetchStatus.Loading
		)
	}
)

export const selectIsLoadingVipTrades = createSelector(
	selectQueryStatuses,
	selectTradesQueryStatus,
	(statuses, tradeHistoryStatus) =>
		tradeHistoryStatus === FetchStatus.Loading ||
		statuses.get_vip_fee_reimbursed?.status === FetchStatus.Loading
)

export const selectCanClaim = createSelector(selectVipTradeHistory, (trades) => {
	return trades.filter((t) => t.feeRebateStatus === 'claimable' && t.feeRebate.gt(0)).length > 0
})

import type { TokenAllowances, TokenTickers } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { selectProviderNetwork } from 'state/futures/common/selectors'
import type { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'

export const selectBalances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return tokenBalances
	}
)

export const selectTokenAllowances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return Object.entries(tokenBalances).reduce<Partial<Record<TokenTickers, TokenAllowances>>>(
			(acc, [asset, balanceAndAllowances]) => {
				const serialized = Object.entries(balanceAndAllowances.allowances).reduce<TokenAllowances>(
					(acc, [spender, value]) => {
						if (value) acc[spender] = wei(value)
						return acc
					},
					{}
				)
				acc[asset as TokenTickers] = serialized
				return acc
			},
			{}
		)
	}
)

export const selectEthWalletBalance = createSelector(selectBalances, (balances) =>
	wei(balances.ETH?.balance ?? 0)
)

export const selectV3DepositableBalances = createSelector(selectBalances, (tokenBalances) => {
	return {
		USDC: wei(tokenBalances.USDC?.balance ?? 0),
		WETH: wei(tokenBalances.WETH?.balance ?? 0),
		USDx: wei(tokenBalances.USDx?.balance ?? 0),
		ETH: wei(tokenBalances.ETH?.balance ?? 0),
		tBTC: wei(tokenBalances.tBTC?.balance ?? 0),
		sBTC: wei(tokenBalances.sBTC?.balance ?? 0),
	}
})

export const selectUsdcBalance = createSelector(
	selectV3DepositableBalances,
	(balances) => balances.USDC ?? wei('0')
)

import { PerpsProvider, type SnxV2NetworkIds } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { selectAccountContexts } from 'state/futures/selectors'
import { handleFetchError } from 'state/helpers'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { refetchWithComparator } from 'utils/queries'

import {
	setAccountClaimPeriod,
	setFeeReimbursed,
	setQueryStatus,
	setThirtyDayVolume,
	setVipData,
} from './reducer'
import { selectLastClaimedBlock } from './selectors'
import type { FeeReimbursed, VipData } from './types'

export const fetchVipData = createAsyncThunk<
	void,
	{
		providers: PerpsProvider[]
	},
	ThunkConfig
>('futures/fetchVipData', async ({ providers }, { getState, dispatch, extra: { sdk } }) => {
	const contexts = selectAccountContexts(getState())

	for (const provider of providers) {
		const { network, accountId } = contexts[provider]

		if (!network || !accountId) {
			dispatch(setVipData(null))
			continue
		}

		dispatch(setQueryStatus({ key: 'get_vip_data', status: FetchStatus.Loading, provider }))
		try {
			let vipData = null
			if (provider === PerpsProvider.SNX_V2_OP) {
				vipData = await sdk.snxPerpsV2.getVipData(accountId, network as SnxV2NetworkIds)
			}

			dispatch(setVipData(vipData))
			dispatch(setQueryStatus({ key: 'get_vip_data', status: FetchStatus.Success, provider }))
		} catch (err) {
			handleFetchError(dispatch, 'get_vip_data', err, { provider })
		}
	}
})

export const refetchVipData = createAsyncThunk<
	void,
	{
		providers: PerpsProvider[]
	},
	ThunkConfig
>('futures/refetchVipData', async ({ providers }, { dispatch, getState, extra: { sdk } }) => {
	const contexts = selectAccountContexts(getState())
	const lastClaimedAtBlock = selectLastClaimedBlock(getState())

	for (const provider of providers) {
		const { network, accountId } = contexts[provider]
		dispatch(setQueryStatus({ key: 'refetch_vip_data', status: FetchStatus.Loading, provider }))

		if (!network || !accountId) continue

		try {
			let result: { data: VipData | null } = { data: null }
			let feeReimbursed: FeeReimbursed[] = []
			let accountClaimPeriod: {
				startBlockNumber: number
				endBlockNumber: number
			} = { startBlockNumber: 0, endBlockNumber: 0 }

			if (provider === PerpsProvider.SNX_V2_OP) {
				result = await refetchWithComparator(
					() => sdk.snxPerpsV2.getVipData(accountId, network as SnxV2NetworkIds),
					lastClaimedAtBlock,
					(existing, next) => {
						return existing === next.lastClaimedAtBlock
					}
				)
				feeReimbursed = await sdk.snxPerpsV2.getVipFeeReimbursed(
					accountId,
					network as SnxV2NetworkIds
				)
				accountClaimPeriod = await sdk.snxPerpsV2.getVipAccountClaimPeriod(
					accountId,
					network as SnxV2NetworkIds
				)
			}

			dispatch(setVipData(result.data))
			dispatch(setFeeReimbursed(feeReimbursed))
			dispatch(setAccountClaimPeriod(accountClaimPeriod))
			dispatch(setQueryStatus({ key: 'refetch_vip_data', status: FetchStatus.Success, provider }))
		} catch (err) {
			handleFetchError(dispatch, 'refetch_vip_data', err, { provider })
		}
	}
})

export const fetchThirtyDayVolume = createAsyncThunk<
	void,
	{
		providers: PerpsProvider[]
		fromDate: number
	},
	ThunkConfig
>(
	'futures/fetchThirtyDayVolume',
	async ({ providers, fromDate }, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const { network, wallet } = contexts[provider]

			if (!network || !wallet) {
				dispatch(setThirtyDayVolume('0'))
				continue
			}

			dispatch(setQueryStatus({ key: 'get_thirty_day_volume', status: FetchStatus.Loading }))
			try {
				let thirtyDayVolume = '0'
				if (provider === PerpsProvider.SNX_V2_OP) {
					thirtyDayVolume = await sdk.snxPerpsV2.getVolumeByDate(
						wallet,
						network as SnxV2NetworkIds,
						fromDate
					)
				}
				dispatch(setThirtyDayVolume(thirtyDayVolume))
				dispatch(
					setQueryStatus({ key: 'get_thirty_day_volume', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_thirty_day_volume', err, { provider })
			}
		}
	}
)

export const fetchSnxV2AccountClaimPeriod = createAsyncThunk<
	void,
	{
		providers: PerpsProvider[]
	},
	ThunkConfig
>('futures/fetchSnxV2AccountClaimPeriod', async (_, { getState, dispatch, extra: { sdk } }) => {
	const contexts = selectAccountContexts(getState())

	const { network, accountId } = contexts[PerpsProvider.SNX_V2_OP]

	if (!network || !accountId) {
		dispatch(setAccountClaimPeriod({ startBlockNumber: 0, endBlockNumber: 0 }))
		return
	}

	dispatch(setQueryStatus({ key: 'get_account_claim_period', status: FetchStatus.Loading }))
	try {
		const accountClaimPeriod = await sdk.snxPerpsV2.getVipAccountClaimPeriod(
			accountId,
			network as SnxV2NetworkIds
		)
		dispatch(setAccountClaimPeriod(accountClaimPeriod))
		dispatch(
			setQueryStatus({
				key: 'get_account_claim_period',
				status: FetchStatus.Success,
				provider: PerpsProvider.SNX_V2_OP,
			})
		)
	} catch (err) {
		handleFetchError(dispatch, 'get_account_claim_period', err, {
			provider: PerpsProvider.SNX_V2_OP,
		})
	}
})

export const fetchSnxV2VipFeeReimbursed = createAsyncThunk<
	void,
	{
		providers: PerpsProvider[]
	},
	ThunkConfig
>('futures/fetchSnxV2VipFeeReimbursed', async (_, { getState, dispatch, extra: { sdk } }) => {
	const contexts = selectAccountContexts(getState())

	const { network, accountId } = contexts[PerpsProvider.SNX_V2_OP]

	if (!network || !accountId) {
		dispatch(setFeeReimbursed([]))
		return
	}

	dispatch(setQueryStatus({ key: 'get_vip_fee_reimbursed', status: FetchStatus.Loading }))
	try {
		const feeReimbursed = await sdk.snxPerpsV2.getVipFeeReimbursed(
			accountId,
			network as SnxV2NetworkIds
		)
		dispatch(setFeeReimbursed(feeReimbursed))
		dispatch(
			setQueryStatus({
				key: 'get_vip_fee_reimbursed',
				status: FetchStatus.Success,
				provider: PerpsProvider.SNX_V2_OP,
			})
		)
	} catch (err) {
		handleFetchError(dispatch, 'get_vip_fee_reimbursed', err, { provider: PerpsProvider.SNX_V2_OP })
	}
})

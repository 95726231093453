import type { UserOperationStruct } from '@alchemy/aa-core'
import {
	type BiconomySmartAccountV2,
	type BuildUserOpOptions,
	Bundler,
	DEFAULT_MULTICHAIN_MODULE,
	DEFAULT_SESSION_KEY_MANAGER_MODULE,
	PaymasterMode,
	type PaymasterUserOperationDto,
	createSmartAccountClient,
} from '@biconomy/account'
import {
	type MultiChainValidationModule,
	type SendUserOpParams,
	type SessionKeyManagerModule,
	createMultiChainValidationModule,
	createSessionKeyManagerModule,
} from '@biconomy/modules'
import {
	ALCHEMY_NETWORK_LOOKUP,
	COMMON_ADDRESSES,
	ETH_ADDRESS,
	ZERO_BIG_INT,
} from '@kwenta/sdk/constants'
import type { NetworkId } from '@kwenta/sdk/types'
import { simulatedRequestToTxRequest, usdcDecimals } from '@kwenta/sdk/utils'
import { StorageKeys } from 'services/storage/storageKeys'
import {
	AbstractAccountAbstraction,
	type SendUserOperationsRequest,
	type SessionInfo,
	type Transaction,
} from 'types/accountAbstraction'
import {
	http,
	type Address,
	type Hex,
	type PublicClient,
	type WalletClient,
	createWalletClient,
	encodeAbiParameters,
	encodeFunctionData,
	erc20Abi,
	isHex,
	parseAbiItem,
	parseAbiParameters,
	parseEther,
	parseUnits,
} from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'

import { BICONOMY_VALIDATION_MODULE_ADDRESS } from 'constants/address'
import { EST_TRADE_TX_COST_ETH } from 'constants/defaults'
import kwentaSdk from 'state/sdk'
import { type DateInterval, ONE_SECOND_IN_MS, convertIntervalToSeconds } from 'utils/dates'
import logError from 'utils/logError'
import { getRandomInteger } from 'utils/numbers'

export class AccountAbstraction extends AbstractAccountAbstraction {
	private sdk?: BiconomySmartAccountV2

	private validationModule?: MultiChainValidationModule
	private sessionKeyManagerModule?: SessionKeyManagerModule

	private readonly MULTICHAIN_MODULE_ADDRESS = DEFAULT_MULTICHAIN_MODULE

	public accountAddress?: Address
	protected _chainId?: number
	private sessionKeyModuleAddress?: Address
	private validationModuleAddress?: Address

	private isPaymasterEnabled = false

	public get chainId() {
		return this._chainId
	}

	private set chainId(chainId: number | undefined) {
		this._chainId = chainId
	}

	private get usdcAddress() {
		const address = COMMON_ADDRESSES.USDC[this.chainId as NetworkId]
		if (!address) throw new Error('USDC address not found')
		return address
	}

	public async init(client: WalletClient, publicClient: PublicClient, isPaymasterEnabled: boolean) {
		const validationModule = await this.initValidationModule(client)

		const chainId = await publicClient.getChainId()

		this.chainId = chainId
		this.isPaymasterEnabled = isPaymasterEnabled

		this.validationModuleAddress = BICONOMY_VALIDATION_MODULE_ADDRESS[chainId]
		this.sessionKeyModuleAddress = DEFAULT_SESSION_KEY_MANAGER_MODULE

		const rpcUrl = `https://${ALCHEMY_NETWORK_LOOKUP[chainId]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`

		this.sdk = await createSmartAccountClient({
			bundler: new Bundler({
				bundlerUrl: rpcUrl,
				chainId,
				userOpReceiptMaxDurationIntervals: {
					[chainId]: ONE_SECOND_IN_MS * 60,
				},
				rpcUrl,
			}),
			paymasterUrl: `https://api.stackup.sh/v1/paymaster/${process.env.NEXT_PUBLIC_STACKUP_PAYMASTER_API_KEY}`,
			signer: client,
			defaultValidationModule: validationModule,
			activeValidationModule: validationModule,
			rpcUrl,
			chainId,
		})

		this.accountAddress = (await this.sdk.getAccountAddress()) as Address

		if (this.sessionKeyManagerModule) {
			await this.getOrCreateSessionKeyManagerModule(true) // update session module with new key
		}

		this.notifyAll()
	}

	public disconnect() {
		this.sdk = undefined
		this.accountAddress = undefined
		this.chainId = undefined

		this.notifyAll()
	}

	public async getBalance() {
		if (!this.sdk || !this.accountAddress) {
			throw new Error('SDK not initialized')
		}

		return this.sdk.rpcProvider.getBalance({ address: this.accountAddress })
	}

	public async getUsdcBalance() {
		const { chainId, sdk, accountAddress } = this

		if (!chainId || !sdk || !accountAddress) {
			throw new Error('Not connected')
		}

		const balance = await sdk?.rpcProvider.readContract({
			abi: erc20Abi,
			address: this.usdcAddress,
			functionName: 'balanceOf',
			args: [accountAddress],
		})

		return balance
	}

	private async initValidationModule(signer: WalletClient) {
		this.validationModule = await createMultiChainValidationModule({
			signer,
			moduleAddress: this.MULTICHAIN_MODULE_ADDRESS,
		})
		return this.validationModule
	}

	private async checkSessionKeyModuleEnabled() {
		if (!this.sdk || !this.sessionKeyModuleAddress) {
			throw new Error('SDK not initialized')
		}

		try {
			const sessionKeyModuleEnabled = await this.sdk.isModuleEnabled(this.sessionKeyModuleAddress)

			return sessionKeyModuleEnabled
		} catch (_e) {
			return false
		}
	}

	private async getOrCreateSessionKeyManagerModule(create = false) {
		if (this.sessionKeyModuleAddress === undefined || this.accountAddress === undefined) {
			throw new Error('SDK not initialized')
		}

		if (!this.sessionKeyManagerModule || create) {
			this.sessionKeyManagerModule = await createSessionKeyManagerModule({
				moduleAddress: this.sessionKeyModuleAddress,
				smartAccountAddress: this.accountAddress,
				chainId: this.chainId!,
			})
		}

		return this.sessionKeyManagerModule
	}

	public async createSession(
		interval: DateInterval | number,
		address: Address,
		withApprove = false
	) {
		if (
			!this.sdk ||
			!this.accountAddress ||
			!this.validationModuleAddress ||
			!this.sessionKeyModuleAddress
		) {
			throw new Error('SDK not initialized')
		}

		try {
			const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
			const key = (savedKey as Hex) ?? generatePrivateKey()
			const account = privateKeyToAccount(key)

			if (!savedKey) {
				this.storage.save(StorageKeys.SESSION_KEY, key)
			}

			this.removeLocalSessions()

			const sessionModule = await this.getOrCreateSessionKeyManagerModule(true)

			const sessionKeyData = encodeAbiParameters(parseAbiParameters('address x, address y'), [
				account.address,
				address,
			])

			const now = Math.floor(Date.now() / 1000)
			const secInterval =
				typeof interval === 'number' ? interval : convertIntervalToSeconds(interval)
			const getFutureTimestamp = now + secInterval

			const sessionData = await sessionModule.createSessionData([
				{
					validUntil: getFutureTimestamp,
					validAfter: now,
					sessionValidationModule: this.validationModuleAddress,
					sessionPublicKey: account.address,
					sessionKeyData,
				},
			])

			const enableTx = {
				to: this.sessionKeyModuleAddress,
				data: sessionData.data as Hex,
			}

			const isSessionKeyModuleEnabled = await this.checkSessionKeyModuleEnabled()

			const transactions: Transaction[] = []

			if (!isSessionKeyModuleEnabled) {
				const initTx = (await this.sdk.getEnableModuleData(
					this.sessionKeyModuleAddress
				)) as Transaction

				transactions.push(initTx)
			}

			transactions.push(enableTx)

			const options: BuildUserOpOptions = {}

			if (this.isPaymasterEnabled && withApprove) {
				const paymasterServiceData = this.preparePaymasterData()

				if (!paymasterServiceData) {
					throw new Error('Paymaster data not found')
				}

				options.paymasterServiceData = {
					...paymasterServiceData,
					skipPatchCallData: false,
				}
			}

			// Check for ETH balance. We need little bit ETH for PYTH price updates even if paymaster enabled
			const balance = await this.getBalance()

			if (balance === ZERO_BIG_INT) {
				try {
					const txs = await this.createSwapTxs()

					transactions.push(...txs)
				} catch (e) {
					logError(e)
				}
			}

			const { wait } = await this.sendTransactions({
				readyTxs: transactions,
				options,
			})

			const { success } = await wait()

			if (!success) {
				throw new Error('Session creation failed')
			}

			this.sdk = this.sdk.setActiveValidationModule(sessionModule)

			const session = await this.getSessionInfo()

			return session
		} catch (e) {
			this.removeLocalSessions()
			this.getOrCreateSessionKeyManagerModule()

			if (e.message.includes('simulation: insufficient token payments')) {
				if (withApprove) {
					throw new Error('Failed to approve token payments')
				}

				// Rerun with approve
				await this.createSession(interval, address, true)
				return
			}
			throw new Error(e)
		}
	}

	public async getSessionInfo(): Promise<SessionInfo | undefined> {
		if (this.sdk === undefined || this.accountAddress === undefined) {
			throw new Error('SDK not initialized')
		}

		try {
			const privateKey = this.storage.get(StorageKeys.SESSION_KEY)

			if (!privateKey) {
				throw new Error('Session key not found')
			}

			const account = privateKeyToAccount(privateKey as Hex)

			const sessionModule = await this.getOrCreateSessionKeyManagerModule()

			const session = await sessionModule.sessionStorageClient.getSessionData({
				sessionValidationModule: this.validationModuleAddress,
				sessionPublicKey: account.address,
			})

			return session
		} catch (_e) {
			return undefined
		}
	}

	public removeLocalSessions() {
		if (!this.accountAddress) {
			return
		}

		// TODO: Remove this when Biconomy will fix their bug
		localStorage.setItem(
			`${this.accountAddress.toLowerCase()}_sessions_${this.chainId}`,
			JSON.stringify({ merkleRoot: '', leafNodes: [] })
		)
		this.sessionKeyManagerModule = undefined
		this.sdk = this.sdk?.setActiveValidationModule(this.validationModule!)
	}

	public async closeAllSessions() {
		const { userOp, opts } = await this.prepareCloseAllSessions()
		// @ts-ignore
		const { wait } = await this.sendUserOperation({ userOp, params: opts?.params })

		const { success } = await wait()

		if (success === 'false') {
			throw new Error('Failed to close session')
		}

		this.removeLocalSessions()

		return this.getSessionInfo()
	}

	// We create a prepare method for estimate avg tx cost
	private async prepareCloseAllSessions() {
		if (!this.sdk || !this.accountAddress || !this.sessionKeyModuleAddress) {
			throw new Error('SDK not initialized')
		}

		// We sent empty merkleTree for validation module (restrict all previous sessions)
		const transactions = [
			{
				to: this.sessionKeyModuleAddress,
				data: encodeFunctionData({
					abi: [parseAbiItem('function setMerkleRoot(bytes32 _merkleRoot)')],
					args: ['0x0000000000000000000000000000000000000000000000000000000000000000'],
					functionName: 'setMerkleRoot',
				}),
			},
		]

		return this.prepareUserOp({ readyTxs: transactions, options: {} })
	}

	public async sendTransactions(params: SendUserOperationsRequest) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		try {
			const { userOp, opts } = await this.prepareUserOp(params)
			// @ts-ignore
			const res = await this.sendUserOperation({ userOp, params: opts?.params })

			return res
		} catch (e) {
			switch (e.message) {
				case "AA21 didn't pay prefund":
					throw new Error(
						'One-Click Trading balance is low. Please, fund your wallet and try again!'
					)
				case 'AA33 reverted: BTPM: account does not have enough token balance from Bundler':
					throw new Error(
						'One-Click Trading balance is low. Please, fund your wallet and try again!'
					)
				case 'AA23 reverted: SessionNotApproved':
					this.removeLocalSessions()
					throw new Error('Session invalid, open a new session to continue using One-Click Trading')
				default:
					throw new Error(e.message)
			}
		}
	}

	public async prepareUserOp({ readyTxs, simulateTxs, options }: SendUserOperationsRequest) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		if (!(readyTxs?.length || simulateTxs?.length)) {
			throw new Error('No transactions to send')
		}

		const formattedTxs = readyTxs ?? []

		if (simulateTxs) {
			formattedTxs.push(...simulateTxs.map(simulatedRequestToTxRequest))
		}

		const opts = options ?? (await this.prepareSessionOptions())
		if (options) {
			this.sdk = this.sdk.setActiveValidationModule(this.validationModule!)
		} else {
			this.sdk = this.sdk.setActiveValidationModule(this.sessionKeyManagerModule!)
		}

		if (!opts.paymasterServiceData && this.isPaymasterEnabled) {
			opts.paymasterServiceData = this.preparePaymasterData()
		}

		opts.nonceOptions = {
			nonceKey: getRandomInteger(),
		}

		const txsValue = formattedTxs.reduce(
			(acc, tx) => acc + (tx.value ? BigInt(tx.value) : ZERO_BIG_INT),
			ZERO_BIG_INT
		)

		if (txsValue !== ZERO_BIG_INT) {
			const balance = await this.getBalance()
			if (balance < txsValue) {
				this.removeLocalSessions()
				this.getOrCreateSessionKeyManagerModule()
				throw new Error('Need to create new session')
			}
		}
		const userOp = await this.sdk.buildUserOp(formattedTxs, {
			...opts,
		})

		return {
			userOp,
			opts,
		}
	}

	public async sendUserOperation({
		userOp,
		params,
	}: {
		userOp: Partial<UserOperationStruct>
		params?: SendUserOpParams
	}) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		const res = await this.sdk.sendUserOp(userOp, {
			...params,
		})

		return res
	}

	private async prepareSessionOptions(): Promise<BuildUserOpOptions> {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		const privateKey = this.storage.get(StorageKeys.SESSION_KEY)

		if (!privateKey) {
			throw new Error('Session key not found')
		}

		if (!isHex(privateKey)) {
			throw new Error('Session key is not a valid hex')
		}

		const account = privateKeyToAccount(privateKey)

		const sessionSigner = createWalletClient({
			account,
			chain: this.sdk.rpcProvider.chain,
			transport: http(),
		})

		const params = {
			sessionSigner,
			sessionValidationModule: this.validationModuleAddress,
		}

		return {
			params,
		}
	}

	public preparePaymasterData(): PaymasterUserOperationDto | undefined {
		return {
			mode: PaymasterMode.ERC20,
			preferredToken: this.usdcAddress,
			maxApproval: true,
			skipPatchCallData: true,
		}
	}

	public async estimateAvgTxCost() {
		try {
			const { userOp } = await this.prepareCloseAllSessions()

			const { callGasLimit, preVerificationGas, verificationGasLimit, maxFeePerGas } = userOp

			if (!callGasLimit || !preVerificationGas || !verificationGasLimit || !maxFeePerGas) {
				return parseEther(EST_TRADE_TX_COST_ETH.toString())
			}

			const estimate = this.isPaymasterEnabled
				? (BigInt(callGasLimit) +
						BigInt(3) * BigInt(verificationGasLimit) +
						BigInt(preVerificationGas)) *
					BigInt(maxFeePerGas)
				: (BigInt(callGasLimit) + BigInt(preVerificationGas) + BigInt(verificationGasLimit)) *
					BigInt(maxFeePerGas)

			return estimate * BigInt(2)
		} catch (_e) {
			return parseEther(EST_TRADE_TX_COST_ETH.toString())
		}
	}

	async createSwapTxs() {
		const decimals = usdcDecimals(this.chainId as NetworkId)

		const amountHR = '0.01'
		const amount = parseUnits(amountHR, decimals)

		const swapTx = await kwentaSdk.tokens.swapOneInch({
			fromTokenAddress: this.usdcAddress,
			toTokenAddress: ETH_ADDRESS,
			amount: amountHR,
			fromTokenDecimals: decimals,
			fromAddress: this.accountAddress,
			chainId: this.chainId as NetworkId,
		})

		const { request: approveTx } = await kwentaSdk.tokens.approveTokenSpend({
			address: this.usdcAddress,
			spender: swapTx.to,
			signer: this.accountAddress,
			amount,
			chainId: this.chainId as NetworkId,
		})

		const txs = [simulatedRequestToTxRequest(approveTx), swapTx]

		return txs
	}
}

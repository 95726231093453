import { PointsLevel, PointsRank } from '../types/points';
export const LEVEL_THRESHOLDS = [
    { start: 0, level: PointsLevel.NPC_I, rank: PointsRank.NPC },
    { start: 100, level: PointsLevel.NOVICE_I, rank: PointsRank.NOVICE },
    { start: 250, level: PointsLevel.NOVICE_II, rank: PointsRank.NOVICE },
    { start: 500, level: PointsLevel.NOVICE_III, rank: PointsRank.NOVICE },
    { start: 1000, level: PointsLevel.JOURNEYMAN_I, rank: PointsRank.JOURNEYMAN },
    { start: 2500, level: PointsLevel.JOURNEYMAN_II, rank: PointsRank.JOURNEYMAN },
    { start: 5000, level: PointsLevel.JOURNEYMAN_III, rank: PointsRank.JOURNEYMAN },
    { start: 10000, level: PointsLevel.EXPERT_I, rank: PointsRank.EXPERT },
    { start: 25000, level: PointsLevel.EXPERT_II, rank: PointsRank.EXPERT },
    { start: 50000, level: PointsLevel.EXPERT_III, rank: PointsRank.EXPERT },
    { start: 100000, level: PointsLevel.MASTER_I, rank: PointsRank.MASTER },
    { start: 250000, level: PointsLevel.MASTER_II, rank: PointsRank.MASTER },
    { start: 500000, level: PointsLevel.MASTER_III, rank: PointsRank.MASTER },
    { start: 1000000, level: PointsLevel.GRANDMASTER_I, rank: PointsRank.GRANDMASTER },
    { start: 2500000, level: PointsLevel.GRANDMASTER_II, rank: PointsRank.GRANDMASTER },
    { start: 5000000, level: PointsLevel.GRANDMASTER_III, rank: PointsRank.GRANDMASTER },
    { start: 10000000, level: PointsLevel.GOD_I, rank: PointsRank.GOD },
];
function generateGroupedLevelThresholds(levelThresholds) {
    return Object.values(PointsRank).map((rank) => ({
        rank,
        levels: levelThresholds
            .filter((lt) => lt.rank === rank)
            .map(({ start, level }) => ({ start, level })),
    }));
}
export const GROUPED_LEVEL_THRESHOLDS = generateGroupedLevelThresholds(LEVEL_THRESHOLDS);

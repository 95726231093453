import { wei } from '@kwenta/wei';
import { decodeFunctionResult, encodeFunctionData, formatEther, parseUnits, stringToHex, toHex, zeroAddress, } from 'viem';
import { COMMON_ADDRESSES, V3_WRAPPED_TOKEN_MARKETS, ZERO_BIG_INT, ZERO_WEI } from '../constants';
import { KWENTA_TRACKING_CODE, NEWLY_LISTED_MARKETS, SynthAssetKeysV3 } from '../constants/futures';
import { PERIOD_IN_HOURS, PERIOD_IN_SECONDS } from '../constants/period';
import { KWENTA_PERPS_V3_REFERRAL_ADDRESS, ORDERS_KEEPER_ADDRESSES, PREDEFINED_ORACLES, V3_PERPS_MARKET_IDS_TO_KEYS, V3_SYNTH_MARKET_IDS, V3_SYNTH_MARKET_IDS_ARB, V3_SYNTH_MARKET_IDS_BASE, v3MarketIdByMarketAsset, v3PerpsMarketIdToAssetKey, v3PythIdByMarketId, } from '../constants/perpsv3';
import MarginEngineAbi from '../contracts/abis/MarginEngine';
import PerpsMarketV3Abi from '../contracts/abis/PerpsV3MarketProxy';
import WethAbi from '../contracts/abis/WETH';
import { queryAllPerpsV3PositionLiquidations, queryAllPerpsV3SettledOrders, queryCollateralChanges, queryDelegatesForAccount, queryFundingRateHistory, queryOrderSettleds, queryPerpsV3DailyVolume, queryPerpsV3PositionLiquidations, queryPerpsV3Positions, queryPerpsV3SettledOrders, queryPnlSnapshots, querySettlementStrategies, querySubAccountsForAccount, } from '../queries/perpsV3';
import { FuturesMarginType, PerpsProvider, SnxV3NetworkIds, } from '../types';
import { ConditionalOrderStatusV3, } from '../types/perpsV3';
import { UNSUPPORTED_NETWORK } from '../constants/errors';
import { commonContractsByNetwork, snxV3ContractsByNetwork, tokenContractsByNetwork, } from '../contracts';
import { calculateTimestampForPeriod, notNill, parseUsdcValue, usdcDecimals, weiFromWei, } from '../utils';
import { DepositTokenToSynth, MarketAssetByKey, appAdjustedLeverage, getMarketCategory, getMarketName, mapCollateralChanges, } from '../utils/futures';
import { calculatePerpsV3Volumes, chainIsMultiCollateral, chainToV3Provider, formatSettlementStrategy, formatV3AsyncOrder, mapPerpsV3Position, mergeSnxV3LiquidationTrades, mergeSnxV3PositionsData, orderConditionsToCallData, reconcileOrders, serializeConditionalOrder, } from '../utils/perpsV3';
import { getReasonFromCode } from '../utils/token';
import { depositableAssetToToken } from '../utils/token';
import { multicallReads } from './calls';
export default class PerpsV3Service {
    constructor(sdk) {
        this.settlementStrategies = {};
        this.sendErc7412Transaction = async ({ calls, useMarginEngine, chainId, }) => {
            const { walletAddress } = this.sdk.context;
            const { PerpsV3MarketProxy, MarginEngine } = this.contractConfigs(chainId);
            const txs = calls.map((c) => {
                const contract = useMarginEngine ? MarginEngine : PerpsV3MarketProxy;
                if (!contract)
                    throw new Error(UNSUPPORTED_NETWORK);
                const data = useMarginEngine
                    ? encodeFunctionData({
                        abi: MarginEngineAbi,
                        functionName: c.functionName,
                        args: c.params,
                    })
                    : encodeFunctionData({
                        abi: PerpsMarketV3Abi,
                        functionName: c.functionName,
                        args: c.params,
                    });
                return {
                    to: contract.address,
                    from: walletAddress,
                    value: c.value ? BigInt(c.value) : BigInt(0),
                    data: data,
                };
            });
            const res = await this.sdk.calls.erc7412Write(txs, chainId, PREDEFINED_ORACLES, useMarginEngine);
            return res;
        };
        this.batchMarketCalls = async (calls, networkId, oracleIds = []) => {
            const configs = this.contractConfigs(networkId);
            const PerpsV3MarketProxy = configs?.PerpsV3MarketProxy;
            const abi = PerpsV3MarketProxy.abi;
            if (!PerpsV3MarketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const encoded = calls.map((m) => ({
                to: PerpsV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: abi,
                    functionName: m.functionName,
                    args: m.args,
                }),
                value: ZERO_BIG_INT,
            }));
            const multiCalls = multicallReads(encoded, networkId);
            const results = await this.sdk.calls.erc7412Read([multiCalls], networkId, oracleIds);
            return results.map((r, i) => {
                const call = calls[i];
                // @ts-ignore TODO: Fix types
                return decodeFunctionResult({
                    abi: abi,
                    functionName: call.functionName,
                    data: r.returnData,
                });
            });
        };
        this.sdk = sdk;
    }
    contractConfigs(chainId) {
        return {
            ...snxV3ContractsByNetwork(chainId),
            ...commonContractsByNetwork(chainId),
            ...tokenContractsByNetwork(chainId),
        };
    }
    client(chainId) {
        const client = this.sdk.context.clients[chainId];
        if (client)
            return client;
        throw new Error(UNSUPPORTED_NETWORK);
    }
    async getMarkets(chainId) {
        const strategies = await this.getSettlementStrategies(chainId);
        const client = this.client(chainId);
        const perpsV3MarketProxy = this.contractConfigs(chainId)?.PerpsV3MarketProxy;
        if (!perpsV3MarketProxy || !client)
            throw new Error(UNSUPPORTED_NETWORK);
        const markets = await client.readContract({
            ...perpsV3MarketProxy,
            functionName: 'getMarkets',
        });
        const perpsV3Markets = markets.filter((m) => m !== 6300n);
        const interestRate = await client.readContract({
            ...perpsV3MarketProxy,
            functionName: 'interestRate',
        });
        const calls = perpsV3Markets.reduce((acc, m) => {
            acc.push(...[
                {
                    functionName: 'getFundingParameters',
                    args: [m],
                },
                {
                    functionName: 'getLiquidationParameters',
                    args: [m],
                },
                {
                    functionName: 'getMaxLiquidationParameters',
                    args: [m],
                },
                {
                    functionName: 'getOrderFees',
                    args: [m],
                },
                {
                    functionName: 'getMaxMarketSize',
                    args: [m],
                },
                {
                    functionName: 'getMaxMarketValue',
                    args: [m],
                },
                {
                    functionName: 'getMarketSummary',
                    args: [m],
                },
            ]);
            return acc;
        }, []);
        const results = await this.batchMarketCalls(calls, chainId, PREDEFINED_ORACLES);
        const futuresData = results.reduce((acc, res, i) => {
            const type = i % 7;
            switch (type) {
                case 0:
                    acc.fundingParams.push(res);
                    break;
                case 1:
                    acc.liquidationParams.push(res);
                    break;
                case 2:
                    acc.maxLiquidationParams.push(res);
                    break;
                case 3:
                    acc.fees.push(res);
                    break;
                case 4:
                    acc.maxMarketSizes.push(res);
                    break;
                case 5:
                    acc.maxMarketValues.push(res);
                    break;
                case 6:
                    acc.marketSummaries.push(res);
                    break;
            }
            return acc;
        }, {
            fundingParams: [],
            liquidationParams: [],
            maxLiquidationParams: [],
            fees: [],
            maxMarketSizes: [],
            maxMarketValues: [],
            marketSummaries: [],
        });
        const { fundingParams, liquidationParams, maxLiquidationParams, fees, maxMarketSizes, maxMarketValues, marketSummaries, } = futuresData;
        const futuresMarkets = marketSummaries.reduce((acc, summary, i) => {
            const [makerFee, takerFee] = fees[i];
            const perpsMarketId = perpsV3Markets[i].toString();
            const [skewScale] = fundingParams[i];
            const [initialMarginRatioD18, minimumInitialMarginRatioD18, minimumPositionMargin, maintenanceMarginScalarD18, flagRewardRatioD18,] = liquidationParams[i];
            const [maxLiquidationLimitAccumulationMultiplier, maxSecondsInLiquidationWindow] = maxLiquidationParams[i];
            const maxMarketSize = maxMarketSizes[i];
            const maxMarketValue = maxMarketValues[i];
            const marketKey = V3_PERPS_MARKET_IDS_TO_KEYS[Number(perpsMarketId)];
            if (!marketKey)
                return acc;
            const asset = MarketAssetByKey[marketKey];
            const marketSize = weiFromWei(summary.size.toString());
            const marketSkew = weiFromWei(summary.skew.toString());
            const maxOpenInterest = weiFromWei(summary.maxOpenInterest.toString());
            const indexPrice = weiFromWei(summary.indexPrice.toString());
            const makerFeeWei = weiFromWei(makerFee);
            const takerFeeWei = weiFromWei(takerFee);
            const maxMarketSizeWei = weiFromWei(maxMarketSize.toString());
            const maxMarketValueWei = weiFromWei(maxMarketValue.toString());
            const calculatedMaxMarketValue = maxMarketSizeWei.mul(indexPrice);
            const limitUsd = maxMarketValueWei.gt(calculatedMaxMarketValue)
                ? calculatedMaxMarketValue
                : maxMarketValueWei;
            const strategy = strategies.filter((s) => s.marketId === perpsMarketId)[0];
            acc.push({
                provider: chainToV3Provider(chainId),
                marginType: FuturesMarginType.CROSS_MARGIN,
                marketId: perpsMarketId.toString(),
                marketName: getMarketName(asset) ?? marketKey,
                category: getMarketCategory(marketKey),
                settlementStrategies: strategies.filter((s) => s.marketId === perpsMarketId),
                asset: asset,
                currentFundingRate: weiFromWei(summary.currentFundingRate.toString()).div('24'),
                settlementFee: strategy?.settlementReward ?? wei(0),
                feeRates: {
                    makerFee: makerFeeWei,
                    takerFee: takerFeeWei,
                },
                openInterest: {
                    shortPct: marketSize.eq(0)
                        ? 0
                        : marketSize.sub(marketSkew).div('2').div(marketSize).toNumber(),
                    longPct: wei(marketSize).eq(0)
                        ? 0
                        : marketSize.add(marketSkew).div('2').div(marketSize).toNumber(),
                    shortUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).sub(marketSkew).div('2').mul(indexPrice),
                    longUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).add(marketSkew).div('2').mul(indexPrice),
                    long: wei(marketSize).add(marketSkew).div('2'),
                    short: wei(marketSize).sub(marketSkew).div('2'),
                },
                marketDebt: wei(0),
                marketSkew: marketSkew,
                appMaxLeverage: appAdjustedLeverage(wei(25)),
                marketLimitUsd: {
                    long: limitUsd,
                    short: limitUsd,
                },
                marketLimitNative: {
                    long: maxOpenInterest,
                    short: maxOpenInterest,
                },
                minInitialMargin: wei(100), // TODO: Is this still relevant in v3
                isSuspended: false, // TODO: Assign suspensions
                marketClosureReason: getReasonFromCode(2), // TODO: Map closure reason
                settings: {
                    initialMarginRatio: weiFromWei(initialMarginRatioD18),
                    skewScale: weiFromWei(skewScale),
                    minimumInitialMarginRatio: weiFromWei(minimumInitialMarginRatioD18),
                    minimumPositionMargin: weiFromWei(minimumPositionMargin),
                    maintenanceMarginScalar: weiFromWei(maintenanceMarginScalarD18),
                    maxLiquidationLimitAccumulationMultiplier: weiFromWei(maxLiquidationLimitAccumulationMultiplier),
                    maxSecondsInLiquidationWindow: wei(maxSecondsInLiquidationWindow.toString()),
                    flagRewardRatio: weiFromWei(flagRewardRatioD18),
                    interestRate: weiFromWei(interestRate),
                },
                newListing: !!NEWLY_LISTED_MARKETS[chainId]?.includes(marketKey),
            });
            return acc;
        }, []);
        return futuresMarkets;
    }
    async getPositions(params) {
        const { accountId, futuresMarkets, chainId, status, options } = params;
        const positions = await queryPerpsV3Positions(chainId, accountId, status ?? 'all', options);
        const activePositions = await this.getActivePositions(BigInt(accountId), futuresMarkets.map((fm) => fm.id), chainId);
        const marginInfo = await this.getAccountMarginInfo(BigInt(accountId), chainId);
        const markPrices = await this.getMarketPrices(chainId);
        return mergeSnxV3PositionsData(positions, activePositions, marginInfo, futuresMarkets, markPrices);
    }
    async getActivePositions(accountId, marketIds, chainId) {
        if (!marketIds.length)
            return [];
        const oracleIds = marketIds
            .map((id) => v3PythIdByMarketId(id.toString()))
            .filter(Boolean);
        const positionDetails = (await this.batchMarketCalls(marketIds.map((id) => ({ functionName: 'getOpenPosition', args: [accountId, id] })), chainId, oracleIds));
        const positions = positionDetails.reduce((acc, res, i) => {
            const pos = mapPerpsV3Position(marketIds[i], ...res);
            if (pos)
                acc.push(pos);
            return acc;
        }, []);
        return positions;
    }
    async getSupportedCollaterals(chainId) {
        const perpsV3MarketProxyMultiC = this.contractConfigs(chainId)?.PerpsV3MarketProxy;
        const collaterals = await this.client(chainId).readContract({
            ...perpsV3MarketProxyMultiC,
            functionName: 'getSupportedCollaterals',
        });
        return collaterals.reduce((acc, c) => {
            const key = Object.keys(V3_SYNTH_MARKET_IDS_ARB).find((key) => V3_SYNTH_MARKET_IDS_ARB[key] === Number(c));
            if (key) {
                acc.push(key);
            }
            return acc;
        }, []);
    }
    async getCollateralBalances(accountId, chainId) {
        const ids = (chainIsMultiCollateral(chainId) ? V3_SYNTH_MARKET_IDS_ARB : V3_SYNTH_MARKET_IDS_BASE);
        const idKeys = Object.keys(ids);
        const results = await this.batchMarketCalls(idKeys.map((id) => ({
            functionName: 'getCollateralAmount',
            args: [accountId, ids[id]],
        })), chainId);
        return results.map((res, i) => ({
            synthId: idKeys[i],
            amount: wei(res),
        }));
    }
    async getMarketPrices(chainId) {
        const markets = this.markets ?? (await this.getMarkets(chainId));
        return markets.reduce((acc, curr) => {
            try {
                const prices = this.sdk.prices.getOffchainPrice(curr.asset);
                acc[curr.asset] = prices;
                return acc;
            }
            catch {
                return acc;
            }
        }, {});
    }
    async getMarketFundingRatesHistory(marketAsset, chainId, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS) {
        const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
        return queryFundingRateHistory(chainId, marketAsset, minTimestamp);
    }
    async getSettlementStrategies(chainId) {
        const existing = this.settlementStrategies[chainId];
        if (existing && existing.lastUpdated > Date.now() - PERIOD_IN_SECONDS.ONE_HOUR * 1000) {
            return existing.strategies;
        }
        const strategies = await querySettlementStrategies(chainId);
        const formattedStrats = strategies.map(formatSettlementStrategy);
        this.settlementStrategies[chainId] = {
            lastUpdated: Date.now(),
            strategies: formattedStrats,
        };
        return formattedStrats;
    }
    async getDailyVolumes(chainId) {
        const minTimestamp = Math.floor(calculateTimestampForPeriod(PERIOD_IN_HOURS.ONE_DAY) / 1000);
        const response = await queryPerpsV3DailyVolume(chainId, minTimestamp);
        return response ? calculatePerpsV3Volumes(response) : {};
    }
    async getAccounts(walletAddress, marginEngineEnabled, chainId) {
        const { PerpsV3AccountProxy, PerpsV3MarketProxy, MarginEngine } = this.contractConfigs(chainId);
        const marginEngineAddr = MarginEngine?.address;
        if (!PerpsV3AccountProxy || !PerpsV3MarketProxy || !marginEngineAddr)
            throw new Error(UNSUPPORTED_NETWORK);
        if (!walletAddress)
            return [];
        const accountCount = await this.client(chainId).readContract({
            ...PerpsV3AccountProxy,
            functionName: 'balanceOf',
            args: [walletAddress],
        });
        const calls = Number(accountCount) > 0
            ? [...Array(Number(accountCount)).keys()].map((index) => {
                return {
                    ...PerpsV3AccountProxy,
                    functionName: 'tokenOfOwnerByIndex',
                    args: [walletAddress, index],
                };
            })
            : [];
        const accountIds = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: calls,
        });
        const permissionCalls = accountIds.map((res) => {
            return {
                ...PerpsV3MarketProxy,
                functionName: 'hasPermission',
                args: [res, stringToHex('ADMIN', { size: 32 }), marginEngineAddr],
            };
        });
        const permissions = marginEngineEnabled
            ? // @ts-ignore Cannot figure out the type issue
                await this.client(chainId).multicall({ allowFailure: false, contracts: permissionCalls })
            : [];
        return accountIds.map((id, i) => ({
            accountId: id,
            // @ts-ignore
            marginEnginePermitted: !!permissions[i],
        }));
    }
    async getAccountOwner(id, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const owner = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'getAccountOwner',
            args: [BigInt(id)],
        });
        if (owner === zeroAddress)
            return null;
        return owner;
    }
    async getAccountMarginInfo(accountId, chainId) {
        const calls = [
            { functionName: 'getAvailableMargin', args: [accountId] },
            { functionName: 'getRequiredMargins', args: [accountId] },
        ];
        if (chainIsMultiCollateral(chainId)) {
            calls.push({ functionName: 'debt', args: [accountId] });
        }
        const [availableMargin, margins, debt] = (await this.batchMarketCalls(calls, chainId, PREDEFINED_ORACLES));
        const availableMarginWei = weiFromWei(availableMargin.toString());
        const initialMarginWei = weiFromWei(margins[0].toString());
        const liqRewardWei = weiFromWei(margins[2].toString());
        const withdrawableMargin = availableMarginWei.sub(initialMarginWei.add(liqRewardWei));
        return {
            availableMargin: availableMarginWei,
            withdrawableMargin: withdrawableMargin,
            requiredInitialMargin: initialMarginWei,
            requiredMaintenanceMargin: weiFromWei(margins[1].toString()),
            maxLiquidationReward: liqRewardWei,
            debt: weiFromWei(debt?.toString() ?? 0),
        };
    }
    async getAccountUsdcCredit(accountId, chainId) {
        const { MarginEngine } = this.contractConfigs(chainId);
        const client = this.client(chainId);
        if (!MarginEngine || !client)
            throw new Error(UNSUPPORTED_NETWORK);
        const balance = await client.readContract({
            ...MarginEngine,
            functionName: 'credit',
            args: [accountId],
        });
        return wei(balance);
    }
    async getPendingAsyncOrder(accountId, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const order = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'getOrder',
            args: [accountId],
        });
        if (order.request.sizeDelta !== ZERO_BIG_INT) {
            return formatV3AsyncOrder(order);
        }
        return;
    }
    async getTradePreview({ accountId, marketId, chainId, sizeDelta, indexPrice, }) {
        const assetKey = v3PerpsMarketIdToAssetKey(marketId);
        if (!assetKey)
            throw new Error(`Cannot find asset key for market id: ${marketId}`);
        const [[fee], fillPrice, requiredMargin] = (await this.batchMarketCalls([
            {
                functionName: 'computeOrderFeesWithPrice',
                args: [marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
            {
                functionName: 'fillPrice',
                args: [marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
            {
                functionName: 'requiredMarginForOrderWithPrice',
                args: [accountId, marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
        ], chainId, PREDEFINED_ORACLES));
        const executionFee = await this.getExecutionCost();
        const { settlementReward } = (await this.getSettlementStrategies(chainId)).find((s) => s.marketId === marketId) ?? { settlementReward: ZERO_WEI };
        return {
            fillPrice: weiFromWei(fillPrice.toString()),
            fee: weiFromWei(fee.toString()),
            requiredMargin: requiredMargin ? weiFromWei(requiredMargin.toString()) : wei(0),
            settlementFee: executionFee.add(settlementReward),
        };
    }
    async getExecutionCost(chainId = SnxV3NetworkIds.BASE_MAINNET) {
        const { PerpsV3MarketProxy, SynthetixV3Proxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy || !SynthetixV3Proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const nodeId = this.executorNodeId ??
            (await this.client(chainId).readContract({
                ...PerpsV3MarketProxy,
                functionName: 'getKeeperCostNodeId',
                args: [],
            }));
        this.executorNodeId = nodeId;
        const encoded = [
            {
                to: SynthetixV3Proxy.address,
                data: encodeFunctionData({
                    abi: SynthetixV3Proxy.abi,
                    functionName: 'process',
                    args: [nodeId],
                }),
                value: ZERO_BIG_INT,
            },
        ];
        const multiCalls = multicallReads(encoded, chainId);
        const results = await this.sdk.calls.erc7412Read([multiCalls], chainId);
        const { price } = decodeFunctionResult({
            abi: SynthetixV3Proxy.abi,
            functionName: 'process',
            data: results[0].returnData,
        });
        return wei(price);
    }
    // TODO: Support pagination
    async getTradeHistory(accountId, chainId, options) {
        const trades = await queryPerpsV3SettledOrders(chainId, { accountId: accountId.toString() }, options);
        const liquidationTrades = await queryPerpsV3PositionLiquidations(chainId, { accountId: accountId.toString() }, options);
        return mergeSnxV3LiquidationTrades(trades, liquidationTrades);
    }
    async getAllTradesByMarket(marketAsset, chainId, options) {
        const marketId = v3MarketIdByMarketAsset(marketAsset);
        return queryAllPerpsV3SettledOrders(chainId, marketId, options);
    }
    async getTradesForPosition(positionId, chainId, options) {
        const trades = await queryPerpsV3SettledOrders(chainId, { positionId }, options);
        const liquidationTrades = await queryPerpsV3PositionLiquidations(chainId, { positionId }, options);
        return mergeSnxV3LiquidationTrades(trades, liquidationTrades);
    }
    async getAllAccountLiquidations(accountId, chainId, options) {
        const liquidations = await queryPerpsV3PositionLiquidations(chainId, { accountId: accountId.toString() }, options);
        return liquidations.map((o) => ({
            ...o,
            timestamp: Number(o.timestamp),
            estimatedPrice: weiFromWei(o.estimatedPrice),
            amount: weiFromWei(o.amount),
            notionalAmount: weiFromWei(o.notionalAmount),
            liquidationPnl: weiFromWei(o.liquidationPnl),
        }));
    }
    async getAllLiquidationsByMarket(marketId, chainId, options) {
        const positionLiquidations = await queryAllPerpsV3PositionLiquidations(chainId, marketId, options);
        return positionLiquidations.map((o) => ({
            ...o,
            timestamp: Number(o.timestamp),
            estimatedPrice: weiFromWei(o.estimatedPrice),
            amount: weiFromWei(o.amount),
            notionalAmount: weiFromWei(o.notionalAmount),
            liquidationPnl: weiFromWei(o.liquidationPnl),
        }));
    }
    async getDepositAllowances(walletAddress, chainId = SnxV3NetworkIds.BASE_MAINNET) {
        const { PerpsV3MarketProxy, USDx } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy || !USDx)
            throw new Error(UNSUPPORTED_NETWORK);
        const snxusd = await this.client(chainId).readContract({
            ...USDx,
            functionName: 'allowance',
            args: [walletAddress, PerpsV3MarketProxy.address],
        });
        return { snxusd: wei(snxusd) };
    }
    async getPnlSnapshots(accountId, chainId) {
        const snapshots = await queryPnlSnapshots(chainId, accountId.toString());
        return snapshots.map((s) => ({
            ...s,
            timestamp: Number(s.timestamp),
            pnl: weiFromWei(s.pnl),
        }));
    }
    async getMarginSnapshots(accountId, chainId, args) {
        const params = new URLSearchParams({
            networkId: chainId.toString(),
            accountId: accountId.toString(),
            from: args.fromTimestamp.toString(),
            to: args.toTimestamp.toString(),
            resolution: args.resolution.toString(),
        });
        const url = `${this.sdk.context.apiUrl}/perpsV3/account/${accountId}/margin-snapshots?${params.toString()}`;
        const response = await fetch(url);
        const res = await response.json();
        return res.data.marginSnapshots.map((s) => ({
            ...s,
            margin: weiFromWei(s.margin),
        }));
    }
    /**
     * @desc Get conditional orders
     * @param address - Cross margin address
     * @returns Conditional orders array
     * @example
     * ```ts
     * import { KwentaSDK } from 'kwenta-sdk';
     * const sdk = new KwentaSDK();
     * const conditionalOrders = await sdk.snxPerpsV3.getConditionalOrders('0x...');
     * console.log(conditionalOrders);
     * ```
     */
    async getConditionalOrders(accountId, chainId) {
        const params = new URLSearchParams({
            networkId: chainId.toString(),
            accountId: accountId.toString(),
        });
        const url = `${this.sdk.context.apiUrl}/perpsV3/orders?${params.toString()}`;
        const response = await fetch(url);
        const res = await response.json();
        try {
            const formatted = res.data.orders.map((o) => ({
                ...o,
                orderDetails: {
                    ...o.orderDetails,
                    marketId: String(o.orderDetails.marketId),
                    sizeDelta: formatEther(BigInt(o.orderDetails.sizeDelta)),
                    acceptablePrice: formatEther(BigInt(o.orderDetails.acceptablePrice)),
                },
                decodedConditions: {
                    isPriceAbove: o.decodedConditions.isPriceAbove
                        ? formatEther(BigInt(o.decodedConditions.isPriceAbove))
                        : undefined,
                    isPriceBelow: o.decodedConditions.isPriceBelow
                        ? formatEther(BigInt(o.decodedConditions.isPriceBelow))
                        : undefined,
                },
                maxExecutorFee: formatEther(BigInt(o.maxExecutorFee)),
            }));
            return formatted;
        }
        catch (_e) {
            throw new Error(res.data.message);
        }
    }
    /**
     * @desc Create a new conditional order and store it on the DB
     * @param params - Add conditional order params
     * @returns Created conditional order object
     */
    async createConditionalOrders(params, chainId) {
        const orders = [];
        let i = 0;
        for (const order of params) {
            const nonce = (await this.getNonce(order.accountId)) + i;
            const formattedOrder = this.inputToConditionalOrder(order, nonce);
            const signedOrder = await this.signConditionalOrder(formattedOrder, chainId);
            i++;
            orders.push({
                order: formattedOrder,
                signedOrder,
            });
        }
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orders: orders.map((o) => ({
                    ...o,
                    order: serializeConditionalOrder(o.order),
                })),
            }),
        });
        const responseBody = await response.json();
        if (!responseBody.success) {
            throw new Error(responseBody.data.message);
        }
        return responseBody.data;
    }
    async updateConditionalOrders(orderUpdates, chainId) {
        const orders = orderUpdates.map(({ inputs, nonce }) => this.inputToConditionalOrder(inputs, nonce));
        const signedOrders = await Promise.all(orders.map((order) => this.signConditionalOrder(order, chainId)));
        const orderUpdatesBody = orderUpdates.map((o, i) => ({
            orderId: o.orderId,
            newOrder: serializeConditionalOrder(orders[i]),
            signedOrder: signedOrders[i],
            newStatus: o.status,
        }));
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderUpdates: orderUpdatesBody,
            }),
        });
        const data = await response.json();
        return data;
    }
    async cancelConditionalOrders(ids) {
        const signedOrders = await Promise.all(ids.map((id) => this.sdk.context.walletClient.signMessage({ message: toHex(id) })));
        const orderUpdatesBody = signedOrders.map((o, i) => ({
            orderId: ids[i],
            signedOrder: o,
            newStatus: ConditionalOrderStatusV3.Cancelled,
        }));
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderUpdates: orderUpdatesBody,
            }),
        });
        const data = await response.json();
        return data;
    }
    async removeStaleConditionalOrders(accountId, chainId) {
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders/remove-stale`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId: accountId.toString(),
                networkId: chainId,
            }),
        });
        const data = await response.json();
        if (data.success)
            return;
        throw new Error(`Failed to remove orders: ${data.data.message}`);
    }
    // Contract mutations
    async depositCreditForOrders({ accountId, amount, fromMargin = false, chainId, }) {
        const amountUSDC = parseUsdcValue(amount.toString());
        const calls = [
            {
                functionName: 'creditAccountZap',
                params: [accountId, amountUSDC],
            },
        ];
        if (fromMargin) {
            const withdrawAmountWei = parseUnits(amountUSDC.toString(), 12);
            calls.unshift({
                functionName: 'modifyCollateralZap',
                params: [accountId, -withdrawAmountWei],
            });
        }
        return this.sendErc7412Transaction({ calls, useMarginEngine: true, chainId });
    }
    async withdrawCreditForOrders(accountId, amount, chainId) {
        const engine = this.contractConfigs(chainId).MarginEngine;
        if (!engine)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...engine,
            account: this.sdk.context.walletAddress,
            functionName: 'debitAccountZap',
            args: [accountId, amount.toBigInt()],
        });
    }
    async depositCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        return this.modifyCollateral({ accountId, synthKey, amount, useMarginEngine, chainId });
    }
    async withdrawCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        const provider = chainToV3Provider(chainId);
        const synthId = V3_SYNTH_MARKET_IDS[provider]?.[synthKey];
        if (synthId === undefined)
            throw new Error(UNSUPPORTED_NETWORK);
        const calls = [
            {
                functionName: 'modifyCollateral',
                params: [accountId, synthId, amount.neg().toBigInt().toString()],
            },
        ];
        const { debt, invalidReason } = await this.validateCanPayDebt(accountId, chainId);
        if (invalidReason)
            throw new Error(invalidReason);
        if (debt > 0) {
            calls.unshift({
                functionName: 'payDebt',
                params: [accountId, debt],
            });
        }
        return this.sendErc7412Transaction({
            calls,
            useMarginEngine,
            chainId,
        });
    }
    async depositWrappedToken({ accountId, token, amountWei, useMarginEngine, chainId, }) {
        const { walletAddress } = this.sdk.context;
        const contracts = this.contractConfigs(chainId);
        const synthToken = DepositTokenToSynth[token];
        if (synthToken === SynthAssetKeysV3.USDx)
            throw new Error('Cannot wrap USDx');
        const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synthToken];
        const amountEthUnits = weiFromWei(amountWei).toString();
        const amountIn = parseUnits(amountEthUnits, token === 'USDC' ? usdcDecimals(chainId) : 18);
        if (useMarginEngine) {
            const engine = contracts.MarginEngine;
            if (!engine)
                throw new Error(UNSUPPORTED_NETWORK);
            const { request } = await this.client(chainId).simulateContract({
                ...engine,
                account: walletAddress,
                functionName: 'modifyCollateralZap',
                args: [accountId, amountIn],
            });
            return request;
        }
        const proxy = useMarginEngine ? contracts.MarginEngine : contracts.PerpsV3MarketProxy;
        if (!proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const { PerpsV3MarketProxy, SpotV3MarketProxy } = contracts;
        const synthContract = contracts[synthToken];
        const tokenContract = contracts[depositableAssetToToken(token)];
        if (!SpotV3MarketProxy ||
            !synthContract ||
            !tokenContract ||
            !spotMarket ||
            !PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const calls = [
            {
                from: walletAddress,
                to: SpotV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: SpotV3MarketProxy.abi,
                    functionName: 'wrap',
                    args: [BigInt(spotMarket), amountIn, BigInt(amountWei)],
                }),
                value: ZERO_BIG_INT,
            },
        ];
        if (token === 'ETH') {
            const wethCall = this.prepareEthToWeth(amountIn, chainId);
            calls.unshift(wethCall);
        }
        if (!chainIsMultiCollateral(chainId)) {
            calls.push({
                from: walletAddress,
                to: SpotV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: SpotV3MarketProxy.abi,
                    functionName: 'sell',
                    args: [
                        BigInt(spotMarket),
                        BigInt(amountWei),
                        BigInt(amountWei),
                        KWENTA_PERPS_V3_REFERRAL_ADDRESS,
                    ],
                }),
                value: ZERO_BIG_INT,
            });
        }
        const provider = chainToV3Provider(chainId);
        const marketToken = provider === PerpsProvider.SNX_V3_BASE ? 'USDx' : synthToken;
        const market = V3_SYNTH_MARKET_IDS[provider]?.[marketToken];
        if (!market)
            throw new Error(`${synthToken} spot market not found `);
        calls.push({
            from: walletAddress,
            to: PerpsV3MarketProxy.address,
            data: encodeFunctionData({
                abi: PerpsV3MarketProxy.abi,
                functionName: 'modifyCollateral',
                args: [accountId, BigInt(market), BigInt(amountWei)],
            }),
            value: ZERO_BIG_INT,
        });
        return this.sdk.calls.erc7412Write(calls, chainId, PREDEFINED_ORACLES);
    }
    async withdrawUnwrapped({ accountId, token, amountWei, useMarginEngine, chainId, }) {
        const proxy = this.contractConfigs(chainId).PerpsV3MarketProxy;
        if (!proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const { walletAddress } = this.sdk.context;
        const { PerpsV3MarketProxy, SpotV3MarketProxy } = this.contractConfigs(chainId);
        const synthToken = DepositTokenToSynth[token];
        const synthContract = this.contractConfigs(chainId)[synthToken];
        const tokenContract = this.contractConfigs(chainId)[depositableAssetToToken(token)];
        const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synthToken];
        if (!SpotV3MarketProxy ||
            !synthContract ||
            !tokenContract ||
            !spotMarket ||
            !PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        if (useMarginEngine) {
            return this.sendErc7412Transaction({
                calls: [
                    {
                        functionName: 'modifyCollateralZap',
                        params: [accountId, amountWei * BigInt(-1)],
                    },
                ],
                useMarginEngine: true,
                chainId,
            });
        }
        const calls = [];
        const { debt, invalidReason } = await this.validateCanPayDebt(accountId, chainId);
        if (invalidReason)
            throw new Error(invalidReason);
        if (debt > 0) {
            calls.push({
                from: walletAddress,
                to: PerpsV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: PerpsV3MarketProxy.abi,
                    functionName: 'payDebt',
                    args: [accountId, debt],
                }),
                value: ZERO_BIG_INT,
            });
        }
        const marketId = V3_WRAPPED_TOKEN_MARKETS[chainId][synthToken];
        if (!marketId)
            throw new Error(`Spot market not found for ${synthToken}`);
        calls.push({
            from: walletAddress,
            to: proxy.address,
            data: encodeFunctionData({
                abi: PerpsV3MarketProxy.abi,
                functionName: 'modifyCollateral',
                args: [accountId, BigInt(marketId), -BigInt(amountWei)],
            }),
            value: ZERO_BIG_INT,
        });
        if (token === 'USDC') {
            calls.push({
                from: walletAddress,
                to: SpotV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: SpotV3MarketProxy.abi,
                    functionName: 'buy',
                    args: [
                        BigInt(spotMarket),
                        BigInt(amountWei),
                        BigInt(amountWei),
                        KWENTA_PERPS_V3_REFERRAL_ADDRESS,
                    ],
                }),
                value: ZERO_BIG_INT,
            });
        }
        const unwrapAmount = token === 'USDC' ? parseUnits(amountWei.toString(), usdcDecimals(chainId)) : amountWei;
        calls.push({
            from: walletAddress,
            to: SpotV3MarketProxy.address,
            data: encodeFunctionData({
                abi: SpotV3MarketProxy.abi,
                functionName: 'unwrap',
                args: [BigInt(spotMarket), BigInt(amountWei), BigInt(unwrapAmount)],
            }),
            value: ZERO_BIG_INT,
        });
        const res = await this.sdk.calls.erc7412Write(calls, chainId, PREDEFINED_ORACLES);
        return res;
    }
    async submitOrder({ marketId, accountId, sizeDelta, acceptablePrice, settlementStrategyId, referrer, chainId, }, marginEngine) {
        const depositAmountUSDC = parseUsdcValue(marginEngine?.ordersGasDeposit?.toString() ?? '0');
        const params = marginEngine?.useEngine
            ? [
                marketId,
                accountId,
                sizeDelta.toBigInt(),
                settlementStrategyId,
                acceptablePrice.toBigInt(),
                KWENTA_TRACKING_CODE,
                KWENTA_PERPS_V3_REFERRAL_ADDRESS,
            ]
            : [
                {
                    marketId: marketId,
                    accountId,
                    sizeDelta: sizeDelta.toBigInt().toString(),
                    settlementStrategyId,
                    referrer: referrer ?? KWENTA_PERPS_V3_REFERRAL_ADDRESS,
                    acceptablePrice: acceptablePrice.toBigInt().toString(),
                    trackingCode: KWENTA_TRACKING_CODE,
                },
            ];
        const calls = [];
        if (Number(depositAmountUSDC) > 0) {
            const walletBalances = await this.sdk.tokens.getBalancesAndAllowances(['USDC'], this.sdk.context.walletAddress, [], chainId);
            const walletBalance = walletBalances.USDC?.balance;
            if (wei(walletBalance ?? 0).lt(marginEngine?.ordersGasDeposit)) {
                // If the wallet balance is less than the deposit amount
                // we can withdraw it from collateral
                const withdrawAmountWei = parseUnits(depositAmountUSDC.toString(), 12);
                calls.push({
                    functionName: 'modifyCollateralZap',
                    params: [accountId, -withdrawAmountWei],
                });
            }
            calls.push({
                functionName: 'creditAccountZap',
                params: [accountId, depositAmountUSDC],
            });
        }
        calls.push({
            functionName: 'commitOrder',
            params,
        });
        return this.sendErc7412Transaction({
            calls,
            useMarginEngine: marginEngine?.useEngine,
            chainId,
        });
    }
    async executeAsyncOrder(accountId, chainId) {
        const { walletAddress } = this.sdk.context;
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const tx = {
            to: PerpsV3MarketProxy.address,
            from: walletAddress,
            data: encodeFunctionData({
                abi: PerpsMarketV3Abi,
                functionName: 'settleOrder',
                args: [accountId],
            }),
            value: ZERO_BIG_INT,
        };
        return await this.sdk.calls.erc7412Write([tx], chainId, PREDEFINED_ORACLES);
    }
    async createAccount(chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'createAccount',
            args: [],
        });
    }
    async grantMarginEnginePermission(accountId, chainId) {
        const { PerpsV3MarketProxy, MarginEngine } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy || !MarginEngine)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'grantPermission',
            args: [accountId, stringToHex('ADMIN', { size: 32 }), MarginEngine.address],
        });
    }
    async grantDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'grantPermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
    }
    async revokeDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'revokePermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
    }
    async checkDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const result = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'hasPermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
        return result;
    }
    async getDelegatesForAccount(walletAddress, chainId) {
        return await queryDelegatesForAccount(chainId, walletAddress);
    }
    async getSubAccountsForAccount(walletAddress, chainId) {
        return await querySubAccountsForAccount(chainId, walletAddress);
    }
    // private helpers
    inputToConditionalOrder(input, nonce) {
        const orderDetails = {
            marketId: input.marketId,
            networkId: input.networkId,
            accountId: input.accountId.toString(),
            sizeDelta: input.sizeDelta,
            settlementStrategyId: input.settlementStrategyId,
            acceptablePrice: input.acceptablePrice,
            isReduceOnly: input.isReduceOnly,
            trackingCode: KWENTA_TRACKING_CODE,
            referrer: KWENTA_PERPS_V3_REFERRAL_ADDRESS,
        };
        const conditions = orderConditionsToCallData(input, input.rawConditions);
        const trustedExecutor = ORDERS_KEEPER_ADDRESSES[Number(input.networkId)];
        return {
            orderDetails,
            signer: this.sdk.context.walletAddress,
            nonce: nonce,
            requireVerified: false,
            trustedExecutor,
            maxExecutorFee: input.maxExecutorFee,
            conditions: conditions,
        };
    }
    async signConditionalOrder(conditionalOrder, chainId) {
        const { MarginEngine } = this.contractConfigs(chainId);
        if (!MarginEngine) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        await this.sdk.context.walletClient.switchChain({ id: chainId });
        const signature = (await this.sdk.transactions.signTypedData({
            account: this.sdk.context.walletAddress,
            domain: {
                name: 'SMv3: OrderBook',
                version: '1',
                chainId,
                verifyingContract: MarginEngine.address,
            },
            primaryType: 'ConditionalOrder',
            types: {
                OrderDetails: [
                    { name: 'marketId', type: 'uint128' },
                    { name: 'accountId', type: 'uint128' },
                    { name: 'sizeDelta', type: 'int128' },
                    { name: 'settlementStrategyId', type: 'uint128' },
                    { name: 'acceptablePrice', type: 'uint256' },
                    { name: 'isReduceOnly', type: 'bool' },
                    { name: 'trackingCode', type: 'bytes32' },
                    { name: 'referrer', type: 'address' },
                ],
                ConditionalOrder: [
                    { name: 'orderDetails', type: 'OrderDetails' },
                    { name: 'signer', type: 'address' },
                    { name: 'nonce', type: 'uint256' },
                    { name: 'requireVerified', type: 'bool' },
                    { name: 'trustedExecutor', type: 'address' },
                    { name: 'maxExecutorFee', type: 'uint256' },
                    { name: 'conditions', type: 'bytes[]' },
                ],
            },
            message: this.formatOrderForSigning(conditionalOrder),
        }, chainId));
        return signature;
    }
    formatOrderForSigning(order) {
        return {
            orderDetails: {
                marketId: BigInt(order.orderDetails.marketId),
                accountId: BigInt(order.orderDetails.accountId),
                sizeDelta: order.orderDetails.sizeDelta,
                settlementStrategyId: BigInt(order.orderDetails.settlementStrategyId),
                acceptablePrice: order.orderDetails.acceptablePrice,
                isReduceOnly: order.orderDetails.isReduceOnly,
                trackingCode: order.orderDetails.trackingCode,
                referrer: order.orderDetails.referrer,
            },
            signer: order.signer,
            nonce: BigInt(order.nonce),
            requireVerified: order.requireVerified,
            trustedExecutor: order.trustedExecutor,
            maxExecutorFee: order.maxExecutorFee,
            conditions: order.conditions,
        };
    }
    async getNonce(accountId) {
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/account/${accountId.toString()}/nonce`);
        const { data } = await response.json();
        return data.nonce;
    }
    modifyCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        const synthId = V3_WRAPPED_TOKEN_MARKETS[chainId][synthKey];
        const proxy = useMarginEngine
            ? this.contractConfigs(chainId).MarginEngine
            : this.contractConfigs(chainId).PerpsV3MarketProxy;
        if (synthId === undefined)
            throw new Error(`No contract found for Synth ${synthKey}`);
        if (!proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return this.sendErc7412Transaction({
            calls: [
                {
                    functionName: 'modifyCollateral',
                    params: [accountId, synthId, amount.toBigInt().toString()],
                },
            ],
            useMarginEngine,
            chainId,
        });
    }
    async getCollateralTransfers(chainId, accountId) {
        const response = await queryCollateralChanges(chainId, accountId?.toString());
        return response ? mapCollateralChanges(response.collateralChanges, chainId) : [];
    }
    async getOrderHistory(accountId, chainId, options) {
        const conditionalOrders = await this.getConditionalOrders(accountId, chainId);
        const marketOrdersResponse = await queryOrderSettleds(chainId, accountId?.toString(), options);
        return reconcileOrders(marketOrdersResponse.orderSettleds ?? [], conditionalOrders);
    }
    async getMaxDepositAmounts(tokens, chainId) {
        const items = tokens
            .map((t) => {
            const synth = DepositTokenToSynth[t];
            const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synth];
            const tokenContract = this.contractConfigs(chainId)[depositableAssetToToken(t)];
            if (synth && tokenContract && spotMarket)
                return {
                    synth,
                    token: t,
                    spotMarket,
                    tokenContract,
                };
            return null;
        })
            .filter(notNill);
        const { SpotV3MarketProxy, CoreProxy } = this.contractConfigs(chainId);
        if (!SpotV3MarketProxy || !CoreProxy || !items.length)
            throw new Error(UNSUPPORTED_NETWORK);
        const wrapperCalls = items.map((item) => ({
            ...SpotV3MarketProxy,
            functionName: 'getWrapper',
            args: [BigInt(item.spotMarket)],
        }));
        const collateralCalls = items.map((item) => ({
            ...CoreProxy,
            functionName: 'getMarketCollateralAmount',
            args: [BigInt(item.spotMarket), item.tokenContract?.address],
        }));
        const results = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: [...wrapperCalls, ...collateralCalls],
        });
        const wrapperResults = results.slice(0, items.length);
        const collateralResults = results.slice(items.length);
        const maxWrappableAmounts = wrapperResults.reduce((acc, r, i) => {
            // @ts-ignore inferred incorrectly
            const maxWrappableAmount = r[1] ?? 0;
            const marketCollateralAmount = collateralResults[i] ?? 0;
            acc[items[i].token] = wei(maxWrappableAmount).sub(wei(marketCollateralAmount)).toString();
            return acc;
        }, {});
        return maxWrappableAmounts;
    }
    prepareEthToWeth(amountWei, chainId) {
        return {
            from: this.sdk.context.walletAddress,
            to: COMMON_ADDRESSES.WETH[chainId],
            data: encodeFunctionData({
                abi: WethAbi,
                functionName: 'depositTo',
                args: [this.sdk.context.walletAddress],
            }),
            value: amountWei,
        };
    }
    async validateCanPayDebt(accountId, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!chainToV3Provider(chainId))
            return { debt: BigInt(0) };
        const debt = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'debt',
            args: [accountId],
        });
        const walletBalances = await this.sdk.tokens.getBalancesAndAllowances([SynthAssetKeysV3.USDx], this.sdk.context.walletAddress, [PerpsV3MarketProxy.address], chainId);
        const allowance = walletBalances[SynthAssetKeysV3.USDx]?.allowances[PerpsV3MarketProxy.address];
        const balance = walletBalances[SynthAssetKeysV3.USDx]?.balance;
        if (wei(balance ?? 0).lt(weiFromWei(debt)))
            return { debt: debt, invalidReason: 'Insufficient sUSD balance to pay debt' };
        if (wei(allowance ?? 0).lt(weiFromWei(debt)))
            return { debt: debt, invalidReason: 'Insufficient sUSD allowance for debt' };
        return { debt };
    }
}

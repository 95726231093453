import type { UserOpResponse } from '@biconomy/account'
import { type NetworkId, TransactionStatus } from '@kwenta/sdk/types'
import { toast } from 'react-toastify'
import type { Hex } from 'viem'

import { NotificationError } from 'components/TransactionNotification'
import sdk from 'state/sdk'
import type { AppDispatch } from 'state/store'

import { updateTransactionHash, updateTransactionOrder, updateTransactionStatus } from './reducer'

export const monitorAndAwaitTransaction = async (
	chainId: NetworkId,
	dispatch: AppDispatch,
	txHash: Hex
) => {
	dispatch(updateTransactionHash(txHash))
	const client = sdk.context.clients[chainId]
	if (!client) throw new Error(`No client found for chain id: ${chainId}`)
	await client.waitForTransactionReceipt({
		confirmations: 1,
		hash: txHash,
	})

	dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
}

export const monitorAndAwaitUserOp = async (dispatch: AppDispatch, response: UserOpResponse) => {
	const { transactionHash } = await response.waitForTxHash()
	dispatch(updateTransactionHash(transactionHash as Hex))
	const result = await response.wait()

	if (result.success === 'false') {
		dispatch(updateTransactionStatus(TransactionStatus.Failed))
		dispatch(updateTransactionOrder(undefined))
	} else {
		dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
	}
}

export const monitorFollowingTransaction = async (
	originTxHash: Hex,
	txHash: Hex,
	network: NetworkId
) => {
	const emitter = sdk.transactions.hash(txHash, network)
	emitter.on('txFailed', ({ failureReason }) => {
		toast.update(originTxHash, {
			containerId: 'notifications',
			render: <NotificationError failureReason={failureReason} />,
			hideProgressBar: false,
			progressClassName: 'failProgress',
			autoClose: 10000,
		})
	})
}

import type { WalletClientType } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

import { fetchBalancesAndAllowances } from 'state/balances/actions'
import {
	fetchDelegatesForAccount,
	fetchPerpsAccounts,
	fetchSubAccountsForAccount,
} from 'state/futures/actions'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { fetchPoints } from 'state/points/actions'
import {
	fetchClaimableRewards,
	fetchClaimableRewardsParams,
	fetchEscrowData,
	fetchMigrationDetails,
	fetchStakingData,
} from 'state/staking/actions'
import { setSelectedUserMigrationInfo } from 'state/stakingMigration/reducer'
import type { ThunkConfig } from 'state/types'

import { disconnect, setWalletAddress } from './reducer'
import type { SetWalletPayload } from './types'

export const resetWalletAddress = createAsyncThunk<void, SetWalletPayload, ThunkConfig>(
	'wallet/resetWalletAddress',
	async ({ address, selectedType }, { dispatch, getState }) => {
		const perpsProvider = selectPerpsProvider(getState())
		dispatch(setWalletAddress({ address, selectedType }))
		if (selectedType === 'signer') {
			dispatch(setSelectedUserMigrationInfo(address))
			dispatch(fetchPerpsAccounts({ providers: [perpsProvider] }))
			dispatch(fetchSubAccountsForAccount([perpsProvider]))
			dispatch(fetchDelegatesForAccount([perpsProvider]))
			dispatch(fetchBalancesAndAllowances())
			dispatch(fetchClaimableRewards())
			dispatch(fetchClaimableRewardsParams())
			dispatch(fetchStakingData())
			dispatch(fetchEscrowData())
			dispatch(fetchMigrationDetails())
			dispatch(fetchPoints())
		}
		Sentry.setUser({ id: address })
	}
)

export const setWalletClient = createAsyncThunk<
	void,
	WalletClientType | null | undefined,
	ThunkConfig
>('wallet/setWalletClient', async (walletClient, { dispatch, extra: { sdk } }) => {
	if (walletClient) {
		await sdk.setWalletClient(walletClient)
	} else {
		dispatch(disconnect())
		dispatch({ type: 'balances/clearBalances' })
	}
})

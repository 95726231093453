import { type FuturesTrade, type PerennialFuturesMarket, PerpsProvider } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import type { Address } from 'viem'

import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'
import { unserializePerennialMarkets, unserializeTrades } from 'utils/futures'

import { selectPerennialV2Network } from '../common/selectors'
import type { MarkPrices } from '../common/types'

export const selectPerennialAccount = createSelector(
	selectWallet,
	(state: RootState) => state.futures,
	(wallet, futures) => {
		return wallet
			? (futures.accounts[PerpsProvider.PERENNIAL_V2_ARB]?.[wallet]?.account as Address)
			: undefined
	}
)

export const selectPerennialAccountContext = createSelector(
	selectWallet,
	selectPerennialV2Network,
	selectPerennialAccount,
	(wallet, network, accountId) => {
		return {
			wallet,
			network,
			accountId,
			provider: PerpsProvider.PERENNIAL_V2_ARB,
			isIsolatedMargin: true,
		}
	}
)

export const selectPerennialAccountData = createSelector(
	selectWallet,
	(state: RootState) => state.futures.accounts,
	(wallet, accounts) => {
		return wallet ? accounts?.[PerpsProvider.PERENNIAL_V2_ARB]?.[wallet] : undefined
	}
)

export const selectPerennialMarkets = createSelector(
	(state: RootState) => state.futures.providerData.markets,
	(markets) => {
		return unserializePerennialMarkets(markets[PerpsProvider.PERENNIAL_V2_ARB] ?? [])
	}
)

type PerennialTrade = FuturesTrade & {
	market: PerennialFuturesMarket
}

export const selectPerennialAccountTrades = createSelector(
	selectPerennialAccountData,
	selectPerennialMarkets,
	(account, markets) => {
		const trades = unserializeTrades(account?.trades ?? [])
		return trades.reduce<PerennialTrade[]>((acc, t) => {
			const market = markets.find((m) => m.asset.toLowerCase() === t.asset.toLowerCase())
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectPerennialMarkPrices = createSelector(
	selectPerennialMarkets,
	selectPrices,
	(perennialMarkets, prices) => {
		const markPrices: MarkPrices = {}
		return perennialMarkets.reduce((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			acc[market.asset] = price
			return acc
		}, markPrices)
	}
)

export const selectTotalFuturesFeesArb = (state: RootState) =>
	state.futures.providerData.totalFeesPaid[PerpsProvider.PERENNIAL_V2_ARB] ?? '0'

export const selectFuturesFeesForAccountArb = createSelector(
	selectPerennialAccountData,
	(account) => {
		if (!account) return wei(0)
		return wei(account.totalFeesPaid ?? 0)
	}
)

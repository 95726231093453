import { wei } from '@kwenta/wei';
import request, { gql } from 'graphql-request';
import { ZERO_WEI } from '../constants';
import { REFERRALS_ENDPOINTS } from '../constants/referrals';
import { queryCodesByReferrer, queryTradersByCode } from '../queries/referrals';
import { calculateFeesForAccount, getPerpsV2SubgraphUrl } from './futures';
const calculateTraderVolume = (futuresTrades) => {
    return futuresTrades.reduce((acc, trade) => {
        return acc + (Math.abs(+trade.size) / 1e18) * (+trade.price / 1e18);
    }, 0);
};
export const getReferralsGqlEndpoint = (networkId) => {
    return REFERRALS_ENDPOINTS[networkId] || REFERRALS_ENDPOINTS[10];
};
const getCumulativeStatsByCode = async (sdk, account, chainId, epochStart, epochEnd, epochPeriod) => {
    const codes = await queryCodesByReferrer(sdk, account, chainId);
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    return Promise.all(codes.map(async (code) => {
        const traders = await queryTradersByCode(sdk, code, chainId, epochStart, epochEnd);
        const totalRewards = epochPeriod
            ? await sdk.kwentaToken.getKwentaRewardsByTraders(epochPeriod, traders.map(({ id }) => id))
            : ZERO_WEI;
        const traderVolumeQueries = await Promise.all(traders.map(({ id, lastMintedAt }) => {
            const start = epochStart ? Math.max(Number(lastMintedAt), epochStart) : lastMintedAt;
            const end = epochEnd ? Math.min(currentTimeInSeconds, epochEnd) : currentTimeInSeconds;
            return gql `
					user_${id}: futuresTrades(
						first: 1000
						where: {
							timestamp_gt: ${start}
							timestamp_lte: ${end}
							trackingCode: "0x4b57454e54410000000000000000000000000000000000000000000000000000"
							account: "${id}"
						}
						orderBy: timestamp
						orderDirection: asc
					) {
						timestamp
						account
						size
						price
					}
					`;
        }));
        if (traderVolumeQueries.length > 0) {
            const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
						query totalFuturesTrades {
							${traderVolumeQueries.join('')}
						}
					`);
            const totalTrades = response ? Object.values(response).flat() : [];
            const totalVolume = calculateTraderVolume(totalTrades);
            return {
                code,
                referredCount: traders.length.toString(),
                referralVolume: totalVolume.toString(),
                earnedRewards: totalRewards.toString(),
                tradesCount: totalTrades.length.toString(),
            };
        }
        else {
            return {
                code,
                referredCount: '0',
                referralVolume: '0',
                earnedRewards: totalRewards.toString(),
                tradesCount: '0',
            };
        }
    }));
};
export const getReferralStatisticsByAccount = (sdk, account, chainId) => {
    return getCumulativeStatsByCode(sdk, account, chainId);
};
export const getReferralStatisticsByAccountAndEpochTime = (sdk, account, epochStart, epochEnd, chainId) => {
    return getCumulativeStatsByCode(sdk, account, chainId, epochStart, epochEnd);
};
export const getReferralStatisticsByAccountAndEpoch = (sdk, account, chainId, epochPeriod) => {
    return getCumulativeStatsByCode(sdk, account, chainId, undefined, undefined, epochPeriod);
};
export const getReferralFeesPaid = async (sdk, account, epochStart, epochEnd, chainId) => {
    const codes = await queryCodesByReferrer(sdk, account, chainId);
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const fees = await Promise.all(codes.map(async (code) => {
        const traders = await queryTradersByCode(sdk, code, chainId);
        const traderFeePaidQueries = await Promise.all(traders.map(({ id, lastMintedAt }) => {
            const start = epochStart ? Math.max(Number(lastMintedAt), epochStart) : lastMintedAt;
            const end = epochEnd ? Math.min(currentTimeInSeconds, epochEnd) : currentTimeInSeconds;
            return gql `
					user_${id}: futuresTrades(
						first: 1000
						where: {
							timestamp_gt: ${start}
							timestamp_lte: ${end}
							trackingCode: "0x4b57454e54410000000000000000000000000000000000000000000000000000"
							account: "${id}"
						}
						orderBy: timestamp
						orderDirection: asc
					) {
						timestamp
						account
						feesPaid
						keeperFeesPaid
					}
					`;
        }));
        if (traderFeePaidQueries.length > 0) {
            const response = await request(getPerpsV2SubgraphUrl(chainId), gql `
						query totalFuturesTrades {
							${traderFeePaidQueries.join('')}
						}
					`);
            const totalTrades = response
                ? Object.values(response)
                    .flat()
                    .map((trade) => {
                    return {
                        feesPaid: wei(trade.feesPaid, 0),
                        keeperFeesPaid: wei(trade.keeperFeesPaid, 0),
                    };
                })
                : [];
            const totalFeesPaid = calculateFeesForAccount(totalTrades);
            return {
                code,
                feesPaid: totalFeesPaid,
            };
        }
        else {
            return {
                code,
                feesPaid: ZERO_WEI,
            };
        }
    }));
    return fees.reduce((acc, { feesPaid }) => acc.add(feesPaid), ZERO_WEI);
};

import { optimism } from 'viem/chains';
import { VESTING_ENTRY_PAGE_SIZE, ZERO_BIG_INT } from '../constants';
import * as sdkErrors from '../constants/errors';
import { kwentaContractsByNetwork } from '../contracts';
import { SnxV2NetworkIds } from '../types';
export default class stakingMigrationService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    contractConfigs(chainId) {
        return kwentaContractsByNetwork(chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET);
    }
    client(chainId) {
        const client = this.sdk.context.clients[chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET];
        if (client)
            return client;
        throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
    }
    switchToOptimismAndWriteContact(params) {
        return this.sdk.transactions.writeContract(params, optimism.id);
    }
    registerEntries(ids) {
        const { EscrowMigrator } = this.contractConfigs(this.sdk.context.walletClient.chain.id);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...EscrowMigrator,
            functionName: 'registerEntries',
            args: [ids],
        });
    }
    async approveKwentaTokenEscrowMigrator(user, chainId = 10) {
        const { KwentaToken, EscrowMigrator } = this.contractConfigs(chainId);
        if (!KwentaToken || !EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        // const amount = await EscrowMigrator.toPay(user)
        const amount = await this.client(chainId).readContract({
            ...EscrowMigrator,
            functionName: 'toPay',
            args: [user],
        });
        return this.switchToOptimismAndWriteContact({
            ...KwentaToken,
            functionName: 'approve',
            args: [EscrowMigrator.address, amount],
        });
    }
    migrateEntries(entryIDs, chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return this.switchToOptimismAndWriteContact({
            ...EscrowMigrator,
            functionName: 'migrateEntries',
            args: [walletAddress, entryIDs],
        });
    }
    getRegisteredVestingEntryIDs(chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return this.client(chainId).readContract({
            ...EscrowMigrator,
            functionName: 'getRegisteredVestingEntryIDs',
            args: [walletAddress, ZERO_BIG_INT, VESTING_ENTRY_PAGE_SIZE],
        });
    }
    getRegisteredVestingSchedules(chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return this.client(chainId).readContract({
            ...EscrowMigrator,
            functionName: 'getRegisteredVestingSchedules',
            args: [walletAddress, ZERO_BIG_INT, VESTING_ENTRY_PAGE_SIZE],
        });
    }
    async getVestingEntryIDs(chainId = 10) {
        const { RewardEscrow } = this.contractConfigs(chainId);
        if (!RewardEscrow) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const schedules = await this.client(chainId).readContract({
            ...RewardEscrow,
            functionName: 'getVestingSchedules',
            args: [walletAddress, ZERO_BIG_INT, VESTING_ENTRY_PAGE_SIZE],
        });
        return schedules
            .filter((schedule) => schedule.escrowAmount > ZERO_BIG_INT)
            .map((schedule) => Number(schedule.entryID));
    }
    getEscrowMigratorAllowance(chainId = 10) {
        const { KwentaToken, EscrowMigrator } = this.contractConfigs(chainId);
        if (!KwentaToken || !EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        // return KwentaToken.allowance(walletAddress, EscrowMigrator.address)
        return this.client(chainId).readContract({
            ...KwentaToken,
            functionName: 'allowance',
            args: [walletAddress, EscrowMigrator.address],
        });
    }
    getToPayByUser(chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        // return EscrowMigrator.toPay(walletAddress)
        return this.client(chainId).readContract({
            ...EscrowMigrator,
            functionName: 'toPay',
            args: [walletAddress],
        });
    }
    async getMigrationDeadline(chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const [start, duration] = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: [
                {
                    ...EscrowMigrator,
                    functionName: 'initializationTime',
                    args: [walletAddress],
                },
                {
                    ...EscrowMigrator,
                    functionName: 'MIGRATION_DEADLINE',
                },
            ],
        });
        return {
            start: Number(start),
            end: Number(start + duration),
        };
    }
    getTotalEscrowUnmigrated(chainId = 10) {
        const { EscrowMigrator } = this.contractConfigs(chainId);
        if (!EscrowMigrator) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        return this.client(chainId).readContract({
            ...EscrowMigrator,
            functionName: 'totalEscrowUnmigrated',
            args: [walletAddress],
        });
    }
}

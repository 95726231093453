import { optimism, optimismSepolia } from 'viem/chains';
export const PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3';
export const SNX_BFP_PERPS_ADDRESSES = {
    PerpAccountProxy: {
        11155111: '0xba5F6C36b3f82f823E860a9aB7fe41562D50b99a',
    },
    MarketProxy: {
        11155111: '0xEf654C46c8f33c7F6b0db04BF64DFd5f228c35d4',
    },
};
export const SNX_V3_PERPS_ADDRESSES = {
    PerpsV3MarketProxy: {
        84532: '0xf53Ca60F031FAf0E347D44FbaA4870da68250c8d',
        8453: '0x0A2AF931eFFd34b81ebcc57E3d3c9B1E1dE1C9Ce',
        421614: '0xA73A7B754Ec870b3738D0654cA75b7d0eEbdb460',
        42161: '', // TODO: Update with mainnet
    },
    PerpsV3AccountProxy: {
        84532: '0xc8eE218577545D5b38443C0eB5d7B9E4140085F2',
        8453: '0xcb68b813210aFa0373F076239Ad4803f8809e8cf',
        421614: '0xf3D4109EB4e7EC31f8Eee5D9ADDAD5F3C53a6C87',
        42161: '', // TODO: Update with mainnet
    },
    SpotV3MarketProxy: {
        84532: '0xaD2fE7cd224c58871f541DAE01202F93928FEF72',
        8453: '0x18141523403e2595D31b22604AcB8Fc06a4CaA61',
        421614: '0x93d645c42A0CA3e08E9552367B8c454765fff041',
        42161: '', // TODO: Update with mainnet
    },
    SynthetixV3Proxy: {
        84532: '0xD4E93f8a0aBc321ECC5b4bFBb501cb968e121F21',
        8453: '0x3d07CBC5Cb9376A67E76C0655Fe239dDa8E2B264',
        421614: '0x12aE0D5CD26f212bFE242DA78139d463019f7a73',
        42161: '', // TODO: Update with mainnet
    },
    CoreProxy: {
        84532: '0xF4Df9Dd327Fd30695d478c3c8a2fffAddcdD0d31',
        8453: '0x32C222A9A159782aFD7529c87FA34b96CA72C696',
        421614: '0x76490713314fCEC173f44e99346F54c6e92a8E42',
        42161: '', // TODO: Update with mainnet
    },
    SnxV3TrustedMulticallForwarder: {
        84532: '0xE2C5658cC5C448B48141168f3e475dF8f65A1e3e',
        8453: '0xE2C5658cC5C448B48141168f3e475dF8f65A1e3e',
        421614: '0xE2C5658cC5C448B48141168f3e475dF8f65A1e3e',
        42161: '0xE2C5658cC5C448B48141168f3e475dF8f65A1e3e',
    },
    MarginEngine: {
        84532: '0xa66d7aCFCeAA376d76B416498192d4AFe4ded4C3',
        8453: '0xe331a7eeC851Ba702aA8BF43070a178451d6D28E',
        421614: '0xA2dF816B2C5D8d799069d6a8a9f8464D402b5D25',
        42161: '', // TODO: Update with mainnet
    },
};
export const SNX_V2_PERPS_ADDRESSES = {
    PerpsV2MarketData: {
        10: '0x340B5d664834113735730Ad4aFb3760219Ad9112',
        11155420: '0x2D19525051C491CD6F48e22a11E7e484aC172FCE',
    },
    PerpsV2MarketSettings: {
        10: '0x649F44CAC3276557D03223Dbf6395Af65b11c11c',
        11155420: '0x34ffA8af41B1B3077e7b40cC19B6906c3125Cd0c',
    },
    SmartMarginAccountFactory: {
        10: '0x8234F990b149Ae59416dc260305E565e5DAfEb54',
        11155420: '0xF877315CfC91E69e7f4c308ec312cf91D66a095F',
    },
};
export const KWENTA_ADDRESSES = {
    KwentaArrakisVault: {
        10: '0x56dEa47c40877c2aaC2a689aC56aa56cAE4938d2',
    },
    StakingRewards: {
        10: '0x6077987e8e06c062094c33177Eb12c4A65f90B65',
    },
    KwentaToken: {
        10: '0x920Cf626a271321C151D027030D5d08aF699456b',
    },
    KwentaStakingRewards: {
        10: '0x6e56A5D49F775BA08041e28030bc7826b13489e0',
    },
    KwentaStakingRewardsV2: {
        10: '0x61294940ce7cd1bda10e349adc5b538b722ceb88',
    },
    RewardEscrow: {
        10: '0x1066A8eB3d90Af0Ad3F89839b974658577e75BE2',
    },
    RewardEscrowV2: {
        10: '0xb2a20fCdc506a685122847b21E34536359E94C56',
    },
    SupplySchedule: {
        10: '0x3e8b82326Ff5f2f10da8CEa117bD44343ccb9c26',
    },
    vKwentaToken: {
        10: '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
    },
    veKwentaToken: {
        10: '0x678d8f4ba8dfe6bad51796351824dcceceaeff2b',
    },
    vKwentaRedeemer: {
        10: '0x8132EE584bCD6f8Eb1bea141DB7a7AC1E72917b9',
    },
    veKwentaRedeemer: {
        10: '0xc7088AC8F287539567e458C7D08C2a1470Fd25B7',
    },
    TradingRewards: {
        10: '0xf486A72E8c8143ACd9F65A104A16990fDb38be14',
    },
    TradingRewardsPerpsV2: {
        10: '0x2787CC20e5ECb4BF1bfB79eAE284201027683179',
    },
    TradingRewardsStakingV2: {
        10: '0x195f6F7ca2268e1DEC03352786C350Eb61aBa307',
    },
    OpRewards: {
        10: '0x1964cF9d0c5c268DcF5a5d37F13600483237f6F5',
    },
    SnxOpRewards: {
        10: '0xD301BCC5D3229A0a6400F1030c382A5d503931E1',
    },
    OpReferralRewards: {
        10: '0x2712DeDE1d4Aac904b91D4a2426281A274971b7c',
    },
    ArbRewards: {
        42161: '0x5733Ef72c134E7A276029CB4ba07Bff8b1163086',
    },
    BatchClaimer: {
        10: '0x6Fd879830D9b1EE5d4f9ef12f8D5deE916bebD0b',
    },
    EscrowMigrator: {
        10: '0xC9aF789Ae606F69cF8Ed073A04eC92f2354b027d',
    },
    BoostNft: {
        10: '0xD3B8876073949D790AB718CAD21d9326a3adA60f',
    },
};
export const COMMON_ADDRESSES = {
    SystemStatus: {
        1: '0x696c905F8F8c006cA46e9808fE7e00049507798F',
        10: '0xE8c41bE1A167314ABAF2423b72Bf8da826943FFD',
        11155420: '0x247814db4369f8E952F60A6409C16A928672dcc6',
    },
    SynthUtil: {
        1: '0x81Aee4EA48f678E172640fB5813cf7A96AFaF6C3',
        10: '0x87b1481c82913301Fc6c884Ac266a7c430F92cFA',
        11155420: '0xb13A82B995f2Df8c4D9f19be25701b49351f4fFF',
    },
    Pyth: {
        10: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        11155420: '0x0708325268dF9F66270F1401206434524814508b',
        84532: '0xA2aa501b19aff244D90cc15a4Cf739D2725B5729',
    },
    sUSDC: {
        84532: '0x8069c44244e72443722cfb22DcE5492cba239d39',
        8453: '0xC74eA762cF06c9151cE074E6a569a5945b6302E7',
        421614: '0xB3A0E9e0699b82b1F33A03fFf38e72513c2Dc933', // TODO: Set this address
    },
    USDx: {
        84532: '0x682f0d17feDC62b2a0B91f8992243Bf44cAfeaaE',
        8453: '0x09d51516F38980035153a554c26Df3C6f51a23C3',
        42161: '0xb2F30A7C980f052f02563fb518dcc39e6bf38175',
        421614: '0xe487Ad4291019b33e2230F8E2FB1fb6490325260',
    },
    sETH: {
        421614: '0x711d4F22CC39223a7273E45537c9d371E66A8a40',
    },
    sBTC: {
        421614: '0x726cFb7f07231D8dBfE993c3A8b09D933CaeE78b',
    },
    SUSD: {
        1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
        10: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        11155420: '0xD7D674d80e79CF3A3b67D6a510AC1B0493dF47cF',
    },
    USDC: {
        1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        10: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
        84532: '0xc43708f8987Df3f3681801e5e640667D86Ce3C30', // Synthetix mock
        8453: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        42161: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        421614: '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d',
    },
    USDT: {
        1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        10: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
    },
    DAI: {
        1: '0x6b175474e89094c44da98b954eedeac495271d0f',
        10: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        421614: '0xda7b438d762110083602AbC497b1Ec8Bc6605eC9',
    },
    WETH: {
        42161: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        421614: '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
        10: '0x4200000000000000000000000000000000000006',
        8453: '0x4200000000000000000000000000000000000006',
        84531: '0x4200000000000000000000000000000000000006',
    },
    tBTC: {
        421614: '0xd45eACF062F6C7c780EF7953c23E112041f0bd1B',
        42161: '0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40',
    },
    LUSD: {
        10: '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
    },
    Synthetix: {
        1: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
        10: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
        11155420: '0xB409b21634F356cb3372F077920ECDd4017AbFc6',
    },
    DappMaintenance: {
        10: '0x54581A23F62D147AC76d454f0b3eF77F9D766058',
        11155420: '0x0D7EC97023D648e9FEeb90510A64D5957AfFe6e8',
    },
    Multicall3_1: {
        10: optimism.contracts.multicall3.address,
        84531: '0xca11bde05977b3631167028862be2a173976ca11',
        11155420: optimismSepolia.contracts.multicall3.address,
    },
    Permit2: {
        1: PERMIT2_ADDRESS,
        10: PERMIT2_ADDRESS,
    },
    GnosisSafeL2: {
        10: '0x6cd3f878852769e04A723A5f66CA7DD4d9E38A6C',
    },
};
export const VIP = {
    FEE_REIMBURSEMENT_CLAIM_ADDRESSES: {
        10: '0x4B1074ce02DD5Da88b86d0a2Dab2C673Ef879376',
        11155420: '',
    },
    FEE_REIMBURSEMENT_APP_ADDRESSES: {
        10: '0xa7CC9DfDbe44069677c5EcFFF732930B17b1364E',
        11155420: '',
    },
};

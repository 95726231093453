import { PointsLevel, PointsRank, } from '../types';
export default class PointsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async getPoints(account) {
        const params = new URLSearchParams({
            user: account.toLowerCase(),
        });
        const url = `${this.sdk.context.apiUrl}/exp/points?${params.toString()}`;
        const response = await fetch(url);
        const res = await response.json();
        return res.total
            ? res
            : {
                total: 0,
                placement: 0,
                user: { address: '', level: PointsLevel.NPC_I, rank: PointsRank.NPC },
            };
    }
    async getLeaderboard() {
        const points = [];
        let total = 0;
        let queryResponseCount = 0;
        const PAGE_SIZE = 10000;
        do {
            const params = new URLSearchParams({
                offset: points.length.toString(),
                count: PAGE_SIZE.toString(),
            });
            const url = `${this.sdk.context.apiUrl}/exp/points-leaderboard?${params.toString()}`;
            const response = await fetch(url);
            const res = await response.json();
            total = res.total;
            queryResponseCount = res.points.length;
            points.push(...res.points.map((point, index) => ({
                address: point.user.address,
                ens: point.user.ens?.name,
                points: point.total,
                rankNumber: points.length + index + 1,
                rank: point.user.rank,
                level: point.user.level,
            })));
        } while (queryResponseCount === PAGE_SIZE);
        return {
            total,
            points,
        };
    }
    async getGlobalActivities() {
        const url = `${this.sdk.context.apiUrl}/exp/global-activity`;
        const response = await fetch(url);
        return await response.json();
    }
    async getUserActivities(user, count = 1000, offset = 0) {
        const url = `${this.sdk.context.apiUrl}/exp/user-activity?user=${user.toLowerCase()}&count=${count}&offset=${offset}`;
        const response = await fetch(url);
        return await response.json();
    }
    async getSeasons(account) {
        const response = await fetch(`${this.sdk.context.apiUrl}/exp/seasons`);
        const { seasons } = (await response.json());
        if (!account)
            return seasons.map((season) => ({ ...season, points: 0, level: PointsLevel.NPC_I }));
        const params = new URLSearchParams({
            user: account.toLowerCase(),
        });
        const userSeasonsResponse = await fetch(`${this.sdk.context.apiUrl}/exp/user-history?${params.toString()}`);
        const userSeasonsData = (await userSeasonsResponse.json()).seasons;
        return seasons.map((season) => {
            const userSeason = userSeasonsData.find((s) => s.season === season.name);
            return {
                ...season,
                points: userSeason?.points.total ?? 0,
                level: userSeason?.points.level ?? PointsLevel.NPC_I,
            };
        });
    }
    async getPointsLevel() {
        const url = `${this.sdk.context.apiUrl}/exp/levels`;
        const response = await fetch(url);
        const res = await response.json();
        return res.levels;
    }
}

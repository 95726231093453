import { useEffect } from 'react'
import type { WalletClient } from 'viem'

import { IS_ONE_CLICK_TRADING_ENABLED } from 'constants/defaults'
import { initAccountAbstraction } from 'state/futures/actions'
import { selectTradingMode } from 'state/futures/common/selectors'
import { setIsSessionActive, setPreferences } from 'state/futures/reducer'
import { selectAccountContext, selectSessionExpiry } from 'state/futures/selectors'
import { fetchUsdcAbstractionAllowanceForEngine } from 'state/futures/snxPerpsV3/actions'
import { TradingModes } from 'state/futures/types'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectAbstractionAddress } from 'state/wallet/selectors'
import { ONE_SECOND_IN_MS } from 'utils/dates'

type UseAccountAbstractionConnectProps = {
	walletClient?: WalletClient
}

export default function useAccountAbstractionConnect({
	walletClient,
}: UseAccountAbstractionConnectProps) {
	const dispatch = useAppDispatch()

	const abstractionAddress = useAppSelector(selectAbstractionAddress)
	const accountContext = useAppSelector(selectAccountContext)

	const tradingMode = useAppSelector(selectTradingMode)
	const sessionExpiry = useAppSelector(selectSessionExpiry)

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		dispatch(initAccountAbstraction(walletClient))
	}, [walletClient, accountContext, dispatch])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!walletClient) {
			return
		}

		dispatch(fetchUsdcAbstractionAllowanceForEngine())
	}, [dispatch, abstractionAddress, accountContext.network, walletClient])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// we should reset the state only once, during session creation / deletion
		if (
			IS_ONE_CLICK_TRADING_ENABLED &&
			tradingMode === TradingModes.DEFAULT &&
			sessionExpiry > Math.floor(Date.now() / 1000)
		) {
			dispatch(setPreferences({ tradingMode: TradingModes.ONE_CLICK }))
		}
	}, [dispatch, sessionExpiry])

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(setIsSessionActive(sessionExpiry > Math.floor(Date.now() / ONE_SECOND_IN_MS)))
		}, ONE_SECOND_IN_MS)

		return () => clearInterval(interval)
	}, [sessionExpiry, dispatch])
}

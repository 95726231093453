import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash'

export default function logError(err: Error, skipReport = false) {
	// biome-ignore lint/nursery/noConsole: <explanation>
	console.error(err)
	if (!sentrySkipFilter(err) && !skipReport) {
		Sentry.captureException(err)
	}
}

const sentrySkipFilter = (e: Error) => {
	return IGNORE_ERRORS.some((text) => {
		return isEmpty(e?.message) || e?.message?.toLowerCase().includes(text.toLowerCase())
	})
}

export const IGNORE_ERRORS = [
	'Insufficient margin',
	'insufficient funds',
	'Network request failed',
	'Network error',
	'timeout exceeded',
	'took too long to respond',
	'Unsupported network',
	'aborted',
	'Transaction reverted without a reason string',
	'Failed to fetch',
	'Wallet not connected',
	'NetworkError',
	'HTTP error',
	'TimeoutError',
	'Load failed',
	'Failed to load',
	'Error in input stream',
	'ExecutionRevertedError',
	'Forbidden',
]

const abi = [
    {
        inputs: [
            { internalType: 'address', name: '_brevisProof', type: 'address' },
            { internalType: 'address', name: '_factory', type: 'address' },
            { internalType: 'address', name: '_migrationContract', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    { inputs: [], name: 'InvalidNewClaimPeriod', type: 'error' },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: '', type: 'address' }],
        name: 'BrevisProofUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: '', type: 'address' }],
        name: 'ClaimerUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'ContractsHashUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'uint248', name: 'feeRebate', type: 'uint248' },
            { indexed: false, internalType: 'uint248', name: 'volume30D', type: 'uint248' },
            { indexed: false, internalType: 'uint248', name: 'feeRebateWithRate', type: 'uint248' },
            { indexed: false, internalType: 'uint64', name: 'startBlockNumber', type: 'uint64' },
            { indexed: false, internalType: 'uint64', name: 'endBlockNumber', type: 'uint64' },
        ],
        name: 'FeeRebateAccumulated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: '', type: 'address' }],
        name: 'FeeRebateTireModuleUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'uint248', name: 'feeRebate', type: 'uint248' },
        ],
        name: 'FeeReimbursed',
        type: 'event',
    },
    { anonymous: false, inputs: [], name: 'MigrationDone', type: 'event' },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'uint248', name: 'feeAccumulated', type: 'uint248' },
            { indexed: false, internalType: 'uint64', name: 'startBlockNumber', type: 'uint64' },
            { indexed: false, internalType: 'uint64', name: 'endBlockNumber', type: 'uint64' },
        ],
        name: 'MigrationFinishedForAccount',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
            { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'bytes32[]', name: 'vkHashes', type: 'bytes32[]' },
            { indexed: false, internalType: 'uint16[]', name: 'sizes', type: 'uint16[]' },
        ],
        name: 'VkHashesUpdated',
        type: 'event',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'accountAccumulatedFee',
        outputs: [{ internalType: 'uint248', name: '', type: 'uint248' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'accountClaimPeriod',
        outputs: [
            { internalType: 'uint64', name: 'startBlockNumber', type: 'uint64' },
            { internalType: 'uint64', name: 'endBlockNumber', type: 'uint64' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint64', name: '_chainId', type: 'uint64' },
            {
                components: [
                    { internalType: 'bytes32', name: 'commitHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'vkHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'appCommitHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'appVkHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'smtRoot', type: 'bytes32' },
                ],
                internalType: 'struct Brevis.ProofData[]',
                name: '_proofDataArray',
                type: 'tuple[]',
            },
            { internalType: 'bytes[]', name: '_appCircuitOutputs', type: 'bytes[]' },
        ],
        name: 'brevisBatchCallback',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: '_requestId', type: 'bytes32' },
            { internalType: 'bytes', name: '_appCircuitOutput', type: 'bytes' },
        ],
        name: 'brevisCallback',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'brevisProof',
        outputs: [{ internalType: 'contract IBrevisProof', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'claim',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'claimer',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'contractsHash',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'contract IFactory', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'feeRebateTierModule',
        outputs: [{ internalType: 'contract IFeeRebateTierModule', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address[]', name: '_accounts', type: 'address[]' }],
        name: 'migrate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'migrationContract',
        outputs: [{ internalType: 'contract IMigration', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardToken',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'rewardTokenDecimals',
        outputs: [{ internalType: 'uint24', name: '', type: 'uint24' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_brevisProof', type: 'address' }],
        name: 'setBrevisProof',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_claimer', type: 'address' }],
        name: 'setClaimer',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_contractsHash', type: 'uint256' }],
        name: 'setContractsHash',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract IFeeRebateTierModule',
                name: '_feeRebateTierModule',
                type: 'address',
            },
        ],
        name: 'setFeeRebateTierModule',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'setMigrationFinished',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_rewardToken', type: 'address' },
            { internalType: 'uint24', name: '_decimals', type: 'uint24' },
        ],
        name: 'setRewardToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32[]', name: '_vkHashes', type: 'bytes32[]' },
            { internalType: 'uint16[]', name: '_sizes', type: 'uint16[]' },
        ],
        name: 'setVkHashes',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint64', name: '_chainId', type: 'uint64' },
            {
                components: [
                    { internalType: 'bytes32', name: 'commitHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'vkHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'appCommitHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'appVkHash', type: 'bytes32' },
                    { internalType: 'bytes32', name: 'smtRoot', type: 'bytes32' },
                ],
                internalType: 'struct Brevis.ProofData',
                name: '_proofData',
                type: 'tuple',
            },
            { internalType: 'bytes32', name: '_merkleRoot', type: 'bytes32' },
            { internalType: 'bytes32[]', name: '_merkleProof', type: 'bytes32[]' },
            { internalType: 'uint8', name: '_nodeIndex', type: 'uint8' },
            { internalType: 'bytes', name: '_appCircuitOutput', type: 'bytes' },
        ],
        name: 'singleRun',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: '_requestId', type: 'bytes32' },
            { internalType: 'uint64', name: '_chainId', type: 'uint64' },
            {
                components: [
                    { internalType: 'bytes32', name: 'smtRoot', type: 'bytes32' },
                    {
                        components: [
                            { internalType: 'uint64', name: 'blkNum', type: 'uint64' },
                            { internalType: 'uint64', name: 'receiptIndex', type: 'uint64' },
                            {
                                components: [
                                    {
                                        components: [
                                            { internalType: 'uint8', name: 'valueFromTopic', type: 'uint8' },
                                            { internalType: 'uint64', name: 'valueIndex', type: 'uint64' },
                                            { internalType: 'address', name: 'contractAddress', type: 'address' },
                                            { internalType: 'bytes32', name: 'logTopic0', type: 'bytes32' },
                                        ],
                                        internalType: 'struct Brevis.LogExtraInfo',
                                        name: 'logExtraInfo',
                                        type: 'tuple',
                                    },
                                    { internalType: 'uint64', name: 'logIndex', type: 'uint64' },
                                    { internalType: 'bytes32', name: 'value', type: 'bytes32' },
                                ],
                                internalType: 'struct Brevis.LogInfo[5]',
                                name: 'logs',
                                type: 'tuple[5]',
                            },
                        ],
                        internalType: 'struct Brevis.ReceiptInfo[]',
                        name: 'receipts',
                        type: 'tuple[]',
                    },
                    {
                        components: [
                            { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
                            { internalType: 'address', name: 'account', type: 'address' },
                            { internalType: 'bytes32', name: 'slot', type: 'bytes32' },
                            { internalType: 'bytes32', name: 'slotValue', type: 'bytes32' },
                            { internalType: 'uint64', name: 'blockNumber', type: 'uint64' },
                        ],
                        internalType: 'struct Brevis.StorageInfo[]',
                        name: 'stores',
                        type: 'tuple[]',
                    },
                    {
                        components: [
                            { internalType: 'bytes32', name: 'leafHash', type: 'bytes32' },
                            { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
                            { internalType: 'uint64', name: 'blockNumber', type: 'uint64' },
                            { internalType: 'uint64', name: 'blockTime', type: 'uint64' },
                            { internalType: 'bytes', name: 'leafRlpPrefix', type: 'bytes' },
                        ],
                        internalType: 'struct Brevis.TransactionInfo[]',
                        name: 'txs',
                        type: 'tuple[]',
                    },
                ],
                internalType: 'struct Brevis.ExtractInfos',
                name: '_extractInfos',
                type: 'tuple',
            },
        ],
        name: 'validateRequest',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        name: 'vkHashesToCircuitSize',
        outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
        stateMutability: 'view',
        type: 'function',
    },
];
export default abi;

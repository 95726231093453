import type { PriceType, PricesMap, SynthPrice } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { AppThunk } from 'state/store'
import type { ThunkConfig } from 'state/types'
import logError from 'utils/logError'
import { getPricesInfo } from 'utils/prices'

import { forexHours } from 'utils/marketHours'
import { setOffChainPrices, setOnChainPrices } from './reducer'

export const updatePrices =
	(newPrices: PricesMap<string>, type: PriceType): AppThunk =>
	(dispatch, getState) => {
		const { prices } = getState()
		if (type === 'off_chain') {
			dispatch(setOffChainPrices(getPricesInfo(prices.offChainPrices, newPrices)))
		} else {
			dispatch(setOnChainPrices(getPricesInfo(prices.onChainPrices, newPrices)))
		}
	}

export const fetchPreviousDayPrices = createAsyncThunk<SynthPrice[], void, ThunkConfig>(
	'prices/fetchPreviousDayPrices',
	async (_, { extra: { sdk } }) => {
		try {
			const laggedPrices = await sdk.prices.getPreviousDayPrices(
				forexHours().isYesterdayWorkingTime
			)
			return laggedPrices
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

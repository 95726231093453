import { createSlice } from '@reduxjs/toolkit'

import {
	fetchApprovedOperators,
	fetchClaimableRewards,
	fetchClaimableRewardsParams,
	fetchEscrowData,
	fetchEscrowV2Data,
	fetchEstimatedRewards,
	fetchFeesPaid,
	fetchReferralFeesPaid,
	fetchStakingData,
	fetchStakingV2Data,
} from './actions'
import type { StakingState } from './types'

const ZERO_STAKE_BALANCE = {
	escrowedKwentaBalance: '0',
	stakedKwentaBalance: '0',
	totalStakedBalance: '0',
	claimableBalance: '0',
	stakedEscrowedKwentaBalance: '0',
}

const ZERO_STAKE_BALANCE_v2 = {
	...ZERO_STAKE_BALANCE,
	feeShareBalance: '0',
}

export const ZERO_ESCROW_BALANCE = {
	totalVestable: '0',
	escrowData: [],
}

const ZERO_STAKE_DATA = {
	...ZERO_STAKE_BALANCE,
	...ZERO_ESCROW_BALANCE,
}

const ZERO_STAKE_DATA_v2 = {
	...ZERO_STAKE_BALANCE_v2,
	...ZERO_ESCROW_BALANCE,
}

const INITIAL_STAKE_INFO = {
	kwentaBalance: '0',
	vKwentaBalance: '0',
	veKwentaBalance: '0',
	kwentaAllowance: '0',
	vKwentaAllowance: '0',
	veKwentaAllowance: '0',
	epochPeriod: 0,
	weekCounter: 1,
}

const INITIAL_STAKE_V2_INFO = {
	stakedResetTime: 0,
	kwentaStakingV2Allowance: '0',
}

export const ZERO_ESTIMATED_REWARDS = {
	kwenta: '0',
	arb: '0',
	opReferral: '0',
}

export const ZERO_CLAIMABLE_REWARDS = {
	kwenta: '0',
	snx: '0',
	arb: '0',
	opReferral: '0',
}

export const ZERO_CLAIMABLE_REWARDS_PARAMS = {
	kwentaV2: [],
	arb: [],
	opReferral: [],
}

export const ZERO_FEES_PAID = {
	kwenta: '0',
	arb: '0',
	opReferral: '0',
}

export const ZERO_REFERRAL_FEES_PAID = {
	opReferral: '0',
}

export const ZERO_STAKING_DATA = {
	...ZERO_STAKE_BALANCE,
	...INITIAL_STAKE_INFO,
}

export const ZERO_STAKING_V2_DATA = {
	...ZERO_STAKE_BALANCE_v2,
	...INITIAL_STAKE_V2_INFO,
}

export const ZERO_TRADING_REWARDS = {
	estimatedRewards: ZERO_ESTIMATED_REWARDS,
	claimableRewards: ZERO_CLAIMABLE_REWARDS,
	claimableRewardsParams: ZERO_CLAIMABLE_REWARDS_PARAMS,
	feesPaid: ZERO_FEES_PAID,
	referralFeesPaid: ZERO_REFERRAL_FEES_PAID,
}

export const STAKING_INITIAL_STATE: StakingState = {
	v1: {
		...ZERO_STAKE_DATA,
	},
	v2: {
		...ZERO_STAKE_DATA_v2,
	},
	...INITIAL_STAKE_INFO,
	...INITIAL_STAKE_V2_INFO,
	tradingRewards: ZERO_TRADING_REWARDS,
	stakingMigrationCompleted: true,
	approvedOperators: [],
}

const stakingSlice = createSlice({
	name: 'staking',
	initialState: STAKING_INITIAL_STATE,
	reducers: {
		setSelectedEpoch: (state, action) => {
			state.selectedEpoch = action.payload
		},
		setStakingMigrationCompleted: (state, action) => {
			state.stakingMigrationCompleted = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStakingData.fulfilled, (state, action) => {
			state.v1.escrowedKwentaBalance = action.payload.escrowedKwentaBalance
			state.v1.stakedKwentaBalance = action.payload.stakedKwentaBalance
			state.v1.stakedEscrowedKwentaBalance = action.payload.stakedEscrowedKwentaBalance
			state.v1.claimableBalance = action.payload.claimableBalance
			state.v1.totalStakedBalance = action.payload.totalStakedBalance
			state.kwentaBalance = action.payload.kwentaBalance
			state.weekCounter = action.payload.weekCounter
			state.vKwentaBalance = action.payload.vKwentaBalance
			state.vKwentaAllowance = action.payload.vKwentaAllowance
			state.kwentaAllowance = action.payload.kwentaAllowance
			state.epochPeriod = action.payload.epochPeriod
			state.veKwentaBalance = action.payload.veKwentaBalance
			state.veKwentaAllowance = action.payload.veKwentaAllowance
		})
		builder.addCase(fetchStakingV2Data.fulfilled, (state, action) => {
			state.v2.escrowedKwentaBalance = action.payload.escrowedKwentaBalance
			state.v2.stakedKwentaBalance = action.payload.stakedKwentaBalance
			state.v2.stakedEscrowedKwentaBalance = action.payload.stakedEscrowedKwentaBalance
			state.v2.claimableBalance = action.payload.claimableBalance
			state.v2.totalStakedBalance = action.payload.totalStakedBalance
			state.v2.feeShareBalance = action.payload.feeShareBalance
			state.kwentaStakingV2Allowance = action.payload.kwentaStakingV2Allowance
			state.stakedResetTime = action.payload.stakedResetTime
		})
		builder.addCase(fetchEscrowData.fulfilled, (state, action) => {
			state.v1.totalVestable = action.payload.totalVestable
			state.v1.escrowData = action.payload.escrowData
		})
		builder.addCase(fetchEscrowV2Data.fulfilled, (state, action) => {
			state.v2.totalVestable = action.payload.totalVestable
			state.v2.escrowData = action.payload.escrowData
		})
		builder.addCase(fetchClaimableRewards.fulfilled, (state, action) => {
			state.tradingRewards.claimableRewards.kwenta = action.payload.kwenta
			state.tradingRewards.claimableRewards.snx = action.payload.snx
			state.tradingRewards.claimableRewards.arb = action.payload.arb
			state.tradingRewards.claimableRewards.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchEstimatedRewards.fulfilled, (state, action) => {
			state.tradingRewards.estimatedRewards.kwenta = action.payload.kwenta
			state.tradingRewards.estimatedRewards.arb = action.payload.arb
			state.tradingRewards.estimatedRewards.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchFeesPaid.fulfilled, (state, action) => {
			state.tradingRewards.feesPaid.kwenta = action.payload.kwenta
			state.tradingRewards.feesPaid.arb = action.payload.arb
			state.tradingRewards.feesPaid.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchReferralFeesPaid.fulfilled, (state, action) => {
			state.tradingRewards.referralFeesPaid.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchClaimableRewardsParams.fulfilled, (state, action) => {
			state.tradingRewards.claimableRewardsParams.kwentaV2 = action.payload.kwentaV2
			state.tradingRewards.claimableRewardsParams.arb = action.payload.arb
			state.tradingRewards.claimableRewardsParams.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchApprovedOperators.fulfilled, (state, action) => {
			state.approvedOperators = action.payload.operators
		})
	},
})

export default stakingSlice.reducer
export const { setSelectedEpoch, setStakingMigrationCompleted } = stakingSlice.actions

import { type FormatNumberOptions, formatNumber, formatPercent } from '@kwenta/sdk/utils'
import { type WeiSource, wei } from '@kwenta/wei'
import { type FC, type ReactNode, memo, useMemo } from 'react'
import styled from 'styled-components'

import Body, { type BodyProps } from './Body'

type NumericValueProps = BodyProps & {
	value?: WeiSource
	preview?: boolean
	colored?: boolean
	options?: FormatNumberOptions
	suffix?: string
	children?: ReactNode
	isPercent?: boolean
}

const NumericValue: FC<NumericValueProps> = memo(
	({
		value,
		preview,
		colored,
		options,
		suffix,
		isPercent,
		color,
		prefix,
		type = 'p',
		...props
	}) => {
		const numberColor = useMemo(() => {
			if (color) {
				return color
			} else if (preview) {
				return 'preview'
			} else if (colored && value) {
				if (wei(value).gt(0)) {
					return 'positive'
				} else if (wei(value).lt(0)) {
					return 'negative'
				}
			} else {
				return 'primary'
			}
		}, [color, preview, colored, value])

		const formatted = useMemo(() => {
			return isPercent ? formatPercent(value ?? 0, options) : formatNumber(value ?? 0, options)
		}, [isPercent, value, options])

		return (
			<Body type={type} mono color={numberColor} {...props}>
				{prefix}
				{props.children ?? formatted}
				{suffix}
			</Body>
		)
	}
)

export const NumberBody = styled(Body).attrs({ mono: true })``

export default NumericValue

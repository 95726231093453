export const depositableAssetToToken = (token) => token === 'ETH' ? 'WETH' : token;
export const getReasonFromCode = (reasonCode) => {
    switch (Number(reasonCode)) {
        case 1:
            return 'system-upgrade';
        case 2:
            return 'market-closure';
        case 3:
        case 55:
        case 65:
            return 'circuit-breaker';
        case 99999:
            return 'emergency';
        default:
            return 'market-closure';
    }
};

import { stringToHex } from 'viem';
import * as sdkErrors from '../constants/errors';
import { kwentaContractsByNetwork } from '../contracts';
import { queryBoostNftTierByHolder, queryCodesByReferrer, queryReferrerByCode, } from '../queries/referrals';
import { SnxV2NetworkIds } from '../types';
import { getReferralFeesPaid, getReferralStatisticsByAccount, getReferralStatisticsByAccountAndEpoch, getReferralStatisticsByAccountAndEpochTime, getReferralsGqlEndpoint, } from '../utils/referrals';
export default class ReferralsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    contractConfigs(chainId) {
        return kwentaContractsByNetwork(chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET);
    }
    client(chainId) {
        const client = this.sdk.context.clients[chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET];
        if (client)
            return client;
        throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
    }
    referralsGqlEndpoint(chainId) {
        return getReferralsGqlEndpoint(chainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
            ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
            : SnxV2NetworkIds.OPTIMISM_MAINNET);
    }
    /**
     * Mint a Boost NFT using the given code.
     * @param code - The referral code.
     * @returns ethers.js TransactionResponse object
     */
    mintBoostNft(code, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.client(chainId).simulateContract({
            ...BoostNft,
            account: this.sdk.context.walletAddress,
            functionName: 'mint',
            args: [stringToHex(code, { size: 32 })],
        });
    }
    /**
     * Register a new referral code.
     * @param code - The referral code to register.
     * @returns ethers.js TransactionResponse object
     */
    registerReferralCode(code, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.client(chainId).simulateContract({
            ...BoostNft,
            account: this.sdk.context.walletAddress,
            functionName: 'registerCode',
            args: [stringToHex(code, { size: 32 })],
        });
    }
    /**
     * Fetches the referral score by a given account.
     * @param account - The account of the referral code creator.
     * @returns The referral score of the account.
     */
    getReferralScoreByReferrer(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.client(chainId).readContract({
            ...BoostNft,
            functionName: 'getReferralScore',
            args: [account],
        });
    }
    /**
     * Fetch the tier of a boost NFT determined by the issuer's referral score.
     * @param account - The account of the boost NFT issuer.
     * @returns The tier level of the boost NFT.
     */
    async getReferralNftTierByReferrer(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        try {
            const score = await this.getReferralScoreByReferrer(account);
            const tier = await this.client(chainId).readContract({
                ...BoostNft,
                functionName: 'getTierFromReferralScore',
                args: [score],
            });
            return Number(tier);
        }
        catch (err) {
            this.sdk.context.logError(err);
            throw err;
        }
    }
    /**
     * Retrieve the tier level associated with a given referral code.
     * @param code - The referral code.
     * @returns The tier level of the referral code, or -1 if the code is invalid.
     */
    async getTierByReferralCode(code, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        try {
            const account = await this.getReferrerByCode(code);
            if (!account) {
                return -1;
            }
            return this.getReferralNftTierByReferrer(account, chainId);
        }
        catch (err) {
            this.sdk.context.logError(err);
            throw err;
        }
    }
    /**
     * Check whether a Boost NFT has been minted by a given account.
     * @param account - The account to check.
     * @returns Boolean indicating whether the NFT has been minted.
     */
    checkNftMintedForAccount(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.client(chainId).readContract({
            ...BoostNft,
            functionName: 'hasMinted',
            args: [account],
        });
    }
    /**
     * Retrieve the owner's address associated with a given referral code.
     * @param code - The referral code.
     * @returns The code owner's address, or null if the code is not found.
     */
    getReferrerByCode(code, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryReferrerByCode(this.sdk, code, chainId);
    }
    /**
     * Fetch the tier of a Boost NFT by the owner's account.
     * @param account - The account of the Boost NFT owner.
     * @returns The tier level of a Boost NFT.
     */
    getBoostNftTierByHolder(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryBoostNftTierByHolder(this.sdk, account, chainId);
    }
    /**
     * Retrieve all the referral codes created by a given referrer.
     * @param account - The account of the referrer.
     * @returns All the referral codes created by the referrer.
     */
    getCodesByReferrer(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryCodesByReferrer(this.sdk, account, chainId);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrer(account, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccount(this.sdk, account, chainId);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @param period - The epoch period.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrerAndEpoch(account, period, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccountAndEpoch(this.sdk, account, period);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @param start - The start epoch timestamp.
     * @param end - The end epoch timestamp.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrerAndEpochTime(account, start, end, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccountAndEpochTime(this.sdk, account, start, end, chainId);
    }
    /**
     * Retrieve the cumulative fees paid for a given referrer.
     * @param account - The account of the referrer.
     * @param start - The start epoch timestamp.
     * @param end - The end epoch timestamp.
     * @returns Wei accumulated fees paid by trades referred by the referrer.
     */
    getReferralFeesPaid(account, start, end, chainId = 10) {
        const { BoostNft } = this.contractConfigs(chainId);
        if (!BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralFeesPaid(this.sdk, account, start, end, chainId);
    }
}

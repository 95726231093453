export function notNill(value) {
    return !!value;
}
export function inverseObj(obj) {
    const retobj = {};
    for (const key in obj) {
        retobj[obj[key]] = key;
    }
    return retobj;
}
export function toSnakeCase(str) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        .replace(/\W+/g, '_')
        .toLowerCase();
}
export function snakeToTitleCase(str) {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}
export function usdcDecimals(chainId) {
    return chainId === 8453 || chainId === 84532 ? 6 : 18;
}

import { createSlice } from '@reduxjs/toolkit'

import { fetchEarnTokenPrices } from './actions'
import type { EarnState } from './types'

export const EARN_INITIAL_STATE: EarnState = {
	balance: '0',
	earnedRewards: '0',
	rewardRate: '0',
	totalSupply: '0',
	lpTokenBalance: '0',
	allowance: '0',
	error: undefined,
	endDate: 0,
	wethAmount: '0',
	kwentaAmount: '0',
	lpTotalSupply: '0',
	wethPrice: '0',
	kwentaPrice: '0',
	opPrice: '0',
	snxPrice: '0',
	arbPrice: '0',
}

const earnSlice = createSlice({
	name: 'earn',
	initialState: EARN_INITIAL_STATE,
	reducers: {
		setEarnDetails: (state, action) => {
			state.balance = action.payload.balance
			state.earnedRewards = action.payload.earnedRewards
			state.endDate = action.payload.endDate
			state.rewardRate = action.payload.rewardRate
			state.totalSupply = action.payload.totalSupply
			state.lpTokenBalance = action.payload.lpTokenBalance
			state.allowance = action.payload.allowance
			state.wethAmount = action.payload.wethAmount
			state.kwentaAmount = action.payload.kwentaAmount
			state.lpTotalSupply = action.payload.lpTotalSupply
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchEarnTokenPrices.fulfilled, (state, action) => {
			state.kwentaPrice = action.payload.kwentaPrice
			state.wethPrice = action.payload.wethPrice
			state.opPrice = action.payload.opPrice
			state.snxPrice = action.payload.snxPrice
			state.arbPrice = action.payload.arbPrice
		})
	},
})

export default earnSlice.reducer

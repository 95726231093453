import { ZERO_WEI } from '@kwenta/sdk/constants'
import { OrderTypeEnum, PositionSide } from '@kwenta/sdk/types'
import { formatCurrency, formatDollars, getDisplayAsset, getMarketName } from '@kwenta/sdk/utils'
import { type FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CheckIcon from 'assets/svg/app/check.svg'
import LinkIcon from 'assets/svg/app/link.svg'
import RefreshIcon from 'assets/svg/app/refresh-cw.svg'
import SpinnerIcon from 'assets/svg/app/spinner.svg'
import ErrorIcon from 'assets/svg/app/subway-error.svg'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { FlexDivCol, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import { NO_VALUE } from 'constants/placeholder'
import { blockExplorer } from 'containers/Connector/Connector'
import type { TransactionOrder } from 'state/app/types'

import Spacer from './Spacer'
import { Body, NumericValue } from './Text'

type TradeNotificationProps = {
	txHash: string
	order: TransactionOrder
}

const NotificationFooter = styled(FlexDivRowCentered)`
	width: max-content;
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

const TradeSummary = memo(({ order }: { order: TransactionOrder }) => {
	const { side, marketAsset, type, sizeDelta, price } = order
	return (
		<FlexDivRowCentered justifyContent="space-between" columnGap="10px">
			<AssetContainer side={side}>
				<CurrencyIcon currencyKey={marketAsset} height={25} width={25} />
				<FlexDivCol>
					<Body fontSize={12} color="primary">
						{getMarketName(marketAsset)}
					</Body>
					<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
						<CapitalizedBody
							size="small"
							color={`${side === PositionSide.LONG ? 'positive' : 'negative'}`}
						>
							{side}
						</CapitalizedBody>
						<Body size="small" color="secondary">
							/
						</Body>
						<CapitalizedBody size="small" color="secondary">
							{type}
						</CapitalizedBody>
					</FlexDivRowCentered>
				</FlexDivCol>
			</AssetContainer>
			<SizeContainer>
				<Body size="small" color="primary">
					<NumericValue type="span" value={sizeDelta}>
						{formatCurrency(getDisplayAsset(marketAsset) || '', sizeDelta?.abs() ?? ZERO_WEI, {
							currencyKey: getDisplayAsset(marketAsset) ?? '',
						})}
					</NumericValue>
				</Body>
				<Body size="small" color="secondary">
					{price ? formatDollars(price, { suggestDecimalsForAsset: marketAsset }) : NO_VALUE}
				</Body>
			</SizeContainer>
		</FlexDivRowCentered>
	)
})

const ViewTransaction = memo(
	({ txHash, invisible = false }: { txHash: string; invisible?: boolean }) => {
		const { t } = useTranslation()

		return (
			<StyledBody size="medium" color="secondary" invisible={invisible}>
				<NotificationFooter
					justifyContent="flex-start"
					columnGap="5px"
					onClick={() => window.open(`${blockExplorer.txLink(txHash)}`)}
				>
					{t('common.transaction.view-transaction')}
					<LinkIcon width={12} height={12} style={{ cursor: 'pointer' }} />
				</NotificationFooter>
			</StyledBody>
		)
	}
)

const TradeNotificationPending: FC<TradeNotificationProps> = memo(
	({ order, txHash }: TradeNotificationProps) => {
		const { t } = useTranslation()

		return (
			<FlexDivCol rowGap="15px">
				<FlexDivRowCentered justifyContent="flex-start" columnGap="10px">
					<SpinnerIcon width={14} height={14} />
					<CapitalizedBody size="large" weight="semiBold">
						{order.type} order {t('common.transaction.notification-title.pending')}
					</CapitalizedBody>
				</FlexDivRowCentered>
				<Divider height={1} divider />
				<TradeSummary order={order} />
				<Divider height={1} divider />
				<ViewTransaction txHash={txHash} />
			</FlexDivCol>
		)
	}
)

type TradeNotificationExecuteProps = TradeNotificationProps & {
	onExecute: () => void
}
const TradeNotificationExecute: FC<TradeNotificationExecuteProps> = memo(
	({ order, onExecute }: TradeNotificationExecuteProps) => {
		const { t } = useTranslation()

		return (
			<FlexDivCol rowGap="15px">
				<FlexDivRowCentered justifyContent="flex-start" columnGap="10px">
					<SpinnerIcon width={14} height={14} />
					<CapitalizedBody size="large" weight="semiBold">
						{order.type} order {t('common.transaction.notification-title.pending')}
					</CapitalizedBody>
				</FlexDivRowCentered>
				<Divider height={1} divider />
				<TradeSummary order={order} />
				<Divider height={1} divider />
				<StyledFooter size="medium">
					<NotificationFooter
						justifyContent="flex-start"
						columnGap="5px"
						style={{ cursor: 'pointer' }}
						onClick={onExecute}
					>
						<RefreshIcon width={12} height={12} />
						{t('common.transaction.execute')}
					</NotificationFooter>
				</StyledFooter>
			</FlexDivCol>
		)
	}
)

const TradeNotificationSuccess: FC<TradeNotificationProps> = memo(
	({ order, txHash }: TradeNotificationProps) => {
		const { t } = useTranslation()

		return (
			<FlexDivCol rowGap="15px">
				<FlexDivRowCentered justifyContent="flex-start" columnGap="10px">
					<CheckIcon width={14} height={14} />
					<CapitalizedBody size="large" weight="semiBold" color="positive">
						{t(
							`common.transaction.notification-title.${
								order.type === OrderTypeEnum.MARKET ? 'filled' : 'order-placed'
							}`
						)}
					</CapitalizedBody>
				</FlexDivRowCentered>
				<Divider height={1} divider />
				<TradeSummary order={order} />
				<Divider height={1} divider />
				<ViewTransaction txHash={txHash} />
			</FlexDivCol>
		)
	}
)

const TradeNotificationFailed: FC<TradeNotificationProps> = memo(
	({ order, txHash }: TradeNotificationProps) => {
		const { t } = useTranslation()

		return (
			<FlexDivCol rowGap="15px">
				<FlexDivRowCentered justifyContent="flex-start" columnGap="10px">
					<ErrorIcon width={14} height={14} />
					<CapitalizedBody size="large" weight="semiBold" color="negative">
						{t('common.transaction.notification-title.failed')}
					</CapitalizedBody>
				</FlexDivRowCentered>
				<Divider height={1} divider />
				<TradeSummary order={order} />
				<Divider height={1} divider />
				<ViewTransaction txHash={txHash} invisible />
			</FlexDivCol>
		)
	}
)

const CapitalizedBody = styled(Body)`
	text-transform: capitalize;
`

const StyledBody = styled(Body)<{ invisible?: boolean }>`
	visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`

const StyledFooter = styled(Body)`
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.banner.yellow.text};
`

const AssetContainer = styled(FlexDivRow)<{ side: PositionSide }>`
	width: 140px;
	padding-left: 10px;
	align-items: center;
	gap: 10px;
	border-left: ${(props) => props.theme.colors.selectedTheme.newTheme.border[props.side]};
	height: 26px;
	justify-content: flex-start;
`

const SizeContainer = styled(FlexDivCol)`
	align-items: end;
`

const Divider = styled(Spacer)`
	width: 100%;
`

export {
	TradeNotificationExecute,
	TradeNotificationPending,
	TradeNotificationSuccess,
	TradeNotificationFailed,
}
